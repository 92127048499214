import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import { APP_ACTIONS } from '@/store/modules/app';
import routes from './routes';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (from, to, next) => {
  next();
});

router.beforeResolve(async (to, from, next) => {
  try {
    for (const route of to.matched) {
      await new Promise((resolve, reject) => {
        if (route.meta && route.meta.beforeResolve) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          route.meta.beforeResolve(to, from, (...args) => {
            if (args.length) {
              if (from.name === args[0].name) {
                store.dispatch(`app/${APP_ACTIONS.SET_PRELOADING}`, false);
              }
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              next(...args);
              reject(new Error('Redirected'));
            } else {
              resolve(null);
            }
          });
        } else {
          resolve(null);
        }
      });
    }
  } catch (error) {
    return;
  }
  next();
});

router.afterEach(async () => {
  await store.dispatch(`app/${APP_ACTIONS.SET_PRELOADING}`, false);
});

export default router;
