import apollo from '@/library/http/apollo';
import gql from 'graphql-tag';

export const STANDARDS_GETTERS = {
  ALL: 'standards',
};

export const STANDARDS_ACTIONS = {
  GET_STANDARDS: 'getStandards',
};

const STANDARDS_MUTATIONS = {
  SET_STANDARDS: 'setStandards',
};

export default {
  namespaced: true,
  state: {
    standards: [],
  },
  getters: {
    [STANDARDS_GETTERS.ALL]: (state: any) => {
      return state.standards;
    },
  },
  mutations: {
    [STANDARDS_MUTATIONS.SET_STANDARDS]: (state: any, standards: any) => {
      state.standards = standards;
    },
  },
  actions: {
    [STANDARDS_ACTIONS.GET_STANDARDS]: async (context: any) => {
      await apollo.apolloClient
        .query({
          query: gql`
            query {
              getAllCurrentlyExistingServiceStandards {
                description
                id
                standardName
                standardLink
                infraServices {
                  categoryId
                  categoryName
                  id
                  apmid
                  isCatalogPart
                  orderable
                  description
                  displayName
                  pavedRoad
                  serviceName
                  state
                  cloudType
                  type
                }
              }
            }
          `,
        })
        .then((response) => {
          const standards = response.data.getAllCurrentlyExistingServiceStandards;
          context.commit(STANDARDS_MUTATIONS.SET_STANDARDS, standards);
        });
    },
  },
};
