<template>
  <q-page class="main-wrapper-page single-page" style="background-color: var(--q-accent)">
    <div class="adr-filter-holder q-mb-lg">
      <div class="row no-wrap q-col-gutter-sm adr-filters">
        <div class="col col-4">
          <q-input v-model="filters.name" label="Title" outlined dense clearable class="q-mb-sm bg-white" />
        </div>
        <div class="col col-4">
          <q-select
            map-options
            v-model="filters.owners"
            label="Owner"
            multiple
            @clear="removeFilters('owners')"
            :options="owners"
            option-value="email"
            option-label="name"
            emit-value
            options-dense
            outlined
            dense
            clearable
            class="q-mb-sm bg-white filter-select"
          >
          </q-select>
        </div>
        <div class="col col-4">
          <q-select
            map-options
            options-dense
            v-model="filters.services"
            multiple
            @clear="removeFilters('services')"
            label="Services"
            :options="infraServices"
            option-label="name"
            emit-value
            option-value="serviceId"
            outlined
            dense
            clearable
            class="q-mb-sm bg-white filter-select"
          >
          </q-select>
        </div>
      </div>
      <div class="btn-holder">
        <q-btn @click="getADRs" icon="search" round flat>
          <q-tooltip>Search</q-tooltip>
        </q-btn>
      </div>
    </div>

    <div class="row q-col-gutter-sm adrs-holder q-pb-lg">
      <div v-for="adr in adrs" class="col-4 q-px-sm q-py-sm adr-holder" :key="adr.id" @click="goToAdr(adr.adrId)">
        <div class="adr bg-white">
          <div class="adr-head flex content-center">
            <span class="adr-title">{{ adr.name }}</span>
          </div>
          <span class="block" style="color: #009688; font-size: 15px"
            >For {{ adr.scope?.units }}, {{ adr.scope?.regions }}</span
          >
          <div
            class="adr-content service-description-markdown"
            v-html="dataMarkdown(adr.requirements || adr.content)"
          ></div>
          <span class="block"><b>Valid date:</b> {{ adr.validDate }}</span>
        </div>
      </div>
    </div>
    <div class="flex flex-center q-mb-lg">
      <q-pagination :max="Math.ceil(totalAdrs / 6)" v-model="currentPage" @update:model-value="getADRs"></q-pagination>
    </div>
    <svg v-if="items.nodes.length && items.links.length" ref="svg" className="demo" />
  </q-page>
</template>

<script>
import * as d3 from 'd3';
import gql from 'graphql-tag';
import emitter from 'tiny-emitter/instance';
import { sankey, sankeyLinkHorizontal } from 'd3-sankey';
import { marked } from 'marked';
import apollo from '@/library/http/apollo';

export default {
  name: 'AdrList',
  data() {
    return {
      colors: {
        DBAAS: '#2196f3',
        APIMGMT: '#ffb300',
        CLOUDENTE: '#5e35b1',
        CCOE: '#4caf50',
        NETWORK: '#2196f3',
        AZURE: '#1a237e',
        AZAPPHOST: '#00acc1',
        MSBI: '#fbc02d',
        SQLOVER: '#78909c',
      },
      adrs: [],
      totalAdrs: 0,
      currentPage: 1,
      infraServices: [],
      categories: [],
      owners: [],
      items: {
        nodes: [],
        links: [],
      },
      filters: {
        services: [],
        category: [],
        owners: [],
        name: '',
      },
    };
  },
  methods: {
    removeFilters(type) {
      if (type === 'category') {
        this.filters.category = '';
      } else if (type === 'owners') {
        this.filters.owners = [];
      } else {
        this.filters.services = [];
      }
    },
    goToAdr(id) {
      this.$router.push(`/adrs/${id}`);
    },
    dataMarkdown(content) {
      return marked(content);
    },
    async getADRs() {
      this.items.nodes = [];
      this.items.links = [];
      this.$showLoading();
      try {
        await apollo.apolloClient
          .query({
            query: gql`
          query getADRs(
            $servicesArr: [String!],
            $ownersArr: [String!],
            $adrName: String,
          ) {
            getADRs(filter: {filterBy: {services: $servicesArr, owners: $ownersArr, name: $adrName}, includeFields: {services: true, owners: true}}, limit: "${6}", skip: "${
              (this.currentPage - 1) * 6
            }") {
              adrs {
                adrId
                category
                id
                created_at
                requirements
                name
                scope
                status
                validDate
                version
                content
                owners {
                  name
                  email
                }
                infraServices {
                  id
                  apmid
                  displayName
                  serviceId
                }
              }
              count
            }
          }
        `,
            variables: {
              servicesArr: this.filters.services.length ? this.filters.services : null,
              ownersArr: this.filters.owners.length ? this.filters.owners : null,
              adrName: this.filters.name?.length ? this.filters.name : null,
            },
          })
          .then((response) => {
            this.adrs = response.data.getADRs.adrs;
            this.totalAdrs = response.data.getADRs.count;
            const nodes = [];
            const links = [];
            const services = [];
            const owners = [];
            this.adrs.forEach((adr) => {
              if (!this.categories.find((i) => i === adr.category)) {
                this.categories.push(adr.category);
              }
              adr.owners.forEach((owner) => {
                if (!owners.find((i) => i.email === owner.email)) {
                  owners.push({ name: owner.name, email: owner.email });
                }
              });
              if (adr.infraServices.length) {
                const node = { color: '#009688' };
                node.name = adr.name;
                node.id = adr.id;
                nodes.push(node);
                adr.infraServices.forEach((service) => {
                  if (service.serviceId) {
                    const node = {};
                    const link = { value: 1 };
                    node.name = service.displayName;
                    node.serviceId = service.serviceId;
                    node.id = service.id;
                    node.color = this.colors[service.apmid] || '#9e9e9e';
                    if (!nodes.find((i) => i.id === service.id)) {
                      nodes.push(node);
                      services.push(node);
                    }
                    link.source = adr.id;
                    link.target = service.id;
                    links.push(link);
                  }
                });
              }
            });
            this.items.nodes = nodes;
            this.items.links = links;
            if (!this.owners.length) {
              this.owners = owners;
            }
            if (!this.infraServices.length) {
              this.infraServices = services;
            }
          });
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
      } finally {
        if (this.items.nodes.length && this.items.links.length) {
          this.createSankey();
        }
        this.$hideLoading();
      }
    },
    createSankey() {
      const { items } = this;
      const width = 600;
      const height = this.items.nodes.length * 9;
      const nodeWidth = 15;
      const nodeHeight = 25;
      const nodePadding = 1;
      const ENABLE_LINKS_GRADIENTS = true;
      const svg = d3.select(this.$refs.svg).attr('viewBox', [0, 0, width, height]);
      const { nodes, links } = sankey()
        .nodeId((d) => d.id)
        .nodeWidth(nodeWidth)
        .nodePadding(nodePadding)
        .extent([
          [1, 1],
          [width, height - nodeHeight],
        ])(items);
      svg
        .append('g')
        .selectAll()
        .data(nodes)
        .join('text')
        .attr('x', (d) => (d.x0 < width / 2 ? d.x1 + 6 : d.x0 - 6))
        .attr('y', (d) => (d.y1 + d.y0) / 2)
        .attr('dy', '0.35em')
        .attr('font-size', 4)
        .attr('font-weight', 500)
        .attr('text-anchor', (d) => (d.x0 < width / 2 ? 'start' : 'end'))
        .text((d) => d.name);
      svg
        .append('g')
        .attr('stroke', '#000')
        .attr('stroke-width', '0')
        .selectAll('rect')
        .data(nodes)
        .join('rect')
        .attr('x', (d) => d.x0)
        .attr('y', (d) => d.y0)
        .attr('height', (d) => d.y1 - d.y0)
        .attr('width', (d) => d.x1 - d.x0)
        .attr('fill', (d) => d.color)
        .append('title')
        .text((d) => `${d.name}\n${d.value}`);
      const link = svg
        .append('g')
        .attr('fill', 'none')
        .attr('stroke-opacity', 0.2)
        .selectAll('g')
        .data(links)
        .join('g');
      //.style("mix-blend-mode", "multiply");

      if (ENABLE_LINKS_GRADIENTS) {
        const gradient = link
          .append('linearGradient')
          .attr('id', (d) => (d.uid = `${d.source.id}-to-${d.target.id}`))
          .attr('gradientUnits', 'userSpaceOnUse')
          .attr('x1', (d) => d.source.x1)
          .attr('x2', (d) => d.target.x0);
        gradient
          .append('stop')
          .attr('offset', '0%')
          .attr('stop-color', (d) => d.source.color);
        gradient
          .append('stop')
          .attr('offset', '100%')
          .attr('stop-color', (d) => d.target.color);
      }
      link
        .append('path')
        .attr('d', sankeyLinkHorizontal())
        .attr('stroke', (d) => (!ENABLE_LINKS_GRADIENTS ? d.color : `url(#${d.uid})`))
        .on('mouseover', function () {
          d3.select(this).attr('stroke-opacity', 0.5);
        })
        .on('mouseout', function () {
          d3.select(this).attr('stroke-opacity', 0.2);
        })
        .attr('stroke-width', (d) => Math.max(1, d.width));
    },
  },
  created() {
    emitter.on('reloadAdrs', async () => {
      await this.getADRs().then(() => {
        this.createSankey();
      });
    });
  },
  unmounted() {
    emitter.off('reloadAdrs');
  },
  async mounted() {
    await this.getADRs().then(() => {
      this.createSankey();
    });
  },
};
</script>

<style scoped lang="scss">
.adr {
  height: 250px;
  border: 1px solid $grey-5;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
}
.adr-title {
  display: block;
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
  width: calc(100% - 24px);
  padding-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.adr-content {
  height: 143px;
  overflow: hidden;
  font-size: 12px;
  margin: 5px 0 10px 0;
}
.adr-holder {
  transition: all 0.5s;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
    content: '';
    opacity: 0;
    z-index: -1;
    border-radius: 10px;
    background-color: white;
  }

  &:hover,
  &:focus {
    transform: scale3d(1.006, 1.006, 1);

    .adr {
      border: none;
    }

    &::after {
      opacity: 1;
    }
  }
}

.metadata {
  white-space: nowrap;
  max-width: calc(100% - 5px);
  overflow: hidden;
  font-weight: bold;
  font-size: 12px;

  span {
    margin-right: 5px;
  }
}
.adr-filter-holder {
  display: flex;
  justify-content: space-between;

  .adr-filters {
    width: calc(100% - 45px);
  }
}
</style>
