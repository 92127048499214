<template>
  <div class="exist-service-holder" :class="{ error: error }">
    <div class="exist-service-name q-mr-sm">
      <span>{{ label }}</span>
    </div>
    <q-spinner v-if="loadData || loading" size="18px"></q-spinner>
    <q-icon
      v-if="label !== 'Choose digital service' && onlyActive"
      @click.stop="resetService"
      class="reset-ds-btn"
      name="restart_alt"
      size="18px"
    ></q-icon>
    <q-icon v-if="!loading && !loadData && currentService === undefined" name="expand_more" size="18px"></q-icon>
    <q-menu
      v-model="showPopup"
      :offset="offsetPopup ? [35, 10] : [0, 0]"
      @update:model-value="showSelect($event)"
      ref="popupMenu"
      @show="focusOnSelect"
      :max-width="fullWidth ? '400px' : '250px'"
      class="service-banner"
    >
      <q-banner>
        <q-select
          v-model="existService"
          use-input
          input-debounce="0"
          label="Search"
          :options="options"
          option-value="digitalServiceName"
          option-label="digitalServiceName"
          @filter="filterService"
          @update:model-value="closeSelectPopup"
          :style="fullWidth ? 'width: 365px' : 'width: 217px'"
          popup-content-class="service-select-menu"
          class="service-select"
          dense
          ref="serviceSelect"
          :loading="loadData"
        >
          <template v-slot:before-options>
            <div class="label-section">
              <span>Name</span>
              <span>APM ID</span>
            </div>
          </template>
          <template v-slot:prepend>
            <q-icon name="search" size="xs" />
          </template>
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey"> No results </q-item-section>
            </q-item>
          </template>
          <template v-slot:option="scope">
            <q-item class="ds-list-item" v-bind="scope.itemProps" dense>
              <q-item-section>
                <q-item-label class="label-name">{{ scope.opt.digitalServiceName }}</q-item-label>
                <q-item-label class="label-apmid">{{ scope.opt.apmId }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </q-banner>
    </q-menu>
    <slot v-if="!error" name="hint"></slot>
    <span v-if="error" class="error">Validation error</span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import emitter from 'tiny-emitter/instance';
import { DIGITAL_SERVICES_ACTIONS, DIGITAL_SERVICES_GETTERS } from '@/store/modules/digital-services';

export default {
  name: 'ServiceSelect',
  props: {
    offsetPopup: {
      type: Boolean,
      default: () => false,
    },
    error: {
      type: Boolean,
      default: () => false,
    },
    fullWidth: {
      type: Boolean,
      default: () => false,
    },
    label: {
      type: String,
      default: () => 'Search APM',
    },
    onlyActive: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      selectOptions: [],
      options: [],
      existService: undefined,
      loadData: false,
      showPopup: false,
    };
  },
  created() {
    emitter.on('clearServiceSelect', () => {
      this.existService = undefined;
    });
    emitter.on('getAllApplications', async () => {
      await this.getAllApplications();
    });
  },
  unmounted() {
    emitter.off('clearServiceSelect');
    emitter.off('getAllApplications');
  },
  computed: {
    ...mapGetters('digitalServices', {
      applications: DIGITAL_SERVICES_GETTERS.ALL_APPLICATIONS,
      activeApplications: DIGITAL_SERVICES_GETTERS.ACTIVE_APPLICATIONS,
      currentService: DIGITAL_SERVICES_GETTERS.CURRENT_SERVICE,
    }),
  },
  methods: {
    ...mapActions('digitalServices', {
      getApplications: DIGITAL_SERVICES_ACTIONS.GET_ALL_APPLICATIONS,
      getActiveApplications: DIGITAL_SERVICES_ACTIONS.GET_ACTIVE_APPLICATIONS,
    }),
    filterService(val, update) {
      update(() => {
        const needle = val ? val.toLowerCase() : '';
        this.options = this.selectOptions.filter(
          (v) =>
            v.digitalServiceName?.toLowerCase().indexOf(needle) > -1 || v.apmId?.toLowerCase().indexOf(needle) > -1,
        );
      });
    },
    showSelect(event) {
      this.loadData ? (this.showPopup = false) : (this.showPopup = event);
    },
    focusOnSelect() {
      this.$refs.serviceSelect.showPopup();
      this.$refs.serviceSelect.focus();
    },
    async closeSelectPopup(value) {
      if (value) {
        this.$emit('addService', value);
      }
      this.existService = value;
      this.$refs.popupMenu.hide();
      this.$refs.serviceSelect.hidePopup();
    },
    resetService() {
      this.$emit('reset');
      this.existService = undefined;
    },
    async getAllApplications() {
      this.loadData = true;
      try {
        await this.getApplications();
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
      } finally {
        this.loadData = false;
      }
    },
  },
  async mounted() {
    this.loadData = true;
    try {
      if (!this.activeApplications.length) {
        await this.getActiveApplications();
      }
      if (!this.applications.length) {
        await this.getAllApplications();
      }
    } catch (error) {
      this.$notify('negative', 'Error', error.message);
    } finally {
      this.loadData = false;
    }

    if (this.onlyActive) {
      this.selectOptions = this.activeApplications;
    } else {
      this.selectOptions = this.applications;
    }
  },
};
</script>

<style scoped lang="scss">
.exist-service-holder {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  width: 200px;
  height: 35px;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    width: 1px;
    height: 30px;
    background-color: #dddee2;
    position: absolute;
    left: 0;
    top: 5px;
  }

  &:after {
    right: 0;
    left: auto;
  }
}

.label-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  color: #a5a5a5;
  padding: 5px 16px 0 16px;
}

.label-name,
.label-apmid {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}
.label-name {
  width: 110px;
  padding-right: 5px;
}
.label-apmid {
  width: 75px;
  text-align: right;
}
.exist-service-holder.error {
  border: 2px solid var(--q-negative);
  .exist-service-name {
    color: var(--q-negative);
  }
}
span.error {
  position: absolute;
  bottom: -25px;
  font-size: 11px;
  color: var(--q-negative);
}
</style>
