<template>
  <q-page class="main-wrapper-page single-page" style="background-color: var(--q-accent)">
    <div class="standards-holder q-mb-lg q-px-md q-py-md bg-white">
      <div class="row-holder column" v-if="allStandards.length" :style="{ height: `${allStandards.length * 50}px}` }">
        <div class="flex-break hidden"></div>
        <div class="flex-break"></div>
        <div class="flex-break"></div>
        <div class="flex-break"></div>
        <div class="flex-break"></div>
        <div class="flex-break"></div>
        <div v-for="(standard, key) in allStandards" :key="key" class="col-standard">
          <div class="standard-holder q-px-md q-py-md">
            <div class="name flex no-wrap items-center q-mb-md">
              <q-icon name="engp:engp-ico-standards" class="q-mr-sm" color="primary" size="28px"></q-icon>
              <a v-if="standard.standardLink" :href="standard.standardLink" target="_blank">
                <span>{{ standard.standardName }}</span>
              </a>
              <span v-else>{{ standard.standardName }}</span>
            </div>
            <div
              v-if="standard.description && standard.description.trim().length"
              class="service-description-markdown"
              v-html="dataMarkdown(standard.description)"
            ></div>
            <template v-if="standard.infraServices">
              <span
                class="block text-uppercase q-mb-sm"
                style="font-size: 10px; font-weight: bold; color: #979ba4; letter-spacing: 1px"
                >Associated with</span
              >
              <infra-service
                class="standard-service"
                style="max-width: 45%"
                v-for="service in standard.infraServices.slice(0, 3)"
                :key="service.id"
                :data-service="service"
              />
              <q-btn
                v-if="standard.infraServices && standard.infraServices.slice(3).length"
                flat
                size="md"
                color="indigo"
                style="min-height: 20px"
                class="q-px-none q-py-none q-mr-sm btn--no-hover"
                :ripple="false"
              >
                +{{ standard.infraServices.length - 3 }}
                <q-menu self="top right" max-width="480px" class="infra-services-holder">
                  <q-banner>
                    <infra-service
                      class="standard-service"
                      v-for="service in standard.infraServices.slice(3)"
                      :key="service.id"
                      :data-service="service"
                    />
                  </q-banner>
                </q-menu>
              </q-btn>
            </template>
          </div>
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import { marked } from 'marked';
import { mapActions, mapGetters } from 'vuex';
import InfraService from '@/pages/digitalServices/components/InfraService';
import { STANDARDS_ACTIONS, STANDARDS_GETTERS } from '@/store/modules/standards';

export default {
  name: 'StandardsPage',
  components: {
    InfraService,
  },
  computed: {
    ...mapGetters('standards', {
      allStandards: STANDARDS_GETTERS.ALL,
    }),
  },
  methods: {
    ...mapActions('standards', {
      getStandards: STANDARDS_ACTIONS.GET_STANDARDS,
    }),
    dataMarkdown(data) {
      return marked(data);
    },
  },
  async mounted() {
    this.$showLoading();
    try {
      await this.getStandards();
    } catch (error) {
      this.$notify('negative', 'Error', error.message);
    } finally {
      this.$hideLoading();
    }
  },
  data() {
    return {
      standards: [],
    };
  },
};
</script>

<style scoped lang="scss">
.standards-holder {
  max-width: 1600px;
  margin: 0 auto;
  border-radius: 15px;
}
.standard-holder {
  border: 2px solid #dedfe4;
  border-radius: 15px;

  &:hover {
    background-color: $accent;
  }

  a {
    color: $text-color;
    text-decoration: none;
  }

  .name {
    span {
      font-size: 14px;
      font-weight: bold;
      line-height: 1.4;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .standard-service {
    height: 22px;
    border-radius: 15px;
  }
}

.flex-break {
  flex: 1 0 100% !important;
  width: 0 !important;
}

.row-holder {
  height: 3000px;
}

$x: 6;

@for $i from 1 through ($x - 1) {
  .row-holder > div:nth-child(#{$x}n + #{$i}) {
    order: #{$i};
  }

  .row-holder > div:nth-child(#{$x}n) {
    order: #{$x};
  }
}

.col-standard {
  width: 16.666%;
  padding: 5px;
}

@media (max-width: $breakpoint-lg-max) {
  $x: 4;

  @for $i from 1 through ($x - 1) {
    .row-holder > div:nth-child(#{$x}n + #{$i}) {
      order: #{$i};
    }

    .row-holder > div:nth-child(#{$x}n) {
      order: #{$x};
    }
  }

  .col-standard {
    width: 25%;
  }
}

@media (max-width: $breakpoint-md-max) {
  $x: 3;

  @for $i from 1 through ($x - 1) {
    .row-holder > div:nth-child(#{$x}n + #{$i}) {
      order: #{$i};
    }

    .row-holder > div:nth-child(#{$x}n) {
      order: #{$x};
    }
  }

  .col-standard {
    width: 33.33%;
  }
}

@media (max-width: $breakpoint-sm-max) {
  $x: 2;

  @for $i from 1 through ($x - 1) {
    .row-holder > div:nth-child(#{$x}n + #{$i}) {
      order: #{$i};
    }

    .row-holder > div:nth-child(#{$x}n) {
      order: #{$x};
    }
  }

  .col-standard {
    width: 50%;
  }
}
</style>
