import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createBlock(_component_q_select, {
    label: _ctx.control.uischema.label || undefined,
    required: _ctx.control.required,
    "error-messages": _ctx.control.errors,
    outlined: "",
    "model-value": _ctx.control.data,
    options: _ctx.options,
    "emit-value": "",
    rules: _ctx.fieldRules,
    dense: _ctx.dense,
    "bg-color": "white",
    "onUpdate:modelValue": _ctx.onChange,
    readonly: _ctx.control.uischema.readOnly,
    disable: _ctx.disable,
    class: "q-pb-none input-form"
  }, _createSlots({ _: 2 }, [
    (_ctx.control.uischema?.options?.tooltip)
      ? {
          name: "append",
          fn: _withCtx(() => [
            _createVNode(_component_q_icon, {
              name: "help_outline",
              class: "cursor-pointer"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_tooltip, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.control.uischema?.options?.tooltip), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        }
      : undefined
  ]), 1032, ["label", "required", "error-messages", "model-value", "options", "rules", "dense", "onUpdate:modelValue", "readonly", "disable"]))
}