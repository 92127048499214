<template>
  <q-table
    :rows="comments"
    :columns="cols"
    flat
    :pagination="{
      sortBy: 'createdAt',
      descending: true,
    }"
    class="table-info table-actions"
  >
    <template v-slot:body-cell-entityDescription="props">
      <q-td :props="props">
        <ul style="max-width: 480px" class="q-px-none q-mx-none q-py-none q-my-none">
          <li
            class="inline-block q-mx-xs"
            style="list-style-type: none; font-size: 12px"
            v-for="(value, key) in props.row.entityDescription"
            :key="key"
          >
            <b>{{ key }}:</b> {{ value }}
          </li>
        </ul>
      </q-td>
    </template>
    <template v-slot:body-cell-createdBy="props">
      <q-td :props="props">
        {{ props.row.createdBy.name }}
      </q-td>
    </template>
    <template v-slot:body-cell-createdAt="props">
      <q-td :props="props">
        <span>{{ $options.dayjs(props.row.createdAt).format('DD.MM.YYYY HH:mm') }}</span>
      </q-td>
    </template>
    <template v-slot:body-cell-comment="props">
      <q-td :props="props">
        <span style="display: block; max-width: 180px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
          {{ props.row.comment }}
          <q-tooltip v-if="props.row.comment.length > 25" max-width="480px">{{ props.row.comment }}</q-tooltip>
        </span>
      </q-td>
    </template>
  </q-table>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'ActionCommentsTable',
  dayjs,
  props: {
    comments: { type: Array },
  },
  data() {
    return {
      cols: [
        {
          label: 'Action',
          name: 'action',
          field: 'action',
          align: 'left',
        },
        {
          label: 'Entity',
          name: 'entity',
          field: 'entity',
          align: 'left',
        },
        {
          label: 'Entity Info',
          name: 'entityDescription',
          field: 'entityDescription',
          align: 'left',
        },
        {
          label: 'Comment',
          name: 'comment',
          field: 'comment',
          align: 'left',
        },
        {
          label: 'Created By',
          name: 'createdBy',
          field: 'createdBy',
          align: 'left',
        },
        {
          label: 'Created At',
          name: 'createdAt',
          field: 'createdAt',
          align: 'left',
          sortable: true,
          sortOrder: 'da',
        },
      ],
    };
  },
};
</script>
