
import { v4 as uuidv4 } from 'uuid';
import {
  ControlElement,
  composePaths,
  createDefaultValue,
  UISchemaElement,
  findUISchema,
  Resolve,
  JsonSchema,
  getFirstPrimitiveProp,
} from '@jsonforms/core';
import { defineComponent, ref } from 'vue';
import { DispatchRenderer, rendererProps, useJsonFormsArrayControl, RendererProps } from '@jsonforms/vue';
import { useNested } from '../util';

const controlRenderer = defineComponent({
  name: 'custom-vnets-layout-renderer',
  components: {
    DispatchRenderer,
  },
  props: {
    ...rendererProps<ControlElement>(),
    manifest: { type: Object, default: () => ({}) },
    freezeManifest: { type: Object, default: () => ({}) },
    currentEnv: { type: String, default: () => '' },
    networkPermissions: { type: Object },
    showFilters: { type: Boolean, default: false },
  },
  setup(props: RendererProps<ControlElement>) {
    const page = ref<number>(1);
    const showVnetsCount = ref<number>(5);
    const control = useJsonFormsArrayControl(props);
    const suggestToDelete = ref<null | number>(null);
    const vnetsFilters = ref<any>({
      name: undefined,
      region: undefined,
      resourceGroup: undefined,
      size: undefined,
    });
    useNested('array');
    return {
      ...control,
      suggestToDelete,
      page,
      showVnetsCount,
      vnetsFilters,
    };
  },
  computed: {
    filterdVnets() {
      if (this.control.data?.length) {
        return Object.values(this.control.data).filter((zone: any) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const prop in this.vnetsFilters) {
            if (this.vnetsFilters[prop]) {
              if (prop === 'type') {
                if (this.vnetsFilters.type !== zone.type) {
                  return false;
                }
              } else {
                if (!new RegExp(this.vnetsFilters[prop], 'i').test(zone[prop])) {
                  return false;
                }
              }
            }
          }
          return true;
        });
      } else {
        return [];
      }
    },
    showingVnets() {
      return JSON.parse(JSON.stringify(this.filterdVnets)).splice(
        (this.page - 1) * this.showVnetsCount,
        this.showVnetsCount,
      );
    },
    countItems() {
      return this.control.data.length;
    },
    addDisabled(): boolean {
      return (
        !this.networkPermissions?.vnetsEnvs.includes(this.currentEnv) ||
        !this.control.enabled ||
        !this.currentEnv ||
        (this.arraySchema !== undefined &&
          this.arraySchema.maxItems !== undefined &&
          this.dataLength >= this.arraySchema.maxItems)
      );
    },
    dataLength(): number {
      return this.control.data ? this.control.data.length : 0;
    },
    foundUISchema(): UISchemaElement {
      return findUISchema(
        this.control.uischemas,
        this.control.schema,
        this.control.uischema.scope,
        this.control.path,
        undefined,
        this.control.uischema,
        this.control.rootSchema,
      );
    },
    arraySchema(): JsonSchema | undefined {
      return Resolve.schema(this.control.rootSchema, this.control.uischema.scope, this.control.rootSchema);
    },
  },
  methods: {
    composePaths,
    createDefaultValue,
    addButtonClick() {
      this.resetFilters();
      this.addItem(this.control.path, createDefaultValue(this.control.schema))();
      this.control.data[this.control.data.length - 1].isNewEl = true;
      this.control.data[this.control.data.length - 1].id = uuidv4();
      this.page = Math.ceil(this.filterdVnets.length / this.showVnetsCount);
    },
    elIndex(el: any) {
      return this.control.data.findIndex((a: any) => a.id === el.id);
    },
    resetFilters() {
      this.vnetsFilters.name = undefined;
      this.vnetsFilters.region = undefined;
      this.vnetsFilters.resourceGroup = undefined;
      this.vnetsFilters.size = undefined;
    },
    removeItemsClick(event: Event, toDelete: number[]): void {
      event.stopPropagation();
      this.removeItems?.(this.control.path, toDelete)();
    },
    childLabelForIndex(index: number | null) {
      if (index === null) {
        return '';
      }
      const childLabelProp =
        this.control.uischema.options?.childLabelProp ?? getFirstPrimitiveProp(this.control.schema);
      if (!childLabelProp) {
        return `${index}`;
      }
      const labelValue = Resolve.data(this.control.data, composePaths(`${index}`, childLabelProp));
      if (labelValue === undefined || labelValue === null || Number.isNaN(labelValue)) {
        return '';
      }
      return `${labelValue}`;
    },
    canBeDelete(element: any) {
      return !element.isNewEl;
    },
  },
});

export default controlRenderer;
