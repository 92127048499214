import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!

  return (_openBlock(), _createBlock(_component_q_input, {
    type: "number",
    outlined: "",
    label: _ctx.control.uischema.label || undefined,
    rules: _ctx.fieldRules,
    class: "input-form",
    "model-value": _ctx.control.data,
    "onUpdate:modelValue": _ctx.onChange
  }, null, 8, ["label", "rules", "model-value", "onUpdate:modelValue"]))
}