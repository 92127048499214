<template>
  <div class="filter-holder">
    <ul class="filter-legend" style="padding-top: 10px; top: -50px; border-top-left-radius: 10px">
      <li style="padding: 0 15px; color: #35454a; opacity: 1">Select one or more items to filter of the services</li>
    </ul>
    <ul class="filter-legend" style="padding-top: 20px">
      <li
        v-for="type in serviceTypes"
        :key="type.value"
        :class="{ active: filters.serviceTypes[type.value] }"
        @click="$emit('filterByType', type.value)"
      >
        {{ type.label }}
      </li>
    </ul>
    <ul class="types" style="padding-top: 20px">
      <strong>Cloud Type</strong>
      <li
        v-for="type in serviceTypes"
        :key="type.value"
        :class="[{ active: filters.serviceTypes[type.value] }, type.value]"
        @click="$emit('filterByType', type.value)"
      >
        <span><q-icon v-if="filters.serviceTypes[type.value]" name="done" size="12px"></q-icon></span>
      </li>
    </ul>
    <ul class="filter-legend" style="padding-top: 20px">
      <li
        v-for="attribute in attributes"
        :key="attribute.value"
        :class="{ active: filters.attributes[attribute.value] }"
        @click="$emit('filterByAttribute', attribute.value)"
      >
        {{ attribute.label }}
      </li>
    </ul>
    <ul class="types attributes" style="padding-top: 18px">
      <strong>Attributes</strong>
      <li
        v-for="attribute in attributes"
        :key="attribute.value"
        :class="{ active: filters.attributes[attribute.value] }"
        @click="$emit('filterByAttribute', attribute.value)"
      >
        <q-icon :name="attribute.icon" size="18px" />
      </li>
    </ul>
    <ul class="filter-legend" style="padding-top: 15px; border-bottom-left-radius: 10px">
      <li
        @click="$emit('resetFilters')"
        :class="{ active: canReset }"
        style="font-size: 14px; color: #35454a; font-weight: bold"
      >
        Reset
      </li>
    </ul>
    <ul class="types" style="padding-top: 15px">
      <li @click="$emit('resetFilters')" :class="{ active: canReset }">
        <q-icon name="replay" size="20px" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'CatalogFilter',
  props: {
    filters: {
      type: Object,
    },
    canReset: {
      type: Boolean,
    },
  },
  data() {
    return {
      serviceTypes: [
        { label: 'Private CLoud', value: 'private' },
        { label: 'Public CLoud', value: 'public' },
        { label: 'On Prem', value: 'onPrem' },
        { label: 'Tools', value: 'tools' },
        { label: 'Independent', value: 'independent' },
        { label: 'N/A', value: 'notAvailable' },
      ],
      attributes: [
        { label: 'Shared services', value: 'sharedServices', icon: 'share' },
        { label: 'Stratum', value: 'stratum', icon: 'engp:engp-ico-stratum' },
        { label: 'Cloud Infrastructure Template', value: 'certified', icon: 'engp:engp-ico-cert_product' },
        { label: 'Planned', value: 'planned', icon: 'engp:engp-ico-planned' },
        { label: 'Not Recommended', value: 'deprecated', icon: 'engp:engp-ico-deprecated' },
      ],
    };
  },
};
</script>

<style lang="scss">
.filter-holder {
  position: fixed;
  right: 20px;
  top: 30%;
  z-index: 999;

  .types {
    position: relative;

    strong {
      position: absolute;
      font-size: 10px;
      text-transform: uppercase;
      color: #a5a5a5;
      display: block;
      right: -185px;
      top: -2px;
      background-color: white;
      padding: 0 5px;
      min-width: 70px;
      transition: all 0.5s;
    }

    &:before {
      content: '';
      width: 20px;
      height: 1px;
      background-color: #dddbdb;
      position: absolute;
      top: 5px;
      right: 0;
      transition: all 0.5s;
    }
  }

  &:hover {
    .types {
      &:before {
        width: 140px;
      }

      strong {
        right: 0;
      }
    }

    .filter-legend {
      right: -20px;
    }
  }

  .attributes {
    li {
      margin-bottom: 6px;
    }
  }

  .filter-legend {
    position: absolute;
    width: 225px;
    background-color: white;
    right: -250px;
    z-index: -1;
    transition: all 0.5s;
    box-shadow: 0px 20px 40px #3c415026;

    li {
      font-size: 12px;
      text-align: right;
      padding-right: 60px;
      cursor: pointer;
      color: $text-color;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      margin-bottom: 10px;
      cursor: pointer;
      opacity: 0.5;

      &.active {
        opacity: 1;
        font-weight: bold;
      }

      &.private {
        span {
          background-color: #e9f4ff;
          border-color: #428ce1;
        }
      }

      &.public {
        span {
          background-color: #e3f9ed;
          border-color: #73e1a5;
        }
      }

      &.onPrem {
        span {
          background-color: #eee6f8;
          border-color: #a88cd1;
        }
      }

      &.independent {
        span {
          background-color: #eff5c6;
          border-color: #b3bb7e;
        }
      }

      &.tools {
        span {
          background-color: #f4f0e6;
          border-color: #bfb9a7;
        }
      }
    }

    span {
      position: relative;
      display: block;
      min-width: 18px;
      width: 18px;
      min-height: 18px;
      border-radius: 50%;
      border: 2px solid;

      i {
        position: absolute;
        left: 1px;
        top: 1px;
      }
    }
  }
}
</style>
