<template>
  <q-page
    class="main-wrapper-page single-service-page single-page digital-service-page"
    style="background-color: var(--q-accent)"
  >
    <div class="row no-wrap q-col-gutter-md">
      <div class="col col-xl-2 col-lg-3 col-md-4">
        <div class="nav-holder">
          <q-scroll-area
            :thumb-style="{
              borderRadius: '5px',
              width: '3px',
              right: '-2px',
              background: '#9f9f9f',
              height: '3px',
              opacity: 1,
            }"
            :style="`max-width: 100%; height: calc(100vh - ${currentBanner ? '215px' : '165px'})`"
          >
            <div class="flex items-center justify-between">
              <span
                class="block q-my-none q-mx-none"
                style="cursor: pointer; font-size: 20px; font-weight: 500"
                @click="scrollToItem('info')"
                ><q-icon name="info" size="14px" class="q-mr-xs"></q-icon>Info</span
              >
              <q-btn
                v-if="!acccesProductsLoaindg"
                flat
                icon="view_in_ar"
                @click="getAllAccessProducts"
                round
                size="12px"
              >
                <q-tooltip>Show all access products</q-tooltip>
              </q-btn>
              <q-spinner v-else size="20px"></q-spinner>
            </div>
            <div v-for="category in serviceCategories" :key="category.name">
              <span class="services-category">{{ category.name }}</span>
              <ul class="services-list">
                <li v-for="service in category.infraServices" :key="service.id" @click="scrollToItem(service)">
                  <div class="flex items-center">
                    <div class="status">
                      <q-spinner-ios
                        v-if="service.status === 'waiting'"
                        color="text-color"
                        size="1.2em"
                        class="q-mr-sm"
                      />
                      <span v-else :class="service.status.toLowerCase()"></span>
                    </div>
                    <strong
                      >{{ service.infraService.displayName || service.infraService.serviceName }}
                      <sup v-if="service.kind === 'RegistrationDraft'" class="q-ml-xs q-mb-md">
                        <q-icon name="mdi-alert-circle" size="16px" color="warning"></q-icon>
                        <q-tooltip anchor="top right"
                          >This registration is in Draft mode, please edit them to add details and start processing
                        </q-tooltip>
                      </sup>
                    </strong>
                  </div>
                  <template v-if="showAccessProducts && service.accessProducts?.length">
                    <q-list class="access-product">
                      <q-item v-for="(role, index) in service.accessProducts" :key="index">
                        <q-checkbox
                          v-model="selectedAccessProducts"
                          @update:model-value="itemSelected(role.name)"
                          :disable="existingRolesSet.includes(role.name)"
                          size="sm"
                          dense
                          :label="role.name"
                          :val="role.name"
                        />
                        <q-btn
                          icon="bookmark_added"
                          class="q-ml-sm btn--no-hover"
                          style="min-width: 20px; min-height: 20px; width: 20px"
                          size="12px"
                          color="blue-grey-8"
                          flat
                          round
                          @click="orderAccessProduct(role.name)"
                        >
                          <q-tooltip max-width="280px">Order Access Product {{ role.name }}</q-tooltip>
                        </q-btn>
                      </q-item>
                    </q-list>
                  </template>
                </li>
              </ul>
            </div>
          </q-scroll-area>
          <q-btn
            style="border-radius: 10px; text-transform: capitalize; bottom: -4px; left: 20%"
            color="primary"
            text-color="text-color"
            :disabled="!newRolesCount"
            v-if="showAccessProducts"
            @click="openConfirmOrderDialog"
            >Order products</q-btn
          >
        </div>
      </div>
      <div class="col col-xl-10 col-lg-9 col-md-8">
        <q-scroll-area
          style="width: 100%; padding-right: 10px"
          :style="`height: calc(100vh - ${currentBanner ? '300px' : '250px'})`"
          :thumb-style="{ borderRadius: '5px', background: '#9f9f9f', width: '3px', opacity: 1, right: 0 }"
        >
          <div v-if="application" class="q-px-md q-py-none">
            <div class="block-holder service-info">
              <h5 class="q-my-none q-mb-md text-weight-bold" ref="info">Info</h5>
              <div class="row no-wrap">
                <div class="col col-12">
                  <div class="row">
                    <div class="col col-6">
                      <table class="info-table bordered">
                        <tbody>
                          <tr>
                            <th class="text-left">APMID:</th>
                            <td class="text-left">{{ application.apmId }}</td>
                          </tr>
                          <tr>
                            <th class="text-left">Service Name:</th>
                            <td class="text-left">{{ application.digitalServiceName }}</td>
                          </tr>
                          <tr>
                            <th class="text-left">Created At:</th>
                            <td class="text-left">
                              {{ $options.dayjs(application.createdAt).format('DD.MM.YYYY HH:mm') }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col col-6">
                      <table class="info-table bordered">
                        <tbody>
                          <tr>
                            <th class="text-left">Status:</th>
                            <td class="text-left">{{ application.status }}</td>
                          </tr>
                          <tr>
                            <th class="text-left">Link:</th>
                            <td class="text-left">
                              <a :href="application.url" target="_blank">{{ application.url }}</a>
                            </td>
                          </tr>
                          <tr>
                            <th class="text-left">Major Incidents:</th>
                            <td class="text-left">{{ application.majorIncidents?.length || 0 }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="owner-info row q-pt-sm">
                    <div class="col-6 q-pt-sm" v-if="application.itOwnerName">
                      <div class="row items-center">
                        <div class="col-2">
                          <img
                            width="60"
                            height="60"
                            style="border-radius: 50%"
                            :src="`https://whoswho.swissre.com/photos/size300/${application.itOwnerId}.jpg`"
                            :alt="application.itOwnerName"
                          />
                        </div>
                        <div class="col-10">
                          <div class="flex column justify-center items-start">
                            <strong style="font-size: 11px" class="block">IT Owner:</strong>
                            <span class="block" style="font-size: 16px">{{ application.itOwnerName }}</span>
                            <span>{{ application.itOwnerEmail }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 q-pt-sm" v-if="application.itCustodianName">
                      <div class="row items-center">
                        <div class="col-2">
                          <img
                            width="60"
                            height="60"
                            style="border-radius: 50%"
                            :src="`https://whoswho.swissre.com/photos/size300/${application.itCustodianId}.jpg`"
                            :alt="application.itCustodianName"
                          />
                        </div>
                        <div class="col-10">
                          <div class="flex column justify-center items-start">
                            <strong style="font-size: 11px" class="block">IT Owner Custodian:</strong>
                            <span class="block" style="font-size: 16px">{{ application.itCustodianName }}</span>
                            <span>{{ application.itCustodianEmail }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 q-pt-sm" v-if="application.businessOwnerName">
                      <div class="row items-center">
                        <div class="col-2">
                          <img
                            width="60"
                            height="60"
                            style="border-radius: 50%"
                            :src="`https://whoswho.swissre.com/photos/size300/${application.businessOwnerId}.jpg`"
                            :alt="application.businessOwnerName"
                          />
                        </div>
                        <div class="col-10">
                          <div class="flex column justify-center items-start">
                            <strong style="font-size: 11px" class="block">Business Owner:</strong>
                            <span class="block" style="font-size: 16px">{{ application.businessOwnerName }}</span>
                            <span>{{ application.businessOwnerEmail }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 q-pt-sm" v-if="application.businessOwnerCustodianName">
                      <div class="row items-center">
                        <div class="col-2">
                          <img
                            width="60"
                            height="60"
                            style="border-radius: 50%"
                            :src="`https://whoswho.swissre.com/photos/size300/${application.businessOwnerCustodianId}.jpg`"
                            :alt="application.businessOwnerCustodianName"
                          />
                        </div>
                        <div class="col-10">
                          <div class="flex column justify-center items-start">
                            <strong style="font-size: 11px" class="block">Business Owner Custodian:</strong>
                            <span class="block" style="font-size: 16px">{{
                              application.businessOwnerCustodianName
                            }}</span>
                            <span>{{ application.businessOwnerCustodianEmail }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="q-mt-sm" style="border-top: 1px solid rgba(0, 0, 0, 0.12)">
                    <q-tabs
                      v-model="infoTab"
                      inline-label
                      content-class="justify-start"
                      class="q-mt-md widget-tabs"
                      indicator-color="primary"
                    >
                      <q-tab name="costData" label="Cost data" />
                      <q-tab v-if="adrs.length" name="adrs" label="ADRs you may be interested in">
                        <span class="indicator">
                          <span
                            style="background-color: #9e9e9e"
                            :style="`width: ${
                              (allAdrs.filter((i) => i.hasDigitalServiceAdherence === null).length / totalAdrs) * 100
                            }%`"
                          ></span>
                          <span
                            style="background-color: #4caf50"
                            :style="`width: ${
                              (allAdrs.filter((i) => i.hasDigitalServiceAdherence).length / totalAdrs) * 100
                            }%`"
                          ></span>
                          <span
                            style="background-color: #c62828"
                            :style="`width: ${
                              (allAdrs.filter((i) => i.hasDigitalServiceAdherence === false).length / totalAdrs) * 100
                            }%`"
                          ></span>
                        </span>
                      </q-tab>
                    </q-tabs>
                    <q-tab-panels v-model="infoTab" animated>
                      <q-tab-panel name="costData">
                        <div class="flex items-center justify-between q-mt-sm">
                          <strong class="block">Detailed cost data:</strong>
                          <q-select
                            :model-value="costDate"
                            option-label="label"
                            option-value="value"
                            borderless
                            :options="costDates"
                            emit-value
                            map-options
                            @update:model-value="setCostDate"
                            class="cost-select"
                            dense
                          />
                        </div>
                        <cost-chart
                          v-if="!loadCostData && costData && Object.keys(costData).length"
                          :cost-data="costData"
                        />
                        <div v-else-if="loadCostData" class="flex items-center justify-center q-py-lg">
                          <q-spinner-bars size="2em" color="primary"></q-spinner-bars>
                        </div>
                        <div v-else class="flex items-center justify-center">
                          <strong>No cost data</strong>
                        </div>
                      </q-tab-panel>
                      <q-tab-panel name="adrs" class="q-px-lg q-py-lg">
                        <div class="row q-col-gutter-xs q-pt-md adrs-holder">
                          <div
                            v-for="adr in adrs"
                            :key="adr.id"
                            @click="goToAdr(adr.adrId)"
                            class="col-lg-4 col-md-6 q-px-xs q-py-xs adr-holder"
                          >
                            <div class="adr">
                              <span class="adr-title">{{ adr.name }}</span>
                              <span class="scope" style="color: #009688; font-size: 14px"
                                >For {{ adr.scope?.units }}, {{ adr.scope?.regions }}</span
                              >
                              <div
                                class="adr-content service-description-markdown"
                                v-html="dataMarkdown(adr.requirements || adr.content)"
                              ></div>
                              <div class="adr-title-holder flex items-center justify-between no-wrap">
                                <span class="block"><b>Valid date:</b> {{ adr.validDate }}</span>
                                <div class="adherence">
                                  <span
                                    :style="`background-color:${
                                      adr.hasDigitalServiceAdherence === null
                                        ? '#9e9e9e'
                                        : adr.hasDigitalServiceAdherence
                                        ? '#4caf50'
                                        : '#c62828'
                                    }`"
                                  ></span>
                                  <b>{{
                                    adr.hasDigitalServiceAdherence === null
                                      ? 'Undefined'
                                      : adr.hasDigitalServiceAdherence
                                      ? 'Adherent'
                                      : 'Not Adherent'
                                  }}</b>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="totalAdrs > 6" class="flex flex-center q-mt-lg">
                          <q-pagination
                            :max="Math.ceil(totalAdrs / 6)"
                            v-model="currentPage"
                            @update:model-value="loadAdrs"
                          ></q-pagination>
                        </div>
                      </q-tab-panel>
                    </q-tab-panels>
                  </div>
                </div>
              </div>
            </div>
            <template v-if="application.registrations && application.registrations.length">
              <div
                class="block-holder"
                v-for="service in application.registrations"
                :key="service.infraService.id"
                :ref="service.infraService.apmid"
              >
                <q-expansion-item
                  :ref="service.infraService.id"
                  @before-show="getWidgetInfo(service)"
                  :hide-expand-icon="service.kind === 'RegistrationDraft'"
                  class="service-item"
                >
                  <template v-slot:header>
                    <div class="service-header">
                      <div class="service-name flex items-center">
                        <span
                          class="icon-service"
                          :class="[
                            `${service.infraService.cloudType ? service.infraService.cloudType.toLowerCase() : 'na'}`,
                            { tool: service.type === 'Tool' },
                          ]"
                        >
                          <infra-service-logo
                            v-if="service.infraService.icon && service.infraService.icon !== 'default'"
                            :logo="service.infraService.icon"
                          />
                          <q-icon v-else name="settings" class="default-icon" size="30px"></q-icon>
                        </span>
                        <strong>{{ service.infraService.displayName || service.infraService.serviceName }}</strong>
                      </div>
                      <div class="status-holder flex items-center q-ml-auto">
                        <span class="label-block q-mr-sm">Status:</span>
                        <div class="status self-end">
                          <div v-if="service.kind === 'RegistrationDraft'" class="flex flex-center items-center">
                            <span></span>
                            Draft
                            <sup class="q-ml-xs q-mb-md">
                              <q-icon name="mdi-alert-circle" size="16px" color="warning"></q-icon>
                              <q-tooltip
                                >This registration is in Draft mode, please edit them to add details and start
                                processing
                              </q-tooltip>
                            </sup>
                          </div>
                          <div v-else class="flex flex-center items-center">
                            <span :class="service.status.toLowerCase()"></span>
                            {{ serviceStatus(service.status) }}
                          </div>
                        </div>
                      </div>
                      <q-btn
                        @click.stop
                        class="q-px-none q-py-none q-ml-lg btn--no-hover"
                        :ripple="false"
                        flat
                        icon="more_vert"
                      >
                        <q-menu self="top right" class="service-actions">
                          <q-list dense style="min-width: 100px">
                            <q-item
                              v-if="
                                service.kind !== 'RegistrationCertifiedProduct' &&
                                service.infraService.kind &&
                                service.infraService.kind !== 'RegistrationCertifiedProduct'
                              "
                              @click="showWizard(service, 'update')"
                              :disable="
                                !(application.digitalServiceActive || application.status === 'To Be Decommissioned') ||
                                isEditDisable(service.infraService.apmid)
                              "
                              clickable
                              v-close-popup
                            >
                              <q-item-section avatar>
                                <q-icon color="text-color" name="edit" size="20px" />
                              </q-item-section>
                              <q-item-section>Edit</q-item-section>
                            </q-item>
                            <q-item clickable disable v-close-popup>
                              <q-item-section avatar>
                                <q-icon color="text-color" name="list_alt" size="20px" />
                              </q-item-section>
                              <q-item-section>View Manifest</q-item-section>
                            </q-item>
                            <q-item
                              v-if="service.kind === 'RegistrationDraft'"
                              clickable
                              v-close-popup
                              @click="removeRegistration(service)"
                            >
                              <q-item-section avatar>
                                <q-icon color="text-color" name="delete" size="20px" />
                              </q-item-section>
                              <q-item-section>Remove</q-item-section>
                            </q-item>
                            <q-item
                              v-if="service.kind === 'RegistrationNetwork' || service.kind === 'RegistrationCloudente'"
                              clickable
                              v-close-popup
                              @click="openReassignDialog(service)"
                            >
                              <q-item-section avatar>
                                <q-icon name="assignment_return" size="20px" />
                              </q-item-section>
                              <q-item-section
                                >Reassign
                                {{ service.kind === 'RegistrationNetwork' ? 'External DNS' : 'zones' }}</q-item-section
                              >
                            </q-item>
                            <q-item v-else clickable disable v-close-popup>
                              <q-item-section avatar>
                                <q-icon color="text-color" name="delete" size="20px" />
                              </q-item-section>
                              <q-item-section>Offboard</q-item-section>
                            </q-item>
                            <q-item clickable @click="showFeedbackDialog(service)" v-close-popup>
                              <q-item-section avatar>
                                <q-icon color="text-color" name="contact_support" size="20px" />
                              </q-item-section>
                              <q-item-section>Give Feedback</q-item-section>
                            </q-item>
                          </q-list>
                        </q-menu>
                      </q-btn>
                    </div>
                  </template>
                  <div v-if="service.kind !== 'RegistrationDraft'" class="service-data">
                    <div class="row no-wrap">
                      <div class="col col-12">
                        <div v-if="service.infraService.widgetDescription" class="description q-mt-md">
                          <span class="label-block q-mb-sm">Description:</span>
                          <div
                            class="service-description q-mb-none"
                            v-html="dataMarkdown(service.infraService.widgetDescription)"
                          ></div>
                        </div>
                        <component
                          :is="widgetType(service.kind)"
                          :manifest="infoWidgets[service.infraService.id]?.data || {}"
                          :loadManifest="infoWidgets[service.infraService.id]?.load"
                          :actions="widgetActions[service.infraService.id]?.data || []"
                          :loadActions="widgetActions[service.infraService.id]?.load"
                          :accessProducts="widgetAccessProducts[service.infraService.id]?.data || {}"
                          :loadAccessProducts="widgetAccessProducts[service.infraService.id]?.load"
                          :commentActions="widgetComments[service.infraService.id]?.data || []"
                          :loadCommentActions="widgetComments[service.infraService.id]?.load"
                          :infraService="service.infraService"
                        >
                        </component>
                      </div>
                    </div>
                  </div>
                </q-expansion-item>
              </div>
            </template>
          </div>
        </q-scroll-area>
        <div v-if="application" style="padding: 0 16px">
          <q-btn
            v-if="!showServiceList && newServices.length < 1"
            @click="showServiceListPopup"
            style="margin-top: 20px"
            color="primary"
            rounded
            unelevated
            :disable="!application.digitalServiceActive || !hasAccess"
            class="block full-width add-service-btn"
            >Add Service</q-btn
          >
          <div v-show="showServiceList || newServices.length" class="service-list flex">
            <div class="selected-services flex items-center" ref="inputServices">
              <q-menu ref="serviceList" fit max-width="500px" :offset="[0, 15]" @hide="() => (showServiceList = false)">
                <div class="orderable-services q-px-lg q-py-lg">
                  <q-input
                    :model-value="serviceSearch"
                    @update:model-value="(event) => (serviceSearch = event)"
                    dense
                    label="Search"
                    class="q-mb-lg"
                  >
                    <template v-slot:prepend>
                      <q-icon name="search"></q-icon>
                    </template>
                  </q-input>
                  <q-scroll-area
                    style="height: 400px; width: 100%; padding-right: 10px"
                    :thumb-style="{ borderRadius: '5px', background: '#9f9f9f', width: '3px', opacity: 1, right: 0 }"
                  >
                    <div v-for="category in filteredItems" :key="category.id" style="max-width: 100%" class="q-mb-sm">
                      <template v-if="category.infraServices.length">
                        <span>{{ category.categoryName }}</span>
                        <div class="infraservices-holder q-pt-xs">
                          <infra-service
                            :class="{ disable: isDisable(service) }"
                            style="cursor: pointer"
                            @click="addService(service)"
                            v-for="service in category.infraServices"
                            :key="service.id"
                            :data-service="{
                              serviceName: service.infraServiceName,
                              displayName: service.infraServiceDisplayName,
                              cloudType: service.cloudType,
                              type: service.type,
                              state: service.state,
                            }"
                          />
                        </div>
                      </template>
                    </div>
                  </q-scroll-area>
                </div>
              </q-menu>
              <q-scroll-area
                style="height: 70px; width: calc(100% - 30px); padding-right: 5px"
                :thumb-style="{ borderRadius: '5px', background: '#9f9f9f', width: '3px', opacity: 1, right: 0 }"
              >
                <span v-if="!newServices.length" class="q-pl-md block" style="font-size: 18px; color: #979ba4"
                  >Select one service that you need...</span
                >
                <infra-service
                  v-for="service in newServices"
                  :key="service.id"
                  :data-service="{
                    serviceName: service.infraServiceName,
                    displayName: service.infraServiceDisplayName,
                    cloudType: service.cloudType,
                    type: service.type,
                    state: service.state,
                  }"
                >
                  <template v-slot:buttons>
                    <q-btn
                      class="btn--no-hover q-ml-auto"
                      size="10px"
                      icon="remove"
                      flat
                      padding="0"
                      :ripple="false"
                      @click.stop="removeFromList(service)"
                    >
                      <q-tooltip style="z-index: 999999">Remove</q-tooltip>
                    </q-btn>
                  </template>
                </infra-service>
              </q-scroll-area>
              <span v-if="newServices.length" class="block" style="width: 30px">
                <q-icon name="close" size="22px" style="cursor: pointer" @click="newServices = []">
                  <q-tooltip>Clear</q-tooltip>
                </q-icon>
              </span>
            </div>
            <q-btn
              @click="addNewService"
              class="block add-service-btn"
              :loading="sendRequest"
              :disable="!newServices.length || sendRequest"
              style="min-width: 200px"
              rounded
              unelevated
              color="primary"
              >Add</q-btn
            >
          </div>
        </div>
      </div>
    </div>

    <q-dialog v-model="wizardDialog" class="single-service-page" persistent>
      <q-card style="min-width: 1024px; border-radius: 20px">
        <q-toolbar class="shadow-2 q-py-md" style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)">
          <q-toolbar-title class="text-weight-bold">
            <div class="service-name flex items-center">
              <span
                class="icon-service"
                :class="[
                  `${
                    currentService.infraService.cloudType ? currentService.infraService.cloudType.toLowerCase() : 'na'
                  }`,
                  { tool: currentService.type === 'Tool' },
                ]"
              >
                <infra-service-logo
                  v-if="currentService.infraService.icon && currentService.infraService.icon !== 'default'"
                  :logo="currentService.infraService.parentServiceIcon || currentService.infraService.icon"
                />
                <q-icon v-else name="settings" class="default-icon" size="30px"></q-icon>
              </span>
              <strong>{{
                currentService.infraService.parentServiceDisplayName ||
                currentService.infraService.displayName ||
                currentService.infraService.serviceName ||
                currentService.infraService.infraServiceDisplayName ||
                currentService.infraService.infraServiceName
              }}</strong>
            </div>
          </q-toolbar-title>
          <q-btn icon="close" round flat class="btn--no-hover" @click="closeWizard"></q-btn>
        </q-toolbar>
        <q-card-section v-if="loadSchema && loadUiSchema && loadData" class="q-py-lg">
          <q-scroll-area
            :style="{ height: widgetHeight }"
            :thumb-style="{ borderRadius: '5px', background: '#9f9f9f', width: '2px', opacity: 1, right: 0 }"
          >
            <q-resize-observer @resize="onResize"></q-resize-observer>
            <q-form ref="mainForm">
              <q-banner
                v-if="
                  approvalGroupError &&
                  (currentService.kind === 'RegistrationNetwork' ||
                    currentService.infraService.kind === 'RegistrationNetwork' ||
                    currentService.kind === 'RegistrationCloudente' ||
                    currentService.infraService.kind === 'RegistrationCloudente')
                "
                class="text-white approval-error-banner q-mb-sm"
              >
                <div v-html="dataMarkdown(approvalGroupError)"></div>
              </q-banner>
              <json-forms
                :data="formData"
                :renderers="renderers"
                :schema="schema"
                :uischema="uiSchema"
                :manifest="manifest"
                :freezeManifest="freezeManifest"
                :networkPermissions="networkPermissions"
                :cloudentePermissions="cloudentePermissions"
                :approvalGroupState="approvalGroupState"
                :readonly="isRegistrationReadOnly"
                @change="onChange"
              />
            </q-form>
            <div v-if="comments.length" class="q-mx-sm q-px-none" style="border-top: 2px solid var(--q-primary)">
              <q-expansion-item class="feedback-list">
                <template v-slot:header>
                  <span>See comments on users actions</span>
                </template>
                <div
                  v-for="(comment, key) in showingComments"
                  :key="key"
                  class="q-px-md q-mb-md q-pb-md"
                  style="border-bottom: 1px solid #e8e8e8"
                >
                  <div class="flex full-width justify-between content-center q-mb-xs">
                    <span
                      ><span style="text-transform: capitalize">{{ comment.entity }}</span> was
                      {{ comment.action + 'd' }} by <b>{{ comment.createdBy.name }}</b></span
                    >
                    <span>{{ $options.dayjs(comment.createdAt).format('DD.MM.YYYY HH:mm') }}</span>
                  </div>
                  <ul class="q-px-none q-mx-none q-py-none q-my-none">
                    <li class="inline-block" style="list-style-type: none; color: #73e1a5">
                      <b style="text-transform: capitalize">{{ comment.entity }} info:</b>
                    </li>
                    <li
                      class="inline-block q-mx-sm"
                      style="list-style-type: none"
                      v-for="(value, key) in comment.entityDescription"
                      :key="key"
                    >
                      <b>{{ key }}:</b> {{ value }}
                    </li>
                  </ul>
                  <p class="q-mb-none"><b style="color: #73e1a5">Comment:</b> {{ comment.comment }}</p>
                </div>
                <div class="flex flex-center">
                  <q-pagination :max="commentsPages" v-model="commentsPage"></q-pagination>
                </div>
              </q-expansion-item>
            </div>
          </q-scroll-area>
        </q-card-section>
        <q-card-section v-else class="flex text-center items-center justify-center" style="min-height: 200px">
          <q-spinner-ios color="text-color" size="3em" />
        </q-card-section>
        <q-card-actions class="q-px-md q-pb-lg">
          <q-btn
            @click="confirmSendManifest"
            :disable="
              loadSendData || !!formErrors.length || JSON.stringify(manifest) === JSON.stringify(freezeManifest)
            "
            :loading="loadSendData"
            rounded
            color="primary"
            >Save Changes</q-btn
          >
          <q-btn @click="closeWizard" rounded flat>Cancel</q-btn>
        </q-card-actions>
      </q-card>
      <q-dialog v-model="showConfirmPopup" class="single-service-page" persistent>
        <q-card style="min-width: 768px; border-radius: 5px">
          <q-toolbar class="q-pt-sm q-px-md">
            <q-toolbar-title class="text-weight-bold">
              <strong>Please confirm your changes and add a comment for your actions</strong>
            </q-toolbar-title>
          </q-toolbar>
          <q-card-section class="confirm-dialog">
            <div v-if="confirmData.deletedZones?.length" class="confirm-section q-mb-sm">
              <div class="confirm-heading">
                <span class="q-mr-sm"><q-icon name="delete" size="20px" color="white"></q-icon></span>
                <h4>Deleted:</h4>
              </div>
              <div class="q-mb-sm">
                <span class="confirm-label">Zones</span>
                <div v-for="zone in confirmData.deletedZones" :key="zone.id" class="confirm-row">
                  <div class="row no-wrap q-col-gutter-sm" style="width: 100%">
                    <div class="col">
                      <q-input :model-value="zone.name" dense label="Zone name" borderless disable></q-input>
                    </div>
                    <div class="col">
                      <q-input :model-value="zone.license" dense label="License" borderless disable></q-input>
                    </div>
                    <div class="col">
                      <q-checkbox :model-value="zone.DNS" label="DNS" disable></q-checkbox>
                    </div>
                    <div class="col col-4">
                      <q-input
                        :rules="[(val) => val.length < 500 || 'Max length is 500 symbols']"
                        bg-color="white"
                        label="Add comment"
                        type="text"
                        v-model="zone.comment"
                        dense
                        outlined
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="confirmData.newZones?.length" class="confirm-section q-mb-sm">
              <div class="confirm-heading">
                <span class="q-mr-sm"><q-icon name="add" size="20px" color="white"></q-icon></span>
                <h4>Added:</h4>
              </div>
              <div class="q-mb-sm">
                <span class="confirm-label">Zones</span>
              </div>
              <div class="warning-text">
                <p>
                  Your change requires the creation of a change request, which will begin to be created after clicking
                  the Confirm button. You must receive approval for the process to proceed. Please wait...
                </p>
              </div>
              <div v-for="zone in confirmData.newZones" :key="zone.id" class="confirm-row">
                <div class="row no-wrap q-col-gutter-sm" style="width: 100%">
                  <div class="col">
                    <q-input :model-value="zone.name" dense label="Zone name" borderless disable></q-input>
                  </div>
                  <div class="col">
                    <q-input :model-value="zone.license" dense label="License" borderless disable></q-input>
                  </div>
                  <div class="col">
                    <q-checkbox :model-value="zone.DNS" label="DNS" disable></q-checkbox>
                  </div>
                  <div class="col col-4">
                    <q-input
                      :rules="[(val) => val.length < 500 || 'Max length is 500 symbols']"
                      bg-color="white"
                      label="Add comment"
                      type="text"
                      v-model="zone.comment"
                      dense
                      outlined
                    />
                  </div>
                </div>
              </div>
            </div>

            <div v-if="confirmData.newNetworkEnvs?.length" class="confirm-section q-mb-sm">
              <div class="confirm-heading">
                <span class="q-mr-sm"><q-icon name="add" size="20px" color="white"></q-icon></span>
                <h4>Added:</h4>
              </div>
              <div class="q-mb-sm">
                <span class="confirm-label">Environments</span>
              </div>
              <div class="row no-wrap q-col-gutter-sm" style="width: 100%">
                <div v-for="env in confirmData.newNetworkEnvs" :key="env.id" class="col">
                  <q-checkbox :model-value="true" :label="env.id" disable></q-checkbox>
                </div>
              </div>
            </div>

            <div v-if="confirmData.newVnets?.length" class="confirm-section q-mb-sm">
              <div class="confirm-heading">
                <span class="q-mr-sm"><q-icon name="add" size="20px" color="white"></q-icon></span>
                <h4>Added:</h4>
              </div>
              <div v-for="env in confirmData.newVnets" :key="env.id" class="q-mb-md">
                <div class="q-mb-sm">
                  <span class="confirm-label">VNETS for {{ env.id }} environment</span>
                </div>
                <div v-for="vnet in env.vnets" :key="vnet.id" class="confirm-row">
                  <div class="row no-wrap q-col-gutter-sm" style="width: 100%">
                    <div class="col">
                      <q-input :model-value="vnet.name" dense label="Name" borderless disable></q-input>
                    </div>
                    <div class="col">
                      <q-input :model-value="vnet.region" dense label="Region" borderless disable></q-input>
                    </div>
                    <div class="col">
                      <q-input
                        :model-value="vnet.resourceGroup"
                        dense
                        label="Resource Group"
                        borderless
                        disable
                      ></q-input>
                    </div>
                    <div class="col">
                      <q-input :model-value="vnet.size" dense label="Size" borderless disable></q-input>
                    </div>
                    <div class="col col-4">
                      <q-input
                        :rules="[(val) => val.length < 500 || 'Max length is 500 symbols']"
                        bg-color="white"
                        label="Add comment"
                        type="text"
                        v-model="vnet.comment"
                        dense
                        outlined
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="confirmData.deletedNetworkZones?.length" class="confirm-section q-mb-sm">
              <div class="confirm-heading">
                <span class="q-mr-sm"><q-icon name="delete" size="20px" color="white"></q-icon></span>
                <h4>Deleted:</h4>
              </div>
              <div class="q-mb-sm">
                <span class="confirm-label">DNS Records</span>
                <div v-for="zone in confirmData.deletedNetworkZones" :key="zone.id" class="confirm-row">
                  <div class="row no-wrap q-col-gutter-sm" style="width: 100%">
                    <div class="col">
                      <q-input :model-value="zone.name" dense label="Name" borderless disable></q-input>
                    </div>
                    <div class="col">
                      <q-input :model-value="zone.type" dense label="Type" borderless disable></q-input>
                    </div>
                    <div class="col">
                      <q-input :model-value="zone.domain" dense label="Domain" borderless disable></q-input>
                    </div>
                    <div class="col">
                      <q-input :model-value="zone.value" dense label="Value" borderless disable></q-input>
                    </div>
                    <div class="col col-4">
                      <q-input
                        :rules="[(val) => val.length < 500 || 'Max length is 500 symbols']"
                        bg-color="white"
                        label="Add comment"
                        type="text"
                        v-model="zone.comment"
                        dense
                        outlined
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="confirmData.newDNSZones?.length" class="confirm-section q-mb-sm">
              <div class="confirm-heading">
                <span class="q-mr-sm"><q-icon name="add" size="20px" color="white"></q-icon></span>
                <h4>Added:</h4>
              </div>
              <div class="q-mb-sm">
                <span class="confirm-label">DNS Records</span>
                <div class="warning-text">
                  <p>
                    Your change requires the creation of a change request, which will begin to be created after clicking
                    the Confirm button. You must receive approval for the process to proceed. Please wait...
                  </p>
                </div>
                <div v-for="zone in confirmData.newDNSZones" :key="zone.id" class="confirm-row">
                  <div class="row no-wrap q-col-gutter-sm" style="width: 100%">
                    <div class="col">
                      <q-input :model-value="zone.name" dense label="Name" borderless disable></q-input>
                    </div>
                    <div class="col">
                      <q-input :model-value="zone.type" dense label="Type" borderless disable></q-input>
                    </div>
                    <div class="col">
                      <q-input :model-value="zone.domain" dense label="Domain" borderless disable></q-input>
                    </div>
                    <div class="col">
                      <q-input :model-value="zone.value" dense label="Value" borderless disable></q-input>
                    </div>
                    <div class="col col-4">
                      <q-input
                        :rules="[(val) => val.length < 500 || 'Max length is 500 symbols']"
                        bg-color="white"
                        label="Add comment"
                        type="text"
                        v-model="zone.comment"
                        dense
                        outlined
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="confirmData.updatedDNSZones?.length" class="confirm-section q-mb-sm">
              <div class="confirm-heading">
                <span class="q-mr-sm"><q-icon name="cached" size="20px" color="white"></q-icon></span>
                <h4>Updated:</h4>
              </div>
              <div class="q-mb-sm">
                <span class="confirm-label">DNS Records</span>
              </div>
              <div v-for="zone in confirmData.updatedDNSZones" :key="zone.id" class="confirm-row">
                <div class="row no-wrap q-col-gutter-sm" style="width: 100%">
                  <div class="col">
                    <p style="margin: 12px 0">
                      For <b> {{ zone.domain }} </b> zone from <b> {{ zone.oldValue }} </b> to
                      <b> {{ zone.newValue }} </b>
                    </p>
                  </div>
                  <div class="col col-4">
                    <q-input
                      :rules="[(val) => val.length < 500 || 'Max length is 500 symbols']"
                      bg-color="white"
                      label="Add comment"
                      type="text"
                      v-model="zone.comment"
                      dense
                      outlined
                    />
                  </div>
                </div>
              </div>
            </div>
          </q-card-section>
          <q-card-actions class="q-px-md q-pb-lg">
            <q-btn @click="sendManifest" rounded color="primary">Confirm</q-btn>
            <q-btn @click="resetConfirm" rounded flat>Cancel</q-btn>
          </q-card-actions>
        </q-card>
      </q-dialog>
    </q-dialog>

    <q-dialog v-model="showConfirmDialog" class="single-service-page" persistent>
      <q-card style="min-width: 840px; border-radius: 0px">
        <q-toolbar class="shadow-2 q-py-sm q-px-md">
          <q-toolbar-title class="text-weight-bold">
            <strong>The Access Rights you are ordering (the right will be valid for one year)</strong>
          </q-toolbar-title>
          <q-btn
            icon="close"
            style="min-width: 20px"
            round
            flat
            class="btn--no-hover"
            @click="showConfirmDialog = false"
          ></q-btn>
        </q-toolbar>
        <q-card-section class="q-mx-md q-px-none q-pb-none" style="border-top: 2px solid var(--q-primary)">
          <ul class="q-px-none q-mx-none order-list">
            <li v-for="role of rolesToOrder" :key="role">{{ role }}</li>
          </ul>
        </q-card-section>
        <q-card-actions class="q-px-md q-pt-none q-pb-lg">
          <q-btn
            style="border-radius: 10px; text-transform: capitalize"
            color="primary"
            text-color="text-color"
            @click="orderMassAccesses"
            >Order</q-btn
          >
          <q-btn
            style="border-radius: 10px; text-transform: capitalize"
            class="btn--no-hover"
            flat
            @click="showConfirmDialog = false"
            >Close</q-btn
          >
        </q-card-actions>
      </q-card>
    </q-dialog>

    <reassign-external-dns-dialog
      :reassignObject="reassignObject"
      :show-reassign-dialog="showReassignDialog"
      :loading="reassignLoading"
      @close="closeReassignDialog"
      @resetService="reassignObject.newDigitalService = undefined"
      @setService="setNewDigitalService($event)"
      @reassignZone="reassignZone($event)"
      @discardReassign="discardReassign($event)"
      @submit="sendReassignedZones($event)"
    />

    <feedback-dialog
      @sendFeedback="createFeedback($event)"
      :feedbacks="currentServiceFeedbacks"
      :loading="feedbackLoad"
      :show="feedbackDialog"
      :service-name="feedbackService?.displayName || feedbackService?.serviceName"
      @close="(feedbackDialog = false), (currentServiceFeedbacks = [])"
    ></feedback-dialog>
  </q-page>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { pick } from 'lodash';
import { marked } from 'marked';
import { mapActions, mapGetters } from 'vuex';
import { JsonForms } from '@jsonforms/vue';
import { scroll } from 'quasar';
import apollo from '@/library/http/apollo';
import gql from 'graphql-tag';
import dayjs from 'dayjs';
import appInsights from '@/library/applicationInsights';
import * as uiConfig from '@/shared/ui-config.json';
import { APP_ACTIONS, APP_GETTERS } from '@/store/modules/app';
import { quasarRenderers } from '@/shared/jsonformsQuasarRenderers/renderers';
import InfraServiceLogo from '@/shared/components/InfraServiceLogo';
import ServiceTable from './components/ServiceTable.vue';
import InfraService from '@/pages/digitalServices/components/InfraService.vue';
import CloudenteWidget from './components/CloudenteWidget.vue';
import DefaultWidget from './components/DefaultWidget.vue';
import DbaasWidget from './components/DbaasWidget.vue';
import NetworkWidget from './components/NetworkWidget.vue';
import MasWidget from './components/MasWidget.vue';
import CertifiedProductWidget from './components/CertifiedProductWidget.vue';
import { BANNERS_GETTERS } from '@/store/modules/banners';
import CostChart from '@/pages/digitalService/components/CostChart';
import { getSchema, getUserBatchPermissions } from './api';
import FeedbackDialog from '@/shared/components/FeedbackDialog';
import OpassWidget from './components/OpassWidget';
import AlibabaWidget from './components/AlibabaWidget';
import ReassignExternalDnsDialog from '@/pages/digitalService/components/ReassignExternalDnsDialog';

const { getScrollTarget, setVerticalScrollPosition } = scroll;
const renderers = [...quasarRenderers];

export default {
  name: 'DigitalService',
  marked,
  components: {
    ReassignExternalDnsDialog,
    FeedbackDialog,
    CostChart,
    InfraServiceLogo,
    ServiceTable,
    JsonForms,
    InfraService,
    CloudenteWidget,
    DefaultWidget,
    DbaasWidget,
    NetworkWidget,
    MasWidget,
    CertifiedProductWidget,
    OpassWidget,
    AlibabaWidget,
  },
  dayjs,
  data() {
    return {
      allAdrs: [],
      currentPage: 1,
      totalAdrs: 0,
      reassignLoading: false,
      showReassignDialog: false,
      reassignObject: {
        currentDigitalService: undefined,
        platform: undefined,
        entity: undefined,
        newDigitalService: undefined,
        reassignedZones: [],
        externalDnsZones: [],
      },
      acccesProductsLoaindg: false,
      newRolesCount: 0,
      newRolesSet: new Set(),
      rolesToOrder: [],
      showConfirmDialog: false,
      selectedAccessProducts: [],
      existingRolesSet: [],
      approvalGroupState: undefined,
      approvalGroupError: undefined,
      widgetHeight: '100px',
      feedbackLoad: false,
      feedbackDialog: false,
      feedbackService: undefined,
      currentServiceFeedbacks: [],
      infoTab: 'costData',
      sendRequest: false,
      application: undefined,
      servicesList: [],
      allInfraservices: [],
      serviceSearch: '',
      wizardDialog: false,
      renderers: Object.freeze(renderers),
      formData: {},
      schema: undefined,
      uiSchema: undefined,
      showServiceList: false,
      newServices: [],
      manifest: undefined,
      freezeManifest: undefined,
      loadSchema: false,
      loadUiSchema: false,
      loadData: false,
      currentService: {},
      action: undefined,
      formErrors: [],
      loadSendData: false,
      uiConfig: {},
      serviceCategories: [],
      infoWidgets: {},
      widgetActions: {},
      widgetComments: {},
      widgetAccessProducts: {},
      costDates: [],
      costDate: '',
      costData: undefined,
      loadCostData: false,
      showAccessProducts: false,
      adrs: [],
      showConfirmPopup: false,
      confirmData: {},
      comments: [],
      commentsPage: 1,
      showCommentsCount: 5,
      vnetColumns: [
        {
          name: 'name',
          label: 'Name',
          field: 'name',
          align: 'left',
        },
        {
          name: 'region',
          label: 'Region',
          field: 'region',
          align: 'left',
        },
        {
          name: 'resourceGroup',
          label: 'Resource Group',
          field: 'resourceGroup',
          align: 'left',
        },
        {
          name: 'size',
          label: 'Size',
          field: 'size',
          align: 'left',
        },
        {
          name: 'comment',
          label: 'Comment',
          field: 'comment',
          align: 'left',
        },
      ],
      dnsZonesColumns: [
        {
          name: 'domain',
          label: 'Domain',
          field: 'domain',
          align: 'left',
        },
        {
          name: 'type',
          label: 'Type',
          field: 'type',
          align: 'left',
        },
        {
          name: 'name',
          label: 'Name',
          field: 'name',
          align: 'left',
        },
        {
          name: 'value',
          label: 'Value',
          field: 'value',
          align: 'left',
        },
        {
          name: 'comment',
          label: 'Comment',
          field: 'comment',
          align: 'left',
        },
      ],
      zonesColumns: [
        {
          name: 'name',
          label: 'Name',
          field: 'name',
          align: 'left',
        },
        {
          name: 'license',
          label: 'License',
          field: 'license',
          align: 'left',
        },
        {
          name: 'DNS',
          label: 'DNS',
          field: 'DNS',
          align: 'left',
        },
        {
          name: 'objStatus',
          label: 'Status',
          field: 'objStatus',
          align: 'left',
        },
        {
          name: 'comment',
          label: 'Comment',
          field: 'comment',
          align: 'left',
        },
      ],
      networkPermissions: {
        vnetsEnvs: [],
        externalDns: {
          create: false,
          change: false,
          delete: false,
        },
      },
      cloudentePermissions: {
        zonesCreate: false,
        zonesDelete: false,
      },
    };
  },
  methods: {
    ...mapActions('app', {
      getUserInfo: APP_ACTIONS.GET_USER_INFO,
    }),
    async sendReassignedZones(data) {
      this.reassignLoading = true;
      await apollo.apolloClient
        .mutate({
          mutation: gql`
            mutation reassignItemsToApplication(
              $currentDigitalService: String!
              $entity: String!
              $ids: [String!]!
              $newDigitalService: String!
              $platform: String!
            ) {
              reassignItemsToApplication(
                currentDigitalService: $currentDigitalService
                entity: $entity
                ids: $ids
                newDigitalService: $newDigitalService
                platform: $platform
              )
            }
          `,
          variables: {
            currentDigitalService: data.currentDigitalService,
            entity: data.entity,
            ids: data.ids,
            newDigitalService: data.newDigitalService,
            platform: data.platform,
          },
        })
        .then(async (response) => {
          const itemsResponse = response.data.reassignItemsToApplication;
          const ids = Object.keys(itemsResponse);
          const reassignedItems = [...this.reassignObject.externalDnsZones, ...this.reassignObject.reassignedZones]
            .filter((item) => ids.includes(item.id) && itemsResponse[item.id].lastAction)
            .map((i) => i.name);
          const notReassignedItems = [...this.reassignObject.externalDnsZones, ...this.reassignObject.reassignedZones]
            .filter((item) => ids.includes(item.id) && itemsResponse[item.id].error)
            .map((i) => i.name);
          reassignedItems.forEach((item) => {
            this.$notify(
              'positive',
              'Success',
              `${
                this.reassignObject.entity === 'external-dns' ? 'External DSN record' : 'Zone'
              } ${item} was reassigned success.`,
            );
          });
          notReassignedItems.forEach((item) => {
            this.$notify(
              'negative',
              'Error',
              `Error during reassignment ${
                this.reassignObject.entity === 'external-dns' ? 'external DSN record' : 'zone'
              } ${item}.`,
            );
          });
          this.closeReassignDialog();
          await this.load();
        })
        .catch((error) => {
          this.$notify(
            'negative',
            'Error',
            `Reassignment ${this.reassignObject.entity === 'external-dns' ? 'external DSN records' : 'zones'}
          from ${data.currentDigitalService} to ${data.newDigitalService} was failed. ${error.message}`,
          );
        })
        .finally(() => {
          this.reassignLoading = false;
        });
    },
    closeReassignDialog() {
      this.showReassignDialog = false;
      this.reassignObject.currentDigitalService = undefined;
      this.reassignObject.platform = undefined;
      this.reassignObject.entity = undefined;
      this.reassignObject.newDigitalService = undefined;
      this.reassignObject.reassignedZones = [];
      this.reassignObject.externalDnsZones = [];
    },
    setNewDigitalService(service) {
      this.reassignObject.newDigitalService = service.apmId;
    },
    reassignZone(zone) {
      const index = this.reassignObject.externalDnsZones.findIndex((i) => i.id === zone.id);
      this.reassignObject.externalDnsZones.splice(index, 1);
      this.reassignObject.reassignedZones.unshift(zone);
    },
    discardReassign(zone) {
      const index = this.reassignObject.reassignedZones.findIndex((i) => i.id === zone.id);
      this.reassignObject.reassignedZones.splice(index, 1);
      this.reassignObject.externalDnsZones.splice(index, 0, zone);
    },
    async openReassignDialog(service) {
      let canBeReassign = false;
      try {
        const platformId = service.infraService.apmid || service.infraService.infraServiceAPMID;
        const moveAuth = {
          userEmail: this.userInfo.email,
          resources: [
            {
              scope: {
                applicationId: this.apmId,
                platformId: platformId,
              },
              resource: {
                type: 'operations',
                name: 'items.move',
              },
            },
          ],
        };
        const response = await getUserBatchPermissions(moveAuth);
        canBeReassign = response[platformId]['items.move'];
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
      }
      if (canBeReassign) {
        apollo.apolloClient
          .query({
            query: gql`
            query {
              getRegistrationByServicesId(digitalService: "${this.apmId}", infraService: "${service.infraService.apmid}") {
                manifest
              }
            }
          `,
          })
          .then((response) => {
            this.reassignObject.currentDigitalService = this.apmId;
            this.reassignObject.platform = service.infraService.apmid;
            if (service.infraService.apmid === 'NETWORK') {
              this.reassignObject.entity = 'external-dns';
              this.reassignObject.externalDnsZones =
                response?.data?.getRegistrationByServicesId.manifest?.spec?.externalDNSZones
                  .filter((zone) => zone.objStatus === 'completed')
                  .map((zone) => {
                    const { id, domain, name, type, value, objStatus } = zone;
                    return { id, domain, name, type, value, status: objStatus };
                  });
            } else {
              this.reassignObject.entity = 'zones';
              this.reassignObject.externalDnsZones = response?.data?.getRegistrationByServicesId.manifest?.spec?.zones
                .filter((zone) => zone.objStatus === 'completed')
                .map((zone) => {
                  const { id, name, license, DNS, objStatus } = zone;
                  return { id, name, license, DNS, status: objStatus };
                });
            }
            this.showReassignDialog = true;
          })
          .catch((error) => {
            this.$notify('negative', 'Error', error.message);
          });
      } else {
        this.$notify('negative', 'Error', 'You dont have permissions for this action');
      }
    },
    orderAccessProduct(role) {
      window.open(
        `https://swissreesm.service-now.com/contactone?id=request_access_rights&accessRightParam=${role}`,
        '_blank',
      );
    },
    openConfirmOrderDialog() {
      this.showConfirmDialog = true;
      this.rolesToOrder = this.selectedAccessProducts.filter((r) => !this.existingRolesSet.includes(r));
    },
    itemSelected(roleName) {
      // Ensure the checkbox is set/unset
      if (this.newRolesSet.has(roleName)) {
        this.newRolesCount--;
        this.newRolesSet.delete(roleName);
      } else {
        this.newRolesSet.add(roleName);
        this.newRolesCount++;
      }
    },
    async orderMassAccesses() {
      this.$showLoading();
      this.showConfirmDialog = false;

      await apollo.apolloClient
        .mutate({
          mutation: gql`
          mutation {
            createMassRequestOrder(accessRoles: [ ${this.rolesToOrder.map((role) => `"${role}"`)}])   
          }
          `,
        })
        .then(() => {
          this.showAccessProducts = false;
          this.selectedAccessProducts = [];
          this.$notify('positive', 'Success', 'Access products ordered successfully');
        })
        .catch((e) => {
          this.$notify('negative', 'Error', e.message);
        })
        .finally(async () => {
          await this.getExistAccessProduct();
          this.$hideLoading();
        });
    },
    onResize(size) {
      if (size.height > 600) {
        this.widgetHeight = `600px`;
      } else {
        this.widgetHeight = `${size.height}px`;
      }
    },
    getWidgetInfo(service, reload = false) {
      if (service.kind === 'RegistrationDraft') return false;
      if (!this.infoWidgets[service.infraService.id] || reload) {
        this.infoWidgets[service.infraService.id] = {};
        this.infoWidgets[service.infraService.id].load = true;
        apollo.apolloClient
          .query({
            query: gql`
          query {
            getRegistrationByServicesId(digitalService: "${this.apmId}", infraService: "${service.infraService.apmid}") {
              manifest
            }
          }
        `,
          })
          .then((response) => {
            const schema = response.data.getRegistrationByServicesId.manifest;
            this.infoWidgets[service.infraService.id].data = schema && schema.spec ? schema.spec : {};
          })
          .finally(() => {
            this.infoWidgets[service.infraService.id].load = false;
          });
        this.widgetAccessProducts[service.infraService.id] = {};
        this.widgetAccessProducts[service.infraService.id].load = true;
        apollo.apolloClient
          .query({
            query: gql`
          query {
            getRegistrationAccessProducts(digitalService: "${this.apmId}", infraService: "${service.infraService.apmid}")
          }
        `,
          })
          .then((response) => {
            this.widgetAccessProducts[service.infraService.id].data = response.data.getRegistrationAccessProducts;
          })
          .finally(() => {
            this.widgetAccessProducts[service.infraService.id].load = false;
          });
        this.widgetComments[service.infraService.id] = {};
        this.widgetComments[service.infraService.id].load = true;
        apollo.apolloClient
          .query({
            query: gql`
            query {
              getComments(digitalServiceId: "${this.apmId}", infraServiceId: "${service.infraService.apmid}") {
                action
                comment
                entity
                entityDescription
                createdBy {
                  name
                }
                createdAt
              }
            }
          `,
          })
          .then((res) => {
            this.widgetComments[service.infraService.id].data = res.data.getComments;
          })
          .finally(() => {
            this.widgetComments[service.infraService.id].load = false;
          });
        this.widgetActions[service.infraService.id] = {};
        this.widgetActions[service.infraService.id].load = true;
        apollo.apolloClient
          .query({
            query: gql`
          query {
            getRegistrationActions(digitalService: "${this.application.apmId}", infraService: "${service.infraService.apmid}") {
              id
              actionName
              environment
              objStatus
              createdBy
              updated
              changeAction
            }
          }
        `,
          })
          .then((response) => {
            this.widgetActions[service.infraService.id].data = response.data.getRegistrationActions;
          })
          .finally(() => {
            this.widgetActions[service.infraService.id].load = false;
          });
      }
    },
    widgetType(kind) {
      switch (kind) {
        case 'RegistrationCloudente':
          return 'CloudenteWidget';
        case 'RegistrationDBaaS':
          return 'DbaasWidget';
        case 'RegistrationNetwork':
          return 'NetworkWidget';
        case 'RegistrationMAS':
          return 'MasWidget';
        case 'RegistrationCertifiedProduct':
          return 'CertifiedProductWidget';
        case 'RegistrationOPASS':
          return 'OpassWidget';
        case 'RegistrationAlibaba':
          return 'AlibabaWidget';
        default:
          return 'DefaultWidget';
      }
    },
    resetConfirm() {
      this.showConfirmPopup = false;
      this.confirmData = {};
    },
    confirmSendManifest() {
      this.$refs.mainForm.validate().then(async (valid) => {
        if (valid) {
          const kind =
            this.currentService.kind === 'RegistrationDraft'
              ? this.currentService.infraService.kind
              : this.currentService.kind;
          if (kind === 'RegistrationCloudente') {
            const deletedZones = [];
            const newZones = [];
            this.manifest?.zones?.forEach((zone) => {
              if (!zone.objStatus) {
                const newZone = {
                  ...zone,
                  comment: '',
                };
                newZones.push(newZone);
              }
            });
            this.freezeManifest?.zones?.forEach((existZone) => {
              if (!this.manifest?.zones.find((zone) => existZone.name === zone.name)) {
                existZone.comment = '';
                deletedZones.push(existZone);
              }
            });
            this.confirmData.newZones = newZones;
            this.confirmData.deletedZones = deletedZones;
            this.showConfirmPopup = true;
          } else if (kind === 'RegistrationNetwork') {
            const newEnvs = [];
            const newVnets = [];
            this.manifest.environments.forEach((env) => {
              if (!this.freezeManifest?.environments?.find((e) => e.id === env.id)) {
                newEnvs.push(env);
              }
              if (
                this.freezeManifest?.environments?.find((e) => e.id === env.id && env.vnets.length > e.vnets.length)
              ) {
                const newObj = {};
                newObj.id = env.id;
                newObj.vnets = this.manifest.environments
                  .find((e) => e.id === env.id)
                  .vnets.filter((vnet) => !vnet.objStatus)
                  .map((vnet) => {
                    return {
                      ...vnet,
                      comment: '',
                    };
                  });
                newVnets.push(newObj);
              }
            });
            this.confirmData.newNetworkEnvs = newEnvs;
            this.confirmData.newVnets = newVnets;
            this.confirmData.newDNSZones = this.manifest?.externalDNSZones
              ?.filter((zone) => !zone.objStatus)
              .map((zone) => {
                return {
                  ...zone,
                  comment: '',
                };
              });
            const updatedZones = [];
            this.freezeManifest?.externalDNSZones?.forEach((zone) => {
              if (this.manifest?.externalDNSZones?.find((z) => z.id === zone.id && z.value !== zone.value)) {
                const updatedZone = {};
                updatedZone.type = zone.type;
                updatedZone.name = zone.name;
                updatedZone.domain = zone.domain;
                updatedZone.oldValue = zone.value;
                updatedZone.newValue = this.manifest.externalDNSZones.find((i) => i.id === zone.id).value;
                updatedZone.comment = '';
                updatedZones.push(updatedZone);
              }
            });
            this.confirmData.updatedDNSZones = updatedZones;
            const deletedZones = [];
            this.freezeManifest?.externalDNSZones?.forEach((existZone) => {
              if (!this.manifest.externalDNSZones?.find((zone) => existZone.id === zone.id)) {
                existZone.comment = '';
                deletedZones.push(existZone);
              }
            });
            this.confirmData.deletedNetworkZones = deletedZones;
            this.showConfirmPopup = true;
          } else if (kind === 'RegistrationAlibaba') {
            this.manifest.environments.forEach((env) => {
              delete env.emails;
              delete env.environment;
            });
            this.showConfirmPopup = true;
          } else {
            this.sendManifest();
          }
        }
      });
    },
    sendManifest() {
      this.showConfirmPopup = false;
      this.$refs.mainForm.validate().then(async (valid) => {
        if (valid) {
          this.loadSendData = true;
          const payload = {};
          const manifest = {
            metadata: {},
          };
          const manifestsList = {
            manifestCloudente: null,
            manifestDbaaS: null,
            manifestNetwork: null,
            manifestThreeEnv: null,
            manifestTwoEnv: null,
            manifestMas: null,
            manifestAlibaba: null,
          };
          payload.digitalServiceId = this.application.id;
          payload.infraServiceId =
            this.currentService.infraService.infraServiceId || this.currentService.infraService.id;
          payload.action = this.action;
          manifest.version = 'v1';
          manifest.kind =
            this.currentService.kind === 'RegistrationDraft'
              ? this.currentService.infraService.kind
              : this.currentService.kind;
          manifest.metadata.application = this.application.apmId;
          manifest.metadata.platform =
            this.currentService.infraService.apmid || this.currentService.infraService.infraServiceAPMID;
          if (manifest.kind === 'RegistrationCloudente') {
            const fixZones = this.manifest.zones.map((zone) => {
              const { name, license, DNS, id, startDate: changeStartDate, endDate: changeEndDate } = zone;
              return {
                id,
                name,
                license,
                DNS,
                changeStartDate,
                changeEndDate,
              };
            });
            this.manifest.zones = fixZones;
          }

          if (manifest.kind === 'RegistrationOPASS') {
            this.manifest.environments.forEach((env) => {
              delete env.emails.lastActionId;
              delete env.emails.lastActionName;
              delete env.emails.lastActionState;
            });
          }

          if (manifest.kind === 'RegistrationNetwork') {
            if (this.manifest.environments?.length) {
              this.manifest.environments = this.manifest.environments.map((env) => {
                return {
                  id: env.id,
                  vnets: env.vnets?.length
                    ? env.vnets.map((vnet) => {
                        const { startDate: changeStartDate, endDate: changeEndDate } = vnet;

                        return {
                          ...pick(vnet, ['region', 'resourceGroup', 'name', 'size', 'id']),
                          changeStartDate,
                          changeEndDate,
                        };
                      })
                    : [],
                };
              });
            }

            if (this.manifest.externalDNSZones?.length) {
              const fixZones = this.manifest.externalDNSZones.map((zone) => {
                const { id, domain, name, type, value, startDate, endDate } = zone;
                return { id, domain, name, type, value, changeStartDate: startDate, changeEndDate: endDate };
              });
              this.manifest.externalDNSZones = fixZones;
            }
          }
          manifest.spec = this.manifest;
          switch (manifest.kind) {
            case 'Registration1Env':
              manifestsList.manifestOneEnv = manifest;
              break;
            case 'Registration2Env':
              manifestsList.manifestTwoEnv = manifest;
              break;
            case 'Registration3Env':
              manifestsList.manifestThreeEnv = manifest;
              break;
            case 'RegistrationDBaaS':
              manifestsList.manifestDbaaS = manifest;
              break;
            case 'RegistrationCloudente':
              manifestsList.manifestCloudente = manifest;
              break;
            case 'RegistrationMAS':
              manifestsList.manifestMas = manifest;
              break;
            case 'RegistrationNetwork':
              manifestsList.manifestNetwork = manifest;
              break;
            case 'RegistrationOPASS':
              manifestsList.manifestOPASS = manifest;
              break;
            case 'RegistrationAlibaba':
              manifestsList.manifestAlibaba = manifest;
              break;
          }
          const deletedZonesComments =
            this.confirmData?.deletedZones?.map((zone) => {
              const { comment, license, name, DNS } = zone;
              return {
                comment,
                entity: 'zone',
                action: 'delete',
                entityDescription: {
                  license,
                  name,
                  DNS,
                },
              };
            }) || [];
          const newZonesComments =
            this.confirmData?.newZones?.map((zone) => {
              const { comment, license, name, DNS } = zone;
              return {
                comment,
                entity: 'zone',
                action: 'create',
                entityDescription: {
                  license,
                  name,
                  DNS,
                },
              };
            }) || [];
          const newVnetsComments = [].concat(
            ...(this.confirmData?.newVnets?.map((env) => {
              return env.vnets?.map((vnet) => {
                const { comment, ...rest } = vnet;
                return {
                  comment,
                  entity: 'vnet',
                  action: 'create',
                  entityDescription: {
                    ...rest,
                    env: env.id,
                  },
                };
              });
            }) || []),
          );
          const deletedNetworkZonesComments =
            this.confirmData?.deletedNetworkZones?.map((dns) => {
              const { comment, domain, name, type, value } = dns;
              return {
                comment,
                entity: 'dnsRecord',
                action: 'delete',
                entityDescription: {
                  domain,
                  name,
                  type,
                  value,
                },
              };
            }) || [];
          const newNetworkZonesComments =
            this.confirmData?.newDNSZones?.map((dns) => {
              const { comment, domain, name, type, value } = dns;
              return {
                comment,
                entity: 'dnsRecord',
                action: 'create',
                entityDescription: {
                  domain,
                  name,
                  type,
                  value,
                },
              };
            }) || [];
          const updatedNetworkZonesComments =
            this.confirmData?.updatedDNSZones?.map((dns) => {
              const { comment, domain, name, type, newValue, oldValue } = dns;
              return {
                comment,
                entity: 'dnsRecord',
                action: 'update',
                entityDescription: {
                  domain,
                  name,
                  type,
                  value: newValue,
                  oldValue,
                },
              };
            }) || [];
          const comments =
            [
              ...deletedZonesComments,
              ...newZonesComments,
              ...newVnetsComments,
              ...deletedNetworkZonesComments,
              ...newNetworkZonesComments,
              ...updatedNetworkZonesComments,
            ].filter((c) => c?.comment?.length) || [];
          await apollo.apolloClient
            .mutate({
              mutation: gql`
                mutation registrateUpdateDigitalServiceInfraService(
                  $action: String!
                  $digitalServiceId: String!
                  $infraServiceId: String!
                  $manifestCloudente: WriteFrontendCloudenteRegistrationPayloadDTO = null
                  $manifestDbaaS: WriteFrontendDBaasRegistrationPayloadDTO = null
                  $manifestNetwork: WriteFrontendNetworkRegistrationPayloadDTO = null
                  $manifestThreeEnv: WriteFrontendThreeEnvironmentsRegistrationPayloadDTO = null
                  $manifestTwoEnv: WriteFrontendTwoEnvironmentsRegistrationPayloadDTO = null
                  $manifestOneEnv: WriteFrontendOneEnvironmentRegistrationPayloadDTO = null
                  $manifestMAS: WriteFrontendMASRegistrationPayloadDTO = null
                  $manifestOPASS: WriteFrontendOPASSRegistrationPayloadDTO
                  $manifestAlibaba: WriteFrontendAlibabaRegistrationPayloadDTO = null
                ) {
                  registrateUpdateDigitalServiceInfraService(
                    action: $action
                    digitalServiceId: $digitalServiceId
                    infraServiceId: $infraServiceId
                    manifestNetwork: $manifestNetwork
                    manifestCloudente: $manifestCloudente
                    manifestDbaaS: $manifestDbaaS
                    manifestThreeEnv: $manifestThreeEnv
                    manifestTwoEnv: $manifestTwoEnv
                    manifestOneEnv: $manifestOneEnv
                    manifestMAS: $manifestMAS
                    manifestOPASS: $manifestOPASS
                    manifestAlibaba: $manifestAlibaba
                  )
                }
              `,
              variables: {
                action: payload.action,
                digitalServiceId: payload.digitalServiceId,
                infraServiceId: payload.infraServiceId,
                manifestCloudente: manifestsList.manifestCloudente ? manifestsList.manifestCloudente : null,
                manifestDbaaS: manifestsList.manifestDbaaS ? manifestsList.manifestDbaaS : null,
                manifestNetwork: manifestsList.manifestNetwork ? manifestsList.manifestNetwork : null,
                manifestThreeEnv: manifestsList.manifestThreeEnv ? manifestsList.manifestThreeEnv : null,
                manifestTwoEnv: manifestsList.manifestTwoEnv ? manifestsList.manifestTwoEnv : null,
                manifestOneEnv: manifestsList.manifestOneEnv ? manifestsList.manifestOneEnv : null,
                manifestMAS: manifestsList.manifestMas ? manifestsList.manifestMas : null,
                manifestOPASS: manifestsList.manifestOPASS || null,
                manifestAlibaba: manifestsList.manifestAlibaba || null,
              },
            })
            .then(async () => {
              this.$notify(
                'positive',
                'Success',
                payload.action === 'create'
                  ? `Application ${this.application.apmId} registered on service ${
                      this.currentService.infraService.apmid || this.currentService.infraService.infraServiceAPMID
                    } successfully.`
                  : `Application ${this.application.apmId} updated on service ${
                      this.currentService.infraService.apmid || this.currentService.infraService.infraServiceAPMID
                    } successfully`,
              );
              if (comments.length) {
                await apollo.apolloClient
                  .mutate({
                    mutation: gql`
                      mutation addCommentAction(
                        $infraServiceId: String
                        $digitalServiceId: String
                        $comments: [FrontendCommentActionInputDTO!]!
                      ) {
                        addCommentAction(
                          comments: $comments
                          infraServiceId: $infraServiceId
                          digitalServiceId: $digitalServiceId
                        ) {
                          status
                          value
                        }
                      }
                    `,
                    variables: {
                      comments: comments,
                      infraServiceId:
                        this.currentService.infraService.apmid || this.currentService.infraService.infraServiceAPMID,
                      digitalServiceId: this.application.apmId,
                    },
                  })
                  .then((res) => {
                    const messages = res.data.addCommentAction.map((i) => i.value).join('. ');
                    this.$notify('positive', 'Success', messages);
                  })
                  .catch((error) => {
                    this.$notify('negative', 'Error', error.message);
                  });
              }
            })
            .catch((error) => {
              this.$notify(
                'negative',
                'Error',
                `${payload.action === 'create' ? 'Create' : 'Update'} ${this.currentService.infraService.displayName}
                registration for ${this.application.apmId} application was failed. ${error.message}`,
              );
            })
            .finally(() => {
              this.loadSendData = false;
              this.load();
              this.getWidgetInfo(this.currentService, true);
              this.closeWizard();
              this.resetConfirm();
            });
        }
      });
    },
    onChange(value) {
      this.manifest = value.data;
      this.formErrors = value.errors;
    },
    closeWizard() {
      this.wizardDialog = false;
      this.uiSchema = undefined;
      this.schema = undefined;
      this.formData = {};
      this.currentService = {};
      this.loadSchema = false;
      this.loadUiSchema = false;
      this.loadData = false;
      this.action = undefined;
      this.newServices = [];
      this.showServiceList = false;
      this.widgetHeight = '100px';
    },
    async showWizard(service, action) {
      const platformId = service.infraService.apmid || service.infraService.infraServiceAPMID;
      const apmId = this.apmId;
      try {
        const editAuth = {
          userEmail: this.userInfo.email,
          resources: [
            {
              scope: {
                applicationId: apmId,
                platformId: platformId,
              },
              resource: {
                type: 'operations',
                name: 'registration.edit',
              },
            },
          ],
        };
        const response = await getUserBatchPermissions(editAuth);
        if (process.env.VUE_APP_ENV === 'PROD' ? response[platformId]['registration.edit'] : true) {
          this.$showLoading();
          if (service.kind === 'RegistrationCloudente' || service.infraService.kind === 'RegistrationCloudente') {
            const authData = {
              userEmail: this.userInfo.email,
              resources: [
                {
                  scope: {
                    applicationId: apmId,
                    platformId: platformId,
                    environmentId: 'PROD',
                  },
                  resource: {
                    type: 'actions',
                    name: 'zones.create',
                  },
                },
                {
                  scope: {
                    applicationId: apmId,
                    platformId: platformId,
                    environmentId: 'PROD',
                  },
                  resource: {
                    type: 'actions',
                    name: 'zones.delete',
                  },
                },
              ],
            };
            try {
              const { CLOUDENTE: cloudentePermissions } = await getUserBatchPermissions(authData);
              this.cloudentePermissions.zonesCreate = cloudentePermissions['zones.create']['PROD'];
              this.cloudentePermissions.zonesDelete = cloudentePermissions['zones.delete']['PROD'];
            } catch (e) {
              this.$notify('negative', 'Error', e.message);
            }
          }
          if (service.kind === 'RegistrationNetwork' || service.infraService.kind === 'RegistrationNetwork') {
            const authData = {
              userEmail: this.userInfo.email,
              resources: [
                {
                  scope: {
                    applicationId: apmId,
                    platformId: platformId,
                    environmentId: 'PROD',
                  },
                  resource: {
                    type: 'actions',
                    name: 'external-dns.create',
                  },
                },
                {
                  scope: {
                    applicationId: apmId,
                    platformId: platformId,
                    environmentId: 'PROD',
                  },
                  resource: {
                    type: 'actions',
                    name: 'external-dns.change',
                  },
                },
                {
                  scope: {
                    applicationId: apmId,
                    platformId: platformId,
                    environmentId: 'PROD',
                  },
                  resource: {
                    type: 'actions',
                    name: 'external-dns.delete',
                  },
                },
                {
                  scope: {
                    applicationId: apmId,
                    platformId: platformId,
                    environmentId: 'PROD',
                  },
                  resource: {
                    type: 'actions',
                    name: 'vnets.create',
                  },
                },
                {
                  scope: {
                    applicationId: apmId,
                    platformId: platformId,
                    environmentId: 'DEV',
                  },
                  resource: {
                    type: 'actions',
                    name: 'vnets.create',
                  },
                },
                {
                  scope: {
                    applicationId: apmId,
                    platformId: platformId,
                    environmentId: 'NONPROD',
                  },
                  resource: {
                    type: 'actions',
                    name: 'vnets.create',
                  },
                },
              ],
            };
            try {
              const { NETWORK: networkPermissions } = await getUserBatchPermissions(authData);
              this.networkPermissions.vnetsEnvs = Object.keys(networkPermissions['vnets.create'])
                .map((i) => {
                  if (networkPermissions['vnets.create'][i]) return i;
                })
                .filter((i) => i);

              this.networkPermissions.externalDns.create = networkPermissions['external-dns.create']['PROD'];
              this.networkPermissions.externalDns.change = networkPermissions['external-dns.change']['PROD'];
              this.networkPermissions.externalDns.delete = networkPermissions['external-dns.delete']['PROD'];
            } catch (e) {
              this.$notify('negative', 'Error', e.message);
            }
          }
          if (this.approvalGroupState === 'APPROVAL_GROUP_INVALID') {
            this.networkPermissions.vnetsEnvs = [];
            this.networkPermissions.externalDns.create = false;
            this.networkPermissions.externalDns.change = false;
            this.networkPermissions.externalDns.delete = false;
            this.cloudentePermissions.zonesCreate = false;
            this.cloudentePermissions.zonesDelete = false;
          }
          if (this.application.status === 'To Be Decommissioned') {
            this.networkPermissions.vnetsEnvs = [];
            this.networkPermissions.externalDns.create = false;
            this.networkPermissions.externalDns.change = false;
            this.networkPermissions.externalDns.delete = true;
            this.cloudentePermissions.zonesCreate = false;
            this.cloudentePermissions.zonesDelete = true;
          }
          appInsights.trackEvent({ name: 'EditWidget' }, service);
          this.currentService = service;
          this.action = action;
          if (action === 'update') {
            apollo.apolloClient
              .query({
                query: gql`
            query {
              getRegistrationByServicesId(digitalService: "${this.apmId}", infraService: "${service.infraService.apmid}") {
                manifest
              }
            }
          `,
              })
              .then((response) => {
                const schema = response.data.getRegistrationByServicesId.manifest;
                const manifestSpec = schema && schema.spec ? schema.spec : {};
                this.formData = manifestSpec;
                this.manifest = manifestSpec;
                this.freezeManifest = manifestSpec ? cloneDeep(manifestSpec) : {};
              })
              .catch((error) => {
                this.$notify('negative', 'Error', error.message);
              })
              .finally(() => {
                this.loadData = true;
              });
          } else {
            this.loadData = true;
          }
          try {
            const response = await getSchema(
              `${service.kind === 'RegistrationDraft' ? service.infraService.kind : service.kind}.json`,
            );
            const schema = JSON.parse(JSON.stringify(response.properties.spec));
            if (service.kind === 'RegistrationOPASS' || service.infraService.kind === 'RegistrationOPASS') {
              delete schema.properties.environments.items.properties.emails.properties.lastAction;
              delete schema.properties.environments.items.properties.emails.properties.objStatus;
            }
            this.schema = schema;
          } catch (error) {
            this.$notify('negative', 'Error', error.message);
          } finally {
            this.loadSchema = true;
          }
          try {
            const uiSchema = await getSchema(
              `ui-schemas-${process.env.VUE_APP_ENV === 'PROD' ? 'prod' : 'dev'}/${
                service.kind === 'RegistrationDraft' ? service.infraService.kind : service.kind
              }.json`,
            );
            this.uiSchema = uiSchema;
          } catch (error) {
            this.$notify('negative', 'Error', error.message);
          } finally {
            this.loadUiSchema = true;
          }
          apollo.apolloClient
            .query({
              query: gql`
            query {
              getComments(digitalServiceId: "${this.apmId}", infraServiceId: "${service.infraService.apmid}") {
                action
                comment
                entity
                entityDescription
                createdBy {
                  name
                }
                createdAt
              }
            }
          `,
            })
            .then((res) => {
              this.comments = res.data.getComments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            });
          this.wizardDialog = true;
          this.$hideLoading();
        } else {
          this.$notify('negative', 'Error', 'You does not have permissions for editing this registration');
        }
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
      }
    },
    scrollToItem(service) {
      const anchor = service.infraService.apmid;
      const widgetRef = service.infraService.id;
      const element = this.$refs[anchor][0] || this.$refs[anchor];
      const target = getScrollTarget(element);
      const offset = element.offsetTop - 10;
      const duration = 1000;
      setVerticalScrollPosition(target, offset, duration);
      const widget = this.$refs[widgetRef][0];
      widget.toggle();
      this.$router.replace({ query: { infraservice: anchor } });
    },
    serviceStatus(status) {
      const statuses = {
        inprogress: 'In progress',
        processing: 'Processing',
        completed: 'Completed',
        pending: 'Pending',
        failed: 'Failed',
        reverted: 'Processing error, reverted...',
      };
      return statuses[status.toLowerCase()] || 'Pending';
    },
    async getApplicationData() {
      await apollo.apolloClient
        .query({
          query: gql`
          query {
            getApplicationByAPMID(digitalServiceAPMID: "${this.apmId}") {
              id
              apmId
              majorIncidents
              assignmentGroup
              businessOwnerEmail
              businessOwnerId
              businessOwnerName
              createdAt
              createdByEmail
              createdById
              createdByName
              digitalServiceName
              status
              digitalServiceActive
              itCustodian
              itCustodianEmail
              itCustodianId
              itCustodianName
              itOwner
              itOwnerEmail
              itOwnerId
              businessOwnerCustodianId
              businessOwnerCustodianName
              businessOwnerCustodianEmail
              itOwnerName
              release
              url
              registrations {
                registrationId
                digitalServiceId
                status
                kind
                infraService {
                  id
                  categoryName
                  categoryId
                  categoryOrder
                  apmid
                  displayName
                  serviceName
                  serviceId
                  cloudType
                  type
                  icon
                  widgetDescription
                  kind
                  links{
                    id
                    type
                    label
                    url
                  }
                }
              }
            }
          }
        `,
        })
        .then((response) => {
          this.application = response?.data.getApplicationByAPMID;
          this.servicesList = this.application?.registrations
            ? this.application.registrations.map((registration) => {
                return {
                  infraService: registration.infraService,
                  kind: registration.kind,
                  status: registration.status,
                };
              })
            : [];
          if (this.servicesList.length) {
            const categories = [];
            this.application.registrations.forEach((registration) => {
              if (!categories.map((item) => item.name).includes(registration.infraService.categoryName)) {
                categories.push({
                  name: registration.infraService.categoryName,
                  order: registration.infraService.categoryOrder,
                });
              }
            });
            const serviceCategories = categories.map((category) => ({
              name: category.name,
              order: category.order,
              infraServices: [],
            }));
            this.servicesList.forEach((item) => {
              const cat = serviceCategories.find((category) => category.name === item.infraService.categoryName);
              cat.infraServices.push(item);
            });
            this.serviceCategories = serviceCategories.sort((a, b) => a.order - b.order);
            this.servicesList.forEach((registration) => {
              // Group links to display in separate columns. TODO: refactor after DBtable is normalized;
              registration.infraService.links = registration.infraService.links.reduce((r, link) => {
                const linkTypes = link.type.split(',').map((t) => t.toLowerCase().trim());
                linkTypes.forEach((linkType) => {
                  if (linkType !== 'details') {
                    // Details should not be displayed
                    r[linkType] = r[linkType] || [];
                    r[linkType].push(link);
                  }
                });
                return r;
              }, {});
            });
          }
        });
      if (this.servicesList.length) {
        await apollo.apolloClient
          .query({
            query: gql`
            query getADRs(
              $apmId: String
              $servicesArr: [String!]
          ) {
              getADRs(filter: {filterBy: {services: $servicesArr, apmId: $apmId }, includeFields: {services: true}}) {
                count
                adrs {
                  adrId
                  category
                  id
                  created_at
                  hasDigitalServiceAdherence(digitalServiceAPMId: "${this.application.apmId}")
                  name
                  scope
                  status
                  validDate
                  version
                  requirements
                  content
                }
              }
            }
          `,
            variables: {
              apmId: this.apmId,
              servicesArr: this.servicesList?.length ? this.servicesList.map((s) => s.infraService.serviceId) : [],
            },
          })
          .then((response) => {
            this.allAdrs = JSON.parse(JSON.stringify(response.data.getADRs.adrs));
            this.totalAdrs = response.data.getADRs.count;
            this.adrs = response.data.getADRs.adrs.splice(0, 6);
          });
      }
    },
    async loadAdrs() {
      await apollo.apolloClient
        .query({
          query: gql`
            query getADRs(
            $servicesArr: [String!]
            $apmId: String
          ) {
              getADRs(filter: {filterBy: {services: $servicesArr, apmId: $apmId }, includeFields: {services: true}}, limit: "${6}", skip: "${
            (this.currentPage - 1) * 6
          }") {
                count
                adrs {
                  adrId
                  category
                  id
                  created_at
                  hasDigitalServiceAdherence(digitalServiceAPMId: "${this.application.apmId}")
                  name
                  scope
                  status
                  validDate
                  version
                  requirements
                  content
                }
              }
            }
          `,
          variables: {
            apmId: this.apmId,
            servicesArr: this.servicesList?.length ? this.servicesList.map((s) => s.infraService.serviceId) : [],
          },
        })
        .then((response) => {
          this.adrs = response.data.getADRs.adrs;
        });
    },
    async getOrderableInfraservices() {
      const apms = this.servicesList.map((item) => item.infraService.apmid).filter((item) => item && item.length);
      await apollo.apolloClient
        .query({
          query: gql`
            query orderabelInfraServicesByCategories($apmsarr: [String!]) {
              orderabelInfraServicesByCategories(apms: $apmsarr) {
                categoryName
                infraServices {
                  cloudType
                  id
                  infraServiceAPMID
                  infraServiceDisplayName
                  infraServiceName
                  kind
                  state
                  type
                  parentServiceDisplayName
                  icon
                  parentServiceIcon
                }
              }
            }
          `,
          variables: {
            apmsarr: apms,
          },
        })
        .then((response) => {
          this.allInfraservices = response.data.orderabelInfraServicesByCategories;
        });
    },
    showServiceListPopup() {
      this.showServiceList = true;
      this.$refs.inputServices.click();
    },
    isDisable(service) {
      return this.newServices.some((item) => item.infraServiceAPMID === service.infraServiceAPMID);
    },
    addService(service) {
      if (
        !this.newServices.some((item) => item.infraServiceAPMID === service.infraServiceAPMID) &&
        !this.servicesList.some((item) => item.infraServiceAPMID === service.infraServiceAPMID)
      ) {
        this.newServices.unshift(service);
      }
    },
    async createDraftRegistrations() {
      this.sendRequest = true;
      const payload = {};
      payload.digitalServiceId = this.application.id;
      payload.infraServiceId = 'DraftRegistrations';
      payload.action = 'create';
      const manifests = this.newServices.map((service) => ({
        version: 'v1',
        kind: 'RegistrationDraft',
        metadata: {
          application: this.application.apmId,
          platform: service.infraServiceAPMID,
        },
        spec: null,
      }));
      await apollo.apolloClient
        .mutate({
          mutation: gql`
            mutation registrateUpdateDigitalServiceInfraService(
              $action: String!
              $digitalServiceId: String!
              $infraServiceId: String!
              $manifestCloudente: WriteFrontendCloudenteRegistrationPayloadDTO = null
              $manifestDbaaS: WriteFrontendDBaasRegistrationPayloadDTO = null
              $manifestNetwork: WriteFrontendNetworkRegistrationPayloadDTO = null
              $manifestThreeEnv: WriteFrontendThreeEnvironmentsRegistrationPayloadDTO = null
              $manifestTwoEnv: WriteFrontendTwoEnvironmentsRegistrationPayloadDTO = null
              $manifestOneEnv: WriteFrontendOneEnvironmentRegistrationPayloadDTO = null
              $manifestMAS: WriteFrontendMASRegistrationPayloadDTO = null
              $manifestDraft: [WriteFrontendRegistrationDraftPayloadDTO!] = null
            ) {
              registrateUpdateDigitalServiceInfraService(
                action: $action
                digitalServiceId: $digitalServiceId
                infraServiceId: $infraServiceId
                manifestNetwork: $manifestNetwork
                manifestCloudente: $manifestCloudente
                manifestDbaaS: $manifestDbaaS
                manifestThreeEnv: $manifestThreeEnv
                manifestTwoEnv: $manifestTwoEnv
                manifestOneEnv: $manifestOneEnv
                manifestMAS: $manifestMAS
                manifestDraft: $manifestDraft
              )
            }
          `,
          variables: {
            action: payload.action,
            digitalServiceId: payload.digitalServiceId,
            infraServiceId: payload.infraServiceId,
            manifestCloudente: null,
            manifestDbaaS: null,
            manifestNetwork: null,
            manifestThreeEnv: null,
            manifestTwoEnv: null,
            manifestOneEnv: null,
            manifestMAS: null,
            manifestDraft: manifests,
          },
        })
        .then(() => {
          this.$notify(
            'positive',
            'Success',
            'Services have been successfully added. Please complete their registration.',
          );
        })
        .catch((error) => {
          this.$notify('negative', 'Error', error.message);
        })
        .finally(() => {
          this.sendRequest = false;
          this.newServices = [];
          this.load();
        });
    },
    addNewService() {
      if (this.newServices.length > 1) {
        this.createDraftRegistrations();
      } else {
        const service = {};
        service.kind = this.newServices[0].kind;
        service.infraService = this.newServices[0];
        this.showWizard(service, 'create');
      }
    },
    async load() {
      this.$showLoading();
      try {
        await this.getApplicationData();
        await this.getOrderableInfraservices();
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
      } finally {
        this.$hideLoading();
      }
    },
    isEditDisable(apmid) {
      return this.uiConfig.disableEditServices.includes(apmid);
    },
    dataMarkdown(data) {
      return marked(data);
    },
    async removeRegistration(service) {
      this.$showLoading();
      await apollo.apolloClient
        .query({
          query: gql`mutation { removeRegistrationById(registrationId: "${service.registrationId}")}`,
        })
        .then(() => {
          this.$notify(
            'positive',
            'Success',
            `Registration for ${
              service.infraService.displayName || service.infraService.serviceName
            } was removed successfully.`,
          );
        })
        .catch((error) => {
          this.$notify('negative', 'Error', error.message);
        })
        .finally(() => {
          this.load();
        });
    },
    removeFromList(service) {
      const index = this.newServices.findIndex((item) => item.id === service.id);
      this.newServices.splice(index, 1);
    },
    async setCostDate(date) {
      this.loadCostData = true;
      this.costDate = date;
      const dates = date.split(' ');
      apollo.apolloClient
        .query({
          query: gql`
          query {
            digitalServiceDetailedCost(apmId: "${this.apmId}", from: "${dates[0]}", to: "${dates[1]}") {
              detailed
            }
          }`,
        })
        .then((response) => {
          const costData = response.data.digitalServiceDetailedCost.detailed;
          const keys = Object.keys(costData).sort();
          const sortedItems = {};
          keys.forEach((key) => {
            sortedItems[key] = costData[key];
          });
          this.costData = sortedItems;
        })
        .finally(() => {
          this.loadCostData = false;
        });
    },
    async getAllAccessProducts() {
      if (this.showAccessProducts) {
        this.showAccessProducts = false;
        return false;
      }
      if (
        this.serviceCategories.find((category) =>
          category.infraServices.find((service) => service.accessProducts?.length),
        )
      ) {
        this.showAccessProducts = !this.showAccessProducts;
        return false;
      }
      const apmids = [];
      this.serviceCategories.forEach((category) => {
        category.infraServices.forEach((item) => {
          if (item.status !== 'PENDING') apmids.push({ apmid: item.infraService.apmid, id: item.infraService.id });
        });
      });
      let count = 0;
      if (apmids.length) {
        this.acccesProductsLoaindg = true;
        const promises = apmids.map((item) => {
          apollo.apolloClient
            .query({
              query: gql`
              query {
                getRegistrationAccessProducts(digitalService: "${this.apmId}", infraService: "${item.apmid}" )
              }
            `,
            })
            .then((response) => {
              const currentCat = this.serviceCategories.find((cat) =>
                cat.infraServices.find((i) => i.infraService.apmid === item.apmid),
              );
              const currentService = currentCat.infraServices.find((i) => i.infraService.apmid === item.apmid);
              const roles = Object.values(response.data.getRegistrationAccessProducts)
                .flat(1)
                .map((accessProduct) => ({
                  name: accessProduct.role.displayName,
                }));
              currentService.accessProducts = roles;
            })
            .finally(() => {
              count += 1;
              if (count === apmids.length) {
                this.showAccessProducts = true;
                this.acccesProductsLoaindg = false;
              }
            });
        });
        await Promise.all(promises);
        await this.getExistAccessProduct();
      }
    },
    async getExistAccessProduct() {
      apollo.apolloClient
        .query({
          query: gql`
            query {
              accessRequestStatuses
            }
          `,
        })
        .then((resp) => {
          const {
            data: { accessRequestStatuses },
          } = resp;
          this.existingRolesSet = accessRequestStatuses.map((i) => i.u_access_product);
          this.selectedAccessProducts = [...this.existingRolesSet];
        });
    },
    goToAdr(id) {
      this.$router.push(`/adrs/${id}`);
    },
    async showFeedbackDialog(service) {
      await apollo.apolloClient
        .query({
          query: gql`
          query {
            feedbacks(infraServiceId: "${service.infraService.apmid}", digitalServiceId: "${this.apmId}") {
              id
              title
              description
              isLike
              stars
              createdAt
              createdBy {
                name
              }
            }
          }
        `,
        })
        .then((res) => {
          this.currentServiceFeedbacks = res.data.feedbacks;
        });
      this.feedbackService = service.infraService;
      this.feedbackDialog = true;
    },
    async createFeedback(feedback) {
      this.feedbackLoad = true;
      await apollo.apolloClient
        .mutate({
          mutation: gql`
            mutation createFeedback(
              $infraServiceId: String
              $digitalServiceId: String
              $feedback: FrontendFeedbackInput!
            ) {
              createFeedback(feedback: $feedback, infraServiceId: $infraServiceId, digitalServiceId: $digitalServiceId)
            }
          `,
          variables: {
            feedback: feedback,
            infraServiceId: this.feedbackService.apmid,
            digitalServiceId: this.apmId,
          },
        })
        .then((res) => {
          if (res.data.createFeedback) {
            this.$notify(
              'positive',
              'Success',
              `Feedback for ${
                this.feedbackService?.displayName || this.feedbackService?.serviceName
              } was sent successfully.`,
            );
          }
        })
        .catch((error) => {
          this.$notify('negative', 'Error', error.message);
        })
        .finally(() => {
          this.feedbackLoad = false;
          this.feedbackDialog = false;
          this.feedbackService = undefined;
        });
    },
  },
  computed: {
    ...mapGetters('app', {
      userInfo: APP_GETTERS.USER_INFO,
    }),
    ...mapGetters('banners', {
      currentBanner: BANNERS_GETTERS.BANNERS_SHOW,
    }),
    commentsPages() {
      return Math.ceil(this.comments.length / this.showCommentsCount);
    },
    showingComments() {
      return JSON.parse(JSON.stringify(this.comments)).splice(
        (this.commentsPage - 1) * this.showCommentsCount,
        this.showCommentsCount,
      );
    },
    apmId() {
      return this.$route.params.id.toUpperCase();
    },
    hasAccess() {
      return process.env.VUE_APP_ENV === 'PROD'
        ? this.application.itOwnerEmail === this.userInfo.email ||
            this.application.businessOwnerEmail === this.userInfo.email ||
            this.application.itCustodianEmail === this.userInfo.email ||
            this.application.businessOwnerCustodianEmail === this.userInfo.email
        : true;
    },
    filteredItems() {
      return this.allInfraservices.map((element) => {
        return {
          ...element,
          infraServices: element.infraServices.filter(
            (subElement) =>
              !this.uiConfig.hideServices.includes(subElement.infraServiceAPMID) &&
              subElement.infraServiceDisplayName.toLowerCase().includes(this.serviceSearch.toLowerCase()),
          ),
        };
      });
    },
    isRegistrationReadOnly() {
      // Could be the place to add more conditions for readonly mode;
      const editableStatuses = ['DRAFT', 'COMPLETED', 'FAILED'];
      const { kind, status } = this.currentService;

      if (kind === 'RegistrationMAS' && !editableStatuses.includes(status)) {
        return true;
      }

      return false;
    },
  },
  async mounted() {
    apollo.apolloClient
      .query({
        query: gql`
        query {
          getSnowApplicationApprovalGroupState(digitalServiceAPMId: "${this.apmId}") {
            error
            state
          }
        }
      `,
      })
      .then((response) => {
        this.approvalGroupState = response.data.getSnowApplicationApprovalGroupState.state;
        this.approvalGroupError = response.data.getSnowApplicationApprovalGroupState.error;
      });
    const currentMonth = dayjs().month() + 1;
    const month = currentMonth === 0 ? 12 : currentMonth;
    const year = dayjs().year();
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    this.costDates = [
      {
        label: `${months[0]} ${year} - ${months[month - 1]} ${year}`,
        value: `${year}-01 ${year}-${month}`,
      },
      {
        label: `${months[0]} ${year - 1} - ${months[11]} ${year - 1}`,
        value: `${year - 1}-01 ${year - 1}-12`,
      },
      {
        label: `${months[0]} ${year - 2} - ${months[11]} ${year - 2}`,
        value: `${year - 2}-01 ${year - 2}-12`,
      },
    ];
    await this.setCostDate(this.costDates[0].value);
    this.uiConfig = uiConfig[process.env.VUE_APP_ENV];
    await this.getUserInfo();
    await this.load();
    if (this.$route.query.infraservice) {
      const cat = this.serviceCategories.find((category) =>
        category.infraServices.find((item) => item.infraService.apmid === this.$route.query.infraservice),
      );
      const service = cat.infraServices.find((item) => item.infraService.apmid === this.$route.query.infraservice);
      this.scrollToItem(service);
    }
  },
};
</script>

<style lang="scss">
.single-service-page {
  ul.zones,
  ul.services-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .info-holder {
    border-radius: 15px;
  }

  .info-table {
    width: 100%;

    th {
      vertical-align: text-top;
    }
  }

  .services-category {
    display: block;
    text-transform: uppercase;
    margin-bottom: 5px;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 1px;
    color: #9a9ba0;
  }

  .services-list {
    li {
      font-size: 16px;
      margin-bottom: 5px;
      cursor: pointer;

      strong {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 15px;
        width: calc(100% - 20px);
        display: block;
      }
    }

    .point {
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 10px;
      background-color: $grey-6;

      &.private {
        background-color: #428ce1;
      }

      &.public {
        background-color: #73e1a5;
      }

      &.onprem {
        background-color: #a88cd1;
      }

      &.na {
        background-color: #b3bb7e;
      }

      &.tool {
        background-color: #bfb9a7;
      }
    }
  }

  .block-holder {
    margin-bottom: 10px;
    padding: 20px;
    background-color: white;
    border-radius: 15px;

    .service-header {
      display: flex;
      align-items: center;
      width: 100%;

      .q-item__section i {
        color: $text-color;
      }

      strong {
        font-size: 20px;
      }
    }
  }

  .icon-service {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 2px solid;
    margin-right: 10px;

    &.private {
      border-color: #428ce1;
      color: #428ce1;
    }

    &.public {
      border-color: #73e1a5;
      color: #73e1a5;
    }

    &.onprem {
      border-color: #a88cd1;
      color: #a88cd1;
    }

    &.na {
      border-color: #b3bb7e;
      color: #b3bb7e;
    }

    &.tool {
      border-color: #bfb9a7;
      color: #bfb9a7;
    }
  }

  .service-actions {
    .q-item__section--avatar {
      min-width: 35px;
      padding-right: 5px;
    }
  }

  .label-block {
    display: block;
    font-weight: bold;
    font-size: 15px;
  }

  .status {
    display: flex;
    align-items: center;

    span {
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: 5px;
      background-color: $grey-6;

      &.inprogress {
        background-color: $orange;
      }

      &.processing {
        background-color: goldenrod;
      }

      &.pending {
        background-color: $grey-6;
      }

      &.completed,
      &.abandoned {
        background-color: $green;
      }

      &.failed {
        background-color: $red;
      }

      &.reverted {
        background-color: #d00fa0;
      }
    }
  }

  .actions {
    ul {
      column-count: 3;
    }

    i {
      transform: rotate(90deg);
      margin-right: 3px;
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #2196f3;
    }
  }

  .environments {
    ul {
      display: flex;
      margin: 0 -10px;
      padding: 0;
    }

    li {
      margin: 0 10px;

      i {
        margin-right: 5px;
      }

      display: flex;
      align-items: center;
    }
  }

  .info-block {
    li {
      font-weight: 300;

      span {
        font-weight: 400;
        text-transform: capitalize;
        margin-right: 5px;
      }
    }
  }

  .nav-holder {
    border-radius: 25px;
    padding: 10px 20px;
    background-color: #e7e9f1;

    .q-scrollarea__content {
      max-width: 100%;
    }
  }

  .info-table {
    border-collapse: collapse;

    th,
    td {
      border-spacing: 0;
      padding: 10px 0;
    }

    &.bordered {
      th,
      td {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }
    }
  }

  .service-list {
    margin-top: 20px;
  }

  .add-service-btn {
    text-transform: capitalize;
    padding: 20px 0;
    font-size: 24px;
  }

  .selected-services {
    width: calc(100% - 210px);
    margin-right: 10px;
    height: 80px;
    border: 1px solid #7e8286;
    border-radius: 10px;
    background-color: white;
    padding: 6px;

    .q-scrollarea__content.absolute {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }

  .orderable-services {
    span {
      display: block;
      font-size: 10px;
      text-transform: uppercase;
      font-weight: bold;
      color: #979ba4;
    }

    .infraservices-holder {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: 100%;
    }

    .infra-service {
      display: flex;
      cursor: pointer;
    }
  }
}
.widget-panels {
  .q-panel {
    overflow: hidden !important;
  }
}
.widget-tabs {
  .q-tab {
    min-height: 38px;
  }
  .q-tab__label {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
  }
}
.sub-tabs {
  .q-tab {
    min-height: 30px;
  }
  .q-tab__label {
    text-transform: none;
    letter-spacing: normal;
  }
  .q-tab__indicator {
    height: 1px;
  }
}
.table-actions {
  &.table-info {
    border-bottom: none;
  }
  div:first-child {
    border-top-right-radius: 0px;
  }
  .q-table__top {
    position: absolute;
    bottom: 10px;
    left: 0;
    padding: 0;
  }
  .q-table thead {
    background-color: #edeff5;
    th {
      border-bottom-width: 0 !important;
    }
  }
}
.actions-filter-holder {
  background-color: #edeff5;
  padding: 10px 5px 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.digital-service-page {
  .service-description {
    max-width: 100%;
  }
}
.infra-service {
  &.disable {
    background-color: #d6d9dc !important;
    cursor: not-allowed;
  }
}

.links {
  display: flex;
  span {
    display: block;
    text-transform: capitalize;
  }
  ul {
    flex-direction: column;
    margin: 0 !important;
    padding: 0;
  }
  li {
    margin: 0 !important;
    list-style-type: none;
  }
  a {
    color: var(--q-info);
  }
}
.cost-select .q-field__native.items-center span {
  max-width: 100%;
  overflow: visible;
}
.access-product .q-item {
  justify-content: space-between;
  padding: 0 10px;
  min-height: 20px;
  font-size: 12px;
  margin-bottom: 3px;

  a {
    color: #3c4150;

    &:visited {
      color: #979ba4;
    }
  }
}
.confirm-dialog {
  span.block {
    font-weight: 500;
    color: #878788;

    b {
      color: #1d1d1d;
    }
  }
}

.adr {
  position: relative;
  height: 150px;
  border: 1px solid $grey-5;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;

  .adr-mark {
    position: absolute;
    right: 15px;
    top: -8px;
  }

  .scope {
    display: block;
    white-space: nowrap;
    max-width: calc(100% - 5px);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .adr-title {
    display: block;
    font-size: 17px;
    font-weight: bold;
    white-space: nowrap;
    max-width: calc(100% - 5px);
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
  }

  .adr-content {
    height: 52px;
    font-size: 12px;
    margin-bottom: 5px;
    overflow: hidden;
  }

  .metadata {
    white-space: nowrap;
    max-width: calc(100% - 5px);
    overflow: hidden;
    font-weight: bold;
    font-size: 12px;

    span {
      margin-right: 5px;
    }
  }
}
.order-list {
  column-count: 2;
  list-style-type: none;
}
.warning-text {
  padding-left: 10px;
  margin: 5px 0;
  border-left: 5px solid $blue-5;
  p {
    margin: 0;
  }
}

.adr-holder {
  transition: all 0.5s;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
    content: '';
    opacity: 0;
    z-index: -1;
    border-radius: 10px;
    background-color: white;
  }
  &:hover,
  &:focus {
    transform: scale3d(1.006, 1.006, 1);

    .adr {
      border: none;
    }

    &::after {
      opacity: 1;
    }
  }
}
.indicator {
  display: flex;
  position: absolute;
  height: 5px;
  width: 100%;
  top: 0px;
}
.adherence {
  display: flex;
  align-items: center;

  span {
    display: block;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    border-radius: 50%;
  }
}
</style>
