<template>
    <span class="label-block q-mt-md" v-if="Object.values(infraService.links).length">Links:</span>
    <div class="links" v-if="Object.values(infraService.links).length">
      <ul
        v-for="(group, key) in Object.keys(infraService.links)"
        :key="key"
        :style="`width:${100 / Object.values(infraService.links).length}%`"
      >
        <span>{{ group }}</span>
        <li v-for="link in infraService.links[group]" :key="link.id">
          <a :href="link.url" target="_blank">{{ link.label }}</a>
        </li>
      </ul>
    </div>
    <div v-if="loadManifest" class="flex items-center justify-center q-py-lg">
      <q-spinner-gears size="50px" color="primary"></q-spinner-gears>
    </div>
    <div v-else-if="manifest.environments && manifest.environments.length" class="environments q-pt-sm q-mb-lg">
      <AlibabaRecordsTable :manifest='this.manifest'></AlibabaRecordsTable>
    </div>
    <div v-else class="flex items-center justify-center q-py-md q-mb-lg">
      <strong>No data</strong>
    </div>
    <q-expansion-item class="actions-item">
      <template v-slot:header>
        <strong>List of actions</strong>
      </template>
      <div v-if="loadActions" class="flex items-center justify-center q-py-lg" style="padding: 30px 0">
        <q-spinner-gears size="40px" color="primary"></q-spinner-gears>
      </div>
      <div class="actions-table-holder q-mt-md" v-else-if="actions.length">
        <actions-table @showActions="actionsDialog = true" :actions="actions" />
      </div>
      <div v-else class="flex full-width column items-center q-py-lg" style="padding: 30px 0">
        <q-icon name="hide_source" class="q-mb-sm" size="40px" color="primary"></q-icon>
        <span class="block" style="font-size: 16px">No data</span>
      </div>
    </q-expansion-item>
    <q-expansion-item class="access-products-item">
      <template v-slot:header>
        <strong>Access products</strong>
      </template>
      <div v-if="loadAccessProducts" class="flex items-center justify-center q-py-lg" style="padding: 30px 0">
        <q-spinner-gears size="40px" color="primary"></q-spinner-gears>
      </div>
      <div class="actions-table-holder q-mt-md" v-else-if="Object.keys(accessProducts).length">
        <access-products-table :accessProducts="accessProducts"></access-products-table>
      </div>
      <div v-else class="flex full-width column items-center q-py-lg" style="padding: 30px 0">
        <q-icon name="hide_source" class="q-mb-sm" size="40px" color="primary"></q-icon>
        <span class="block" style="font-size: 16px">No data</span>
      </div>
    </q-expansion-item>
  
    <q-dialog v-model="actionsDialog" class="single-service-page" persistent>
      <q-card style="min-width: 768px; border-radius: 0px">
        <q-toolbar class="shadow-2 q-py-sm q-px-md">
          <q-toolbar-title class="text-weight-bold">
            <strong>Actions for default component {{ infraService.displayName || infraService.serviceName }}</strong>
          </q-toolbar-title>
          <q-btn icon="close" round flat class="btn--no-hover" @click="actionsDialog = false"></q-btn>
        </q-toolbar>
        <q-card-section class="q-pt-none">
          <actions-table showAll :actions="actions" />
        </q-card-section>
      </q-card>
    </q-dialog>
  </template>
  
  <script>
  import ActionsTable from '@/pages/digitalService/components/ActionsTable';
  import AccessProductsTable from '@/pages/digitalService/components/AccessProductsTable.vue';
  import AlibabaRecordsTable from '@/pages/digitalService/components/AlibabaRecordsTable.vue';

  export default {
    name: 'DefaultWidget',
    components: {
      AccessProductsTable,
      ActionsTable,
      AlibabaRecordsTable,
    },
    props: {
      manifest: { type: Object },
      actions: { type: Array },
      infraService: { type: Object },
      accessProducts: { type: Object },
      loadManifest: { type: Boolean, default: () => false },
      loadActions: { type: Boolean, default: () => false },
      loadAccessProducts: { type: Boolean, default: () => false },
    },
    data() {
      return {
        actionsDialog: false,
        tab: 'info',
        envTab: undefined,
      };
    },
  };
  </script>