<template>
  <div
    class="alibaba-records"
    v-for="(recordsGroup, index) in reformatedAlibabaRows"
    :key="index"
  >
    <div>
      <p >Alicloud Id: {{ recordsGroup.id }}</p>
    </div>
    <q-table :columns="columns" :rows="recordsGroup.records"> </q-table>
  </div>
</template>

<script>
export default {
  name: "AlibabaRecordsTable",
  props: {
    manifest: {
      type: Array,
    },
  },
  data() {
    return {
      columns: [
        {
          name: "id",
          label: "Client environment",
          align: "left",
          field: "environment",
        },
        {
          name: "region",
          label: "Region",
          align: "left",
          field: "region",
        },
        {
          name: "vpcId",
          label: "VPC ID",
          align: "left",
          field: "vpcId",
        },
        {
          name: "subnets",
          label: "Subnets",
          align: "left",
          field: "subnets",
        },
        {
          name: "snowTicket",
          label: "Snow ticket",
          align: "left",
          field: "snowTicket",
        },
      ],
    };
  },
  computed: {
    reformatedAlibabaRows() {
      const groupedByAlicloud = this.manifest.environments.reduce((acc, env) => {
        const { alicloudId } = env;
        acc[alicloudId] = acc[alicloudId] || [];
        acc[alicloudId].push(env);
        return acc;
      }, {});

      return Object.entries(groupedByAlicloud).map(([key, value]) => {
        return {
          id: key,
          records: value,
        };
      });
    },
  },
};
</script>

<style scoped lang="scss">
.alibaba-records {
  margin-bottom: 20px;
}
</style>
