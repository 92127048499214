import { entry as stringControlRendererEntry } from './stringControlRenderer';
import { entry as enumControlRendererEntry } from './enumControlRenderer';
import { entry as booleanControlRendererEntry } from './booleanControlRenderer';
import { entry as NumberControlRenderer } from './numberControlRenderer';
import { entry as OneOfEnumControlRenderer } from './isOneOfEnumControlRenderer';
import { entry as DateControlRenderer } from './dateControlRenderer';

export const controlRenderers = [
  stringControlRendererEntry,
  enumControlRendererEntry,
  booleanControlRendererEntry,
  NumberControlRenderer,
  OneOfEnumControlRenderer,
  DateControlRenderer,
];
