import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Quasar from 'quasar/src/vue-plugin.js';;
import notifyPlugin from '@/library/notify';
import loadingPlugin from '@/library/loading';
import quasarUserOptions from './quasar-user-options';
import apolloProvider from '@/library/http/apollo-provider';
import VueApexCharts from 'vue3-apexcharts';

createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(store)
  .use(router)
  .use(notifyPlugin)
  .use(loadingPlugin)
  .use(apolloProvider)
  .use(VueApexCharts)
  .mount('#app');
