<template>
  <div class="page-view">
    <q-btn
      @click="$emit('changeView', 'list')"
      :class="{ active: pageView === 'list' }"
      icon="mdi-view-list"
      text-color="blue-grey-6"
      flat
      class="btn--no-hover"
      :ripple="false"
    >
      <q-tooltip>List view</q-tooltip>
    </q-btn>
    <q-btn
      @click="$emit('changeView', 'grid')"
      :class="{ active: pageView === 'grid' }"
      icon="mdi-view-module"
      text-color="blue-grey-6"
      flat
      class="btn--no-hover"
      :ripple="false"
    >
      <q-tooltip>Grid view</q-tooltip>
    </q-btn>
  </div>
</template>

<script>
export default {
  name: 'PageView',
  props: {
    pageView: {
      type: String,
      default: 'grid',
    },
  },
};
</script>

<style scoped lang="scss">
.page-view {
  position: fixed;
  right: 0;
  top: 150px;
  display: flex;
  flex-direction: column;

  .q-btn {
    opacity: 0.3;

    &.active {
      opacity: 1;
    }
  }
}
</style>
