import { computed, ComputedRef, inject, ref, provide } from 'vue';
import {
  composePaths,
  computeLabel,
  getFirstPrimitiveProp,
  JsonFormsSubStates,
  isDescriptionHidden,
  Resolve,
} from '@jsonforms/core';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import isPlainObject from 'lodash/isPlainObject';
import get from 'lodash/get';
import Ajv from 'ajv';
import debounce from 'lodash/debounce';
import { useStyles } from '../styles';

export const useControlAppliedOptions = <I extends { control: any }>(input: I) => {
  return computed(() =>
    merge({}, cloneDeep(input.control.value.config), cloneDeep(input.control.value.uischema.options)),
  );
};

export const useComputedLabel = <I extends { control: any }>(input: I, appliedOptions: ComputedRef<any>) => {
  return computed((): string => {
    return computeLabel(
      input.control.value.label,
      input.control.value.required,
      !!appliedOptions.value?.hideRequiredAsterisk,
    );
  });
};

/**
 * Extracts Ajv from JSON Forms
 */
export const useAjv = () => {
  const jsonforms = inject<JsonFormsSubStates>('jsonforms');

  if (!jsonforms) {
    throw new Error("'jsonforms' couldn't be injected. Are you within JSON Forms?");
  }

  // should always exist
  return jsonforms.core?.ajv;
};

export const useQuasarControl = <I extends { control: any; handleChange: any }>(
  input: I,
  adaptValue: (target: any) => any = (v) => v,
  debounceWait?: number,
) => {
  const changeEmitter =
    typeof debounceWait === 'number' ? debounce(input.handleChange, debounceWait) : input.handleChange;

  const onChange = (value: any) => {
    changeEmitter(input.control.value.path, adaptValue(value));
  };

  const appliedOptions = useControlAppliedOptions(input);
  const isFocused = ref(false);

  const persistentHint = (): boolean => {
    return !isDescriptionHidden(
      input.control.value.visible,
      input.control.value.description,
      isFocused.value,
      !!appliedOptions.value?.showUnfocusedDescription,
    );
  };

  const computedLabel = useComputedLabel(input, appliedOptions);

  const controlWrapper = computed(() => {
    const { id, description, errors, label, visible, required } = input.control.value;
    return { id, description, errors, label, visible, required };
  });

  const styles = useStyles(input.control.value.uischema);

  return {
    ...input,
    styles,
    isFocused,
    appliedOptions,
    controlWrapper,
    onChange,
    persistentHint,
    computedLabel,
  };
};

export const useQuasarArrayControl = <I extends { control: any }>(input: I) => {
  const appliedOptions = useControlAppliedOptions(input);

  const computedLabel = useComputedLabel(input, appliedOptions);

  const childLabelForIndex = (index: number | null) => {
    if (index === null) {
      return '';
    }
    const childLabelProp =
      input.control.value.uischema.options?.childLabelProp ?? getFirstPrimitiveProp(input.control.value.schema);
    if (!childLabelProp) {
      return `${index}`;
    }
    const labelValue = Resolve.data(input.control.value.data, composePaths(`${index}`, childLabelProp));
    if (labelValue === undefined || labelValue === null || Number.isNaN(labelValue)) {
      return '';
    }
    return `${labelValue}`;
  };
  return {
    ...input,
    styles: useStyles(input.control.value.uischema),
    appliedOptions,
    childLabelForIndex,
    computedLabel,
  };
};

export const useTranslator = () => {
  const jsonforms = inject<JsonFormsSubStates>('jsonforms');

  if (!jsonforms) {
    throw new Error("'jsonforms couldn't be injected. Are you within JSON Forms?");
  }

  if (!jsonforms.i18n || !jsonforms.i18n.translate) {
    throw new Error("'jsonforms i18n couldn't be injected. Are you within JSON Forms?");
  }

  const translate = computed(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return jsonforms.i18n!.translate!;
  });

  return translate;
};

export interface NestedInfo {
  level: number;
  parentElement?: 'array' | 'object';
}
export const useNested = (element: false | 'array' | 'object'): NestedInfo => {
  const nestedInfo = inject<NestedInfo>('jsonforms.nestedInfo', { level: 0 });
  if (element) {
    provide('jsonforms.nestedInfo', {
      level: nestedInfo.level + 1,
      parentElement: element,
    });
  }
  return nestedInfo;
};
