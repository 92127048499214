<template>
  <div
    class="wizard-item"
    :class="[
      `${itemData.serviceData && itemData.serviceData.cloudType ? itemData.serviceData.cloudType.toLowerCase() : 'na'}`,
      { tool: itemData.serviceData && itemData.serviceData.type && itemData.serviceData.type === 'Tool' },
    ]"
    @click="next"
  >
    <span v-if="!headBlock" class="shadow"></span>
    <img v-if="!headBlock" :src="$options.head_tree" height="85" alt="" />
    <span v-if="headBlock" class="border"></span>
    <div class="content-wizard-item">
      <span v-if="itemData.serviceData && itemData.serviceData.icon && itemData.serviceData.icon !== 'default'">
        <infra-service-logo :logo="itemData.serviceData.icon" />
      </span>
      <span
        class="icon-service"
        v-if="
          itemData.type === 'service' &&
          itemData.serviceData &&
          itemData.serviceData.icon &&
          itemData.serviceData.icon === 'default'
        "
      >
        <q-icon name="settings" size="30px" color="white" />
      </span>
      <div class="content-label">
        <div class="label-markdown" v-html="dataMarkdown"></div>
        <sup v-if="serviceHint.trim().length">
          <q-btn
            flat
            round
            size="sm"
            color="white"
            text-color="text-color"
            class="q-px-none"
            @click.stop
            icon="mdi-information-outline"
          >
            <q-tooltip class="service-description service-reasons tooltip-reasons" v-html="serviceHint"> </q-tooltip>
          </q-btn>
        </sup>
      </div>
      <div class="action-block" v-if="itemData.type === 'service'">
        <span v-if="itemData.serviceData" class="service-label q-ml-auto">
          <q-icon v-if="isPaved" name="engp:engp-ico-paved_road" size="11px"></q-icon>
          <q-icon v-if="isSharedService" name="shared" size="11px" class="q-mt-xs" />
          <q-icon v-if="isStratum" name="engp:engp-ico-stratum" size="11px" class="q-mt-xs" />
          <q-icon v-if="isCertProduct" name="engp:engp-ico-cert_product" size="11px" class="q-mt-xs" />
          <q-icon v-if="isPlanned" name="engp:engp-ico-planned" size="11px" class="q-mt-xs" />
          <q-icon v-if="isDeprecated" name="engp:engp-ico-deprecated" size="11px" class="q-mt-xs" />
        </span>
        <div class="btn-group">
          <q-btn
            v-if="itemData.serviceData.orderable"
            @click="addToDesign"
            :disable="isInDesign"
            class="btn--no-hover"
            icon="add"
            flat
            padding="0"
            :ripple="false"
          >
            <q-tooltip v-if="!isInDesign">Add to Service Design</q-tooltip>
          </q-btn>
          <q-btn @click="goToInfraService" class="btn--no-hover" icon="more_horiz" flat padding="0" :ripple="false">
            <q-tooltip>More Info</q-tooltip>
          </q-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { marked } from 'marked';
import { mapActions } from 'vuex';
import head_tree from '../../../assets/head_tree.svg';
import { SERVICE_DESIGN_ACTIONS } from '@/store/modules/service-design';
import InfraServiceLogo from '@/shared/components/InfraServiceLogo.vue';

export default {
  name: 'WizardItem',
  components: {
    InfraServiceLogo,
  },
  head_tree,
  props: {
    headBlock: {
      type: Boolean,
      default: true,
    },
    itemData: {
      type: Object,
    },
    service: {
      type: Object,
    },
    design: {
      type: Object,
    },
    currentStep: {
      type: String,
    },
  },
  methods: {
    ...mapActions('serviceDesign', {
      setInfraService: SERVICE_DESIGN_ACTIONS.SET_INFRASERVICE,
    }),
    next() {
      if (this.itemData.type === 'usecase' || this.isInDesign) {
        this.$emit('next', this.itemData);
      }
    },
    addToDesign() {
      try {
        this.setInfraService({ category: this.service, infraService: this.itemData.serviceData });
        this.$notify(
          'info',
          'Info',
          `${
            this.itemData.serviceData.displayName || this.itemData.serviceData.serviceName
          } was added to your service design. Please choose digital service if you would like to onboard onto it.`,
        );
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
      }
    },
    goToInfraService() {
      this.$router.push({
        name: 'infraService',
        params: { id: this.itemData.serviceData.id, currentStep: this.currentStep },
      });
    },
  },
  computed: {
    serviceHint() {
      const follow = this.itemData.hint && this.itemData.hint.follow ? this.itemData.hint.follow : '';
      const avoid = this.itemData.hint && this.itemData.hint.avoid ? this.itemData.hint.avoid : '';
      return `
          ${follow ? '<b>Reasons to follow:</b>' : ''}
          ${marked(follow)}
          ${avoid ? '<b>Reasons to avoid:</b>' : ''}
          ${marked(avoid)}
        `;
    },
    dataMarkdown() {
      return this.itemData.label ? marked(this.itemData.label) : 'Missed item label';
    },
    isInDesign() {
      if (this.itemData && this.itemData.serviceData) {
        const category = this.design.find((category) => category.categoryId === this.service.categoryId);
        return category.infraServices.some((infra) => infra.id === this.itemData.serviceData.id);
      } else {
        return false;
      }
    },
    isPaved() {
      return this.itemData.serviceData && this.itemData.serviceData.pavedRoad;
    },
    isDeprecated() {
      return this.itemData.serviceData && this.itemData.serviceData.state === 'Deprecated';
    },
    isPlanned() {
      return this.itemData.serviceData && this.itemData.serviceData.state === 'Planned';
    },
    isCertProduct() {
      return this.itemData.serviceData && this.itemData.serviceData.type === 'Certified Product';
    },
    isStratum() {
      return this.itemData.serviceData && this.itemData.serviceData.type === 'Stratum';
    },
    isSharedService() {
      return this.itemData.serviceData && this.itemData.serviceData.type === 'Shared Service';
    },
  },
};
</script>

<style lang="scss" scoped>
.wizard-item {
  p {
    margin: 0;
  }
}

.content-label {
  display: inline-flex;
  align-items: center;

  .label-markdown {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.service {
  .action-block {
    display: flex;
    margin-left: auto;
    padding: 0 8px;

    .btn-group {
      display: flex;
      flex-direction: column;
      margin-left: 5px;
    }
  }

  .icon-service {
    display: flex;
    min-width: 45px;
    height: 45px;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    border-radius: 50%;
    background-color: var(--q-primary);
  }

  .service-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .content-wizard-item {
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 40px;
    padding: 10px;
    align-items: center;
    justify-content: flex-start;
  }

  .content-label {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.15;
    text-align: left;
  }

  &.private {
    .content-wizard-item {
      background-color: #e9f4ff;
    }

    .border {
      background-color: rgba(66, 140, 225, 0.5);
    }

    .icon-service {
      background-color: #428ce1;
    }

    .service-label {
      i {
        color: #428ce1;
      }
    }
  }

  &.public {
    .content-wizard-item {
      background-color: #e3f9ed;
    }

    .border {
      background-color: rgba(115, 225, 165, 0.5);
    }

    .icon-service {
      background-color: #73e1a5;
    }

    .service-label {
      i {
        color: #73e1a5;
      }
    }
  }

  &.onprem {
    .content-wizard-item {
      background-color: #eee6f8;
    }

    .border {
      background-color: rgba(168, 140, 209, 0.5);
    }

    .icon-service {
      background-color: #a88cd1;
    }

    .service-label {
      i {
        color: #a88cd1;
      }
    }
  }

  &.na {
    .content-wizard-item {
      background-color: #eff5c6;
    }

    .border {
      background-color: rgba(179, 187, 126, 0.5);
    }

    .icon-service {
      background-color: #b3bb7e;
    }

    .service-label {
      i {
        color: #b3bb7e;
      }
    }
  }

  &.tool {
    .content-wizard-item {
      background-color: #f4f0e6;
    }

    .border {
      background-color: rgba(191, 185, 167, 0.5);
    }

    .icon-service {
      background-color: #bfb9a7;
    }

    .service-label {
      i {
        color: #bfb9a7;
      }
    }
  }

  //&.production {
  //  .content-wizard-item {
  //    background-color: #e3f9ed;
  //  }
  //  .border {
  //    background-color: rgba(116, 224, 165, 0.6);
  //  }
  //}
  //
  //&.pavedRoad {
  //  .content-wizard-item {
  //    background-color: #D9E8F9;
  //  }
  //  .border {
  //    background-color: rgba(66, 140, 225, 0.4);
  //  }
  //  .icon-service {
  //    background-color: #418CE1;
  //  }
  //}
}
</style>
