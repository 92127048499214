export const buildCatalogServiceQuery = (filters: any) => {
  const { attributes, pavedRoad, serviceTypes } = filters;
  let query = `query { 
    actualCatalogServices(
      filterCatalogsDTO: {`;
  if (attributes) {
    const isAttributes = Object.keys(attributes).length;
    if (isAttributes) {
      query += `
      attributes: {
      `;
      if (attributes.sharedServices) {
        query += `Shared_Service:true
        `;
      }
      if (attributes.stratum) {
        query += `Stratum:true
        `;
      }
      if (attributes.planned) {
        query += `Planned:true
        `;
      }
      if (attributes.deprecated) {
        query += `Deprecated:true
        `;
      }
      if (attributes.certified) {
        query += `Certified_Product:true
        `;
      }
      query += `}`;
    }
  }
  if (serviceTypes) {
    const isServiceType = Object.keys(serviceTypes).length;
    if (isServiceType) {
      query += `
      serviceTypes: {
      `;
      if (serviceTypes.public) {
        query += `Public:true
        `;
      }
      if (serviceTypes.private) {
        query += `Private:true
        `;
      }
      if (serviceTypes.onPrem) {
        query += `Onprem:true
        `;
      }
      if (serviceTypes.tools) {
        query += `Tool:true
        `;
      }
      if (serviceTypes.independent) {
        query += `Independent:true
        `;
      }
      if (serviceTypes.notAvailable) {
        query += `NotAvailable:true
        `;
      }
      query += `}`;
    }
  }
  if (pavedRoad) {
    query += `
      pavedRoad: "PAVED_ROAD"
    `;
  }
  query += `}
    ) {
      serviceCategories {
        name
        description
        id
        categoryId
        positioningMessages {
          message
        }
        infraServices {
          id
          apmid
          icon
          categoryName
          isCatalogPart
          orderable
          description
          displayName
          pavedRoad
          serviceName
          state
          cloudType
          type
          parentServiceDisplayName
          serviceId
          parentServiceId
        }
        serviceCategories {
          name
          description
          id
          categoryId
          positioningMessages {
            message
          }
          infraServices {
            id
            apmid
            icon
            categoryName
            isCatalogPart
            orderable
            description
            displayName
            pavedRoad
            serviceName
            cloudType
            state
            type
            parentServiceDisplayName
            serviceId
            parentServiceId
          }
        }
      }
    }
  }`;
  return query;
};
