import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const connectionString = process.env.VUE_APP_INSIGHTS_CONNECTION_STRING;
const appInsights = new ApplicationInsights({
  config: {
    connectionString: connectionString,
  },
});

export default appInsights;
