import Notify from 'quasar/src/plugins/Notify.js';;
import dayjs from 'dayjs';
import { NOTIFICATIONS_ACTIONS } from '@/store/modules/notifications';

const notifyPlugin = {
  install(app: any) {
    app.config.globalProperties.$notify = function globalNotify(type: string, caption: string, message: string) {
      Notify.create({
        type,
        icon: type === 'negative' ? 'sym_o_close' : type === 'positive' ? 'sym_o_check' : 'sym_o_exclamation',
        timeout: 3000,
        message: `<div class="notification"><span>${message}</span><span class="date-message">${dayjs(
          new Date(),
        ).format('DD.MM.YYYY HH:mm')}</span></div>`,
        position: 'bottom-right',
        progress: true,
        classes: `notify-${type}`,
        group: false,
        html: true,
      });
      this.$store.dispatch(`notifications/${NOTIFICATIONS_ACTIONS.ADD_NOTIFICATION}`, { type, caption, message });
    };
  },
};

export default notifyPlugin;
