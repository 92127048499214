<template>
  <q-page class="main-wrapper-page single-page" style="background-color: var(--q-accent)">
    <div class="catalog-legend">
      <q-scroll-area
        :thumb-style="{ borderRadius: '5px', background: '#9f9f9f', height: '3px', opacity: 1 }"
        style="height: 30px; max-width: 100%"
      >
        <div class="row-legend">
          <div class="col types">
            <span>Service Types:</span>
            <ul>
              <li class="private">
                <span></span>
                Private Cloud
              </li>
              <li class="public">
                <span></span>
                Public Clouds
              </li>
              <li class="onprem">
                <span></span>
                On Prem
              </li>
              <li class="tool">
                <span></span>
                Tools
              </li>
              <li class="independent">
                <span></span>
                Independent
              </li>
            </ul>
          </div>
          <div class="col attributes">
            <span>Attributes:</span>
            <ul>
              <li>
                <q-icon name="engp:engp-ico-paved_road" size="xs" />
                Paved Road
              </li>
              <li>
                <q-icon name="engp:engp-ico-stratum" size="xs" />
                Stratum
              </li>
              <li>
                <q-icon name="engp:engp-ico-cert_product" size="xs" />
                Certified Product
              </li>
              <li>
                <q-icon name="engp:engp-ico-planned" size="xs" />
                Planned
              </li>
              <li>
                <q-icon name="engp:engp-ico-deprecated" size="xs" />
                Not recommended
              </li>
            </ul>
          </div>
        </div>
      </q-scroll-area>
    </div>
    <div ref="standard" class="templates q-mb-lg" v-if="standardTemplates.length">
      <div class="heading flex justify-between items-center q-mb-md">
        <h5 class="q-my-none">Standard templates</h5>
        <span class="count">{{ standardTemplates.length }}</span>
      </div>
      <service-template
        v-for="template in standardTemplates"
        templateType="standard"
        :current-service="currentService"
        :key="template.id"
        :template-data="template"
        @load="load"
      >
      </service-template>
    </div>
    <div ref="custom" class="templates q-mb-lg" v-if="myTemplates.length">
      <div class="heading flex justify-between items-center q-mb-md">
        <h5 class="q-my-none">My templates</h5>
        <span class="count">{{ myTemplates.length }}</span>
      </div>
      <service-template
        v-for="template in myTemplates"
        templateType="custom"
        @delete="deleteTemplate"
        :current-service="currentService"
        :key="template.id"
        :template-data="template"
        @load="load"
      >
      </service-template>
    </div>
  </q-page>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { scroll } from 'quasar';
import emitter from 'tiny-emitter/instance';
import { TEMPLATES_ACTIONS, TEMPLATES_GETTERS } from '@/store/modules/templates';
import { DIGITAL_SERVICES_GETTERS } from '@/store/modules/digital-services';
import { BANNERS_GETTERS } from '@/store/modules/banners';
import ServiceTemplate from '@/pages/templates/components/ServiceTemplate';
import { SERVICES_ACTIONS } from '@/store/modules/services';
import { SERVICE_DESIGN_GETTERS } from '@/store/modules/service-design';

const { getScrollTarget, setVerticalScrollPosition } = scroll;

export default {
  name: 'TemplatesPage',
  components: {
    ServiceTemplate,
  },
  emits: ['load'],
  async mounted() {
    if (!this.services.length) {
      this.getCatalog();
    }
    if (!this.allTemplates.length) {
      await this.load();
    }
  },
  computed: {
    ...mapGetters('templates', {
      allTemplates: TEMPLATES_GETTERS.ALL,
    }),
    ...mapGetters('digitalServices', {
      currentService: DIGITAL_SERVICES_GETTERS.CURRENT_SERVICE,
    }),
    ...mapGetters('banners', {
      currentBanner: BANNERS_GETTERS.CURRENT,
    }),
    ...mapGetters('serviceDesign', {
      services: SERVICE_DESIGN_GETTERS.ALL,
    }),
    standardTemplates() {
      return this.allTemplates.filter((template) => template.type === 'STANDARD');
    },
    myTemplates() {
      return this.allTemplates.filter((template) => template.type === 'CUSTOM');
    },
  },
  methods: {
    ...mapActions('templates', {
      getTemplates: TEMPLATES_ACTIONS.GET_TEMPLATES,
      removeTemplate: TEMPLATES_ACTIONS.DELETE_TEMPLATE,
    }),
    ...mapActions('services', {
      getCatalog: SERVICES_ACTIONS.GET_SERVICES,
    }),
    async load() {
      try {
        this.$showLoading();
        await this.getTemplates();
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
      } finally {
        this.$hideLoading();
      }
    },
    async deleteTemplate(id) {
      try {
        await this.removeTemplate(id);
        this.$notify('positive', 'Success', 'Template was removed successfully.');
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
      } finally {
        await this.load();
      }
    },
    scrollToItem(anchor) {
      const element = this.$refs[anchor];
      const target = getScrollTarget(element);
      const offset = element.offsetTop - (this.currentBanner ? 95 : 45);
      const duration = 1000;
      setVerticalScrollPosition(target, offset, duration);
    },
  },
  created() {
    emitter.on('goToTemplateAnchor', (ref) => {
      this.scrollToItem(ref);
    });
  },
  unmounted() {
    emitter.off('goToTemplateAnchor');
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.templates {
  background-color: white;
  border-radius: 15px;
  padding: 20px;
}
.heading {
  font-size: 20px;
  font-weight: bold;

  h5 {
    font-weight: bold;
  }

  .count {
    font-size: 24px;
  }
}
</style>
