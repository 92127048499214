import axios from 'axios';
import msalInstance from './apollo';

const apiPrefix = `${process.env.VUE_APP_API_HOST_SCHEMA_SERVER}` || '/schema-mng-api/v1';
const apiPrefixAppReg = `${process.env.VUE_APP_API_HOST_APP_REG}` || '/ars-api/v1';
const apiPrefixServiceCatalog = `${process.env.VUE_APP_API_HOST}` || '/service-catalog-api/v1';

const DEFAULT_HEADERS = {
  Accept: 'application/json',
};

// eslint-disable-next-line
let resolveHandleRedirect: any = () => {};
// eslint-disable-next-line
let rejectHandleRedirect: any = () => {};

msalInstance.msalInstance
  .handleRedirectPromise()
  .then((response: any) => resolveHandleRedirect(response && response.account))
  .catch((error: any) => rejectHandleRedirect(error));

const waitForMsalHandleRedirect = new Promise((resolve, reject) => {
  resolveHandleRedirect = resolve;
  rejectHandleRedirect = reject;
});

const clientId = process.env.VUE_APP_AAD_CLIENT_ID;

const request = {
  scopes: [`${clientId}/Profile.Read`],
};

async function getTokenOrRedirect() {
  const accountAfterRedirect = await waitForMsalHandleRedirect;

  // get account back from redirect or first account from MSAL
  const account = accountAfterRedirect || msalInstance.msalInstance.getAllAccounts().shift();

  if (!account) {
    // redirect to microsoft - code never continues after this call
    await msalInstance.msalInstance.acquireTokenRedirect(request);
  }

  // get token from cache or get token from microsoft with refresh token
  // or get new pair of tokens from microsoft if session is alive but tokens expired
  const tokenData = await msalInstance.msalInstance
    .acquireTokenSilent({
      ...request,
      account,
    })
    .catch(async () => {
      // or make user to log in
      await msalInstance.msalInstance.acquireTokenRedirect(request);
    });

  return tokenData.accessToken;
}

export const call = async (url: string, options: any) => {
  const response = await axios(
    apiPrefix + url,
    Object.assign(options, {
      params: {},
      method: 'get',
      dataType: 'json',
      headers: { ...DEFAULT_HEADERS },
      withCredentials: true,
      timeout: 0,
    }),
  ).catch((error: any) => {
    if (error.response && error.response.data && error.response.data.message) {
      error.message = error.response.data.message;
    } else {
      error.message = 'Service is unavailable right now';
    }

    throw error;
  });

  return response.data;
};

export async function get(url: string, params = {}, headers = {}) {
  return call(url, {
    method: 'get',
    headers: {
      ...DEFAULT_HEADERS,
      ...headers,
    },
    params,
  });
}

export async function post(url: string, data = {}, headers = {}) {
  return call(url, {
    method: 'post',
    dataType: 'json',
    data,
    headers: {
      ...DEFAULT_HEADERS,
      ...headers,
    },
  });
}

export async function del(url: string, data = {}, headers = {}) {
  return call(url, {
    method: 'delete',
    dataType: 'json',
    data,
    headers: {
      ...DEFAULT_HEADERS,
      ...headers,
    },
  });
}

export async function patch(url: string, data = {}, headers = {}) {
  return call(url, {
    method: 'patch',
    data,
    headers: {
      ...DEFAULT_HEADERS,
      ...headers,
    },
  });
}

export async function put(url: string, data = {}, headers = {}) {
  return call(url, {
    method: 'put',
    data,
    headers: {
      ...DEFAULT_HEADERS,
      ...headers,
    },
  });
}

export async function callArs(url: string, options = {}) {
  const response = await axios(
    apiPrefixAppReg + url,
    Object.assign(
      {
        params: {},
        method: 'get',
        dataType: 'json',
        headers: { ...DEFAULT_HEADERS },
        withCredentials: true,
        timeout: 0,
      },
      options,
    ),
  ).catch((error: any) => {
    if (error.response && error.response.data && error.response.data.message) {
      error.message = error.response.data.message;
    } else {
      error.message = 'Service is unavailable right now';
    }

    throw error;
  });

  return response.data;
}

export async function getArs(url: string, params = {}, headers = {}) {
  return callArs(url, {
    method: 'get',
    params,
    headers: {
      ...DEFAULT_HEADERS,
      ...headers,
    },
  });
}

export async function postArs(url: string, data = {}, headers = {}) {
  return callArs(url, {
    method: 'post',
    dataType: 'json',
    data,
    headers: {
      ...DEFAULT_HEADERS,
      ...headers,
    },
  });
}

export async function callServiceCatalog(url: string, options = {}) {
  const token = await getTokenOrRedirect();
  const response = await axios(
    apiPrefixServiceCatalog + url,
    Object.assign(options, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }),
  ).catch((error: any) => {
    if (error.response && error.response.data && error.response.data.message) {
      error.message = error.response.data.message;
    } else {
      error.message = 'Service is unavailable right now';
    }

    throw error;
  });

  return response.data;
}

export async function uploadServiceCatalog(url: string, data: any) {
  return callServiceCatalog(url, {
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  });
}

export async function postServiceCatalog(url: string, params = {}, headers = {}) {
  return callServiceCatalog(url, {
    method: 'post',
    params,
  });
}
