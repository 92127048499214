<template>
  <span class="label-block q-mt-md" v-if="Object.values(infraService.links).length">Links:</span>
  <div class="links" v-if="Object.values(infraService.links).length">
    <ul
      v-for="(group, key) in Object.keys(infraService.links)"
      :key="key"
      :style="`width:${100 / Object.values(infraService.links).length}%`"
    >
      <span>{{ group }}</span>
      <li v-for="link in infraService.links[group]" :key="link.id">
        <a :href="link.url" target="_blank">{{ link.label }}</a>
      </li>
    </ul>
  </div>
  <div>
    <div v-if="loadManifest" class="flex items-center justify-center q-py-lg">
      <q-spinner-gears size="40px" color="primary"></q-spinner-gears>
    </div>
    <div v-if="!loadManifest && manifest.environments && manifest.environments.length" class="q-pt-xs">
      <q-tabs
        v-model="infoTab"
        inline-label
        content-class="justify-start"
        class="q-mt-none widget-tabs"
        indicator-color="primary"
      >
        <q-tab name="vnets" label="VNETs" />
        <q-tab name="zones" label="External DNS records" />
      </q-tabs>
      <q-tab-panels v-model="infoTab" animated>
        <q-tab-panel name="vnets" class="q-px-none q-py-none">
          <q-tabs
            v-model="envTab"
            inline-label
            content-class="justify-start"
            class="q-mt-md widget-tabs"
            active-class="active-tab"
            indicator-color="transparent"
          >
            <q-tab v-for="item in manifest.environments" :name="item.id" :key="item.id">
              <q-icon name="check_circle" class="q-mr-xs" color="light-blue-7" size="18px"></q-icon>
              <span style="text-transform: capitalize; font-size: 12px">{{ item.id }}</span>
            </q-tab>
          </q-tabs>
          <q-tab-panels v-model="envTab" animated>
            <q-tab-panel
              v-for="item in manifest.environments"
              :key="item.id"
              :name="item.id"
              class="q-px-none q-py-none"
            >
              <div>
                <q-table :rows="item.vnets" :columns="cols" flat class="table-info access-products-table vnets-table">
                  <template v-slot:no-data>
                    <div class="flex full-width column items-center q-py-lg">
                      <q-icon name="mdi-cloud-search" class="q-mb-sm" size="40px" color="primary"></q-icon>
                      <span class="block" style="font-size: 14px">No items.</span>
                    </div>
                  </template>
                  <template v-slot:body-cell-objStatus="props">
                    <q-td style="width: 30px" :props="props">
                      <div class="status">
                        <span :class="props.row.objStatus ? props.row.objStatus.toLowerCase() : ''">
                          <q-tooltip>{{ props.row.objStatus || '' }}</q-tooltip>
                        </span>
                      </div>
                    </q-td>
                  </template>
                  <template v-slot:body-cell-lastActionName="props">
                    <q-td style="width: 30px" :props="props">
                      <span v-if="props.row.lastAction.name">{{ props.row.lastAction.name }}</span>
                      <q-icon v-else name="remove"></q-icon>
                    </q-td>
                  </template>
                  <template v-slot:body-cell-lastChangeId="props">
                    <q-td :props="props">
                      <span v-if="props.row.lastChangeRequest.number">
                        <a :href="props.row.lastChangeRequest.link" target="_blank">
                          {{ props.row.lastChangeRequest.number }}
                        </a>
                      </span>
                      <q-icon v-else name="remove"></q-icon>
                    </q-td>
                  </template>
                </q-table>
              </div>
            </q-tab-panel>
          </q-tab-panels>
        </q-tab-panel>
        <q-tab-panel name="zones" class="q-px-none q-py-none">
          <div v-if="!loadManifest" class="q-mt-md">
            <div class="row no-wrap q-col-gutter-sm">
              <div class="col">
                <q-select
                  v-model="dnsFilters.type"
                  :options="['A', 'AAAA', 'CNAME', 'CNAME-WITH-REDIRECT', 'TXT', 'NS', 'MX', 'SRV']"
                  options-dense
                  label="Type"
                  outlined
                  dense
                  clearable
                  class="q-mb-sm bg-white"
                ></q-select>
              </div>
              <div class="col">
                <q-input
                  v-model="dnsFilters.name"
                  label="Record name"
                  outlined
                  dense
                  clearable
                  class="q-mb-sm bg-white"
                ></q-input>
              </div>
              <div class="col">
                <q-input
                  v-model="dnsFilters.domain"
                  label="Domain"
                  outlined
                  dense
                  clearable
                  class="q-mb-sm bg-white"
                ></q-input>
              </div>
              <div class="col">
                <q-input
                  v-model="dnsFilters.value"
                  label="Value"
                  outlined
                  dense
                  clearable
                  class="q-mb-sm bg-white"
                ></q-input>
              </div>
              <div class="col">
                <q-select
                  v-model="dnsFilters.objStatus"
                  :options="statusOptions"
                  option-value="value"
                  map-options
                  option-label="label"
                  emit-value
                  options-dense
                  label="Status"
                  outlined
                  dense
                  clearable
                  class="q-mb-sm bg-white"
                ></q-select>
              </div>
            </div>
            <q-table
              v-if="!loadManifest && manifest.externalDNSZones.length"
              :rows="filteredDnsZones"
              :columns="zoneCols"
              flat
              class="table-info access-products-table vnets-table"
            >
              <template v-slot:no-data>
                <div class="flex full-width column items-center q-py-lg">
                  <q-icon name="mdi-cloud-search" class="q-mb-sm" size="40px" color="primary"></q-icon>
                  <span class="block" style="font-size: 14px">No items were found.</span>
                </div>
              </template>
              <template v-slot:body-cell-value="props">
                <q-td style="width: 30px" :props="props">
                  <span
                    style="
                      display: block;
                      max-width: 150px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                  >
                    {{ props.row.value || '' }}
                    <q-tooltip>{{ props.row.value || '' }}</q-tooltip>
                  </span>
                </q-td>
              </template>
              <template v-slot:body-cell-objStatus="props">
                <q-td style="width: 30px" :props="props">
                  <div class="status">
                    <span :class="props.row.objStatus ? props.row.objStatus.toLowerCase() : ''">
                      <q-tooltip>{{ props.row.objStatus || '' }}</q-tooltip>
                    </span>
                  </div>
                </q-td>
              </template>
              <template v-slot:body-cell-lastActionName="props">
                <q-td style="width: 30px" :props="props">
                  <span v-if="props.row.lastAction.name">{{ props.row.lastAction.name }}</span>
                  <q-icon v-else name="remove"></q-icon>
                </q-td>
              </template>
              <template v-slot:body-cell-lastChangeId="props">
                <q-td :props="props">
                  <q-icon v-if="props.row.lastChangeRequest.error" name="error" class="q-mr-xs" color="red" size="20px">
                    <q-tooltip>{{ props.row.lastChangeRequest.error }}</q-tooltip>
                  </q-icon>
                  <span v-if="props.row.lastChangeRequest.number">
                    <a :href="props.row.lastChangeRequest.link" target="_blank">
                      {{ props.row.lastChangeRequest.number }}
                    </a>
                  </span>
                  <q-icon name="pending_actions" size="20px" color="primary" v-else-if="props.row.lastChangeWaiting">
                    <q-tooltip v-if="props.row.lastChangeRequest.number"
                      >DNS record is in the process of being updated. Change request will be changed</q-tooltip
                    >
                    <q-tooltip v-else-if="props.row.markDeleted"
                      >DNS record is in the process of being deleted.</q-tooltip
                    >
                    <q-tooltip v-else>
                      {{
                        props.row.objStatus.toLowerCase() === 'completed'
                          ? 'DNS record was created successfully but for some reason there is no Change Request information.'
                          : 'DNS record is in the process of being created.'
                      }}
                    </q-tooltip>
                  </q-icon>
                  <q-icon v-else name="remove"></q-icon>
                </q-td>
              </template>
            </q-table>
            <div v-else class="flex full-width column items-center q-py-lg" style="padding: 45px 0">
              <q-icon name="hide_source" class="q-mb-sm" size="60px" color="primary"></q-icon>
              <span class="block" style="font-size: 16px">No data</span>
            </div>
          </div>
        </q-tab-panel>
      </q-tab-panels>
    </div>
  </div>
  <q-expansion-item class="comment-actions">
    <template v-slot:header>
      <strong>Action comments</strong>
    </template>
    <div v-if="loadCommentActions" class="flex items-center justify-center q-py-lg" style="padding: 30px 0">
      <q-spinner-gears size="40px" color="primary"></q-spinner-gears>
    </div>
    <div class="actions-table-holder q-mt-md" v-else-if="commentActions.length">
      <action-comments-table :comments="commentActions" />
    </div>
    <div v-else class="flex full-width column items-center q-py-lg" style="padding: 30px 0">
      <q-icon name="hide_source" class="q-mb-sm" size="40px" color="primary"></q-icon>
      <span class="block" style="font-size: 16px">No data</span>
    </div>
  </q-expansion-item>
  <q-expansion-item class="actions-item">
    <template v-slot:header>
      <strong>List of actions</strong>
    </template>
    <div v-if="loadActions" class="flex items-center justify-center q-py-lg" style="padding: 30px 0">
      <q-spinner-gears size="40px" color="primary"></q-spinner-gears>
    </div>
    <div class="actions-table-holder q-mt-md" v-else-if="actions.length">
      <actions-table @showActions="actionsDialog = true" :actions="actions" />
    </div>
    <div v-else class="flex full-width column items-center q-py-lg" style="padding: 30px 0">
      <q-icon name="hide_source" class="q-mb-sm" size="40px" color="primary"></q-icon>
      <span class="block" style="font-size: 16px">No data</span>
    </div>
  </q-expansion-item>
  <q-expansion-item class="access-products-item">
    <template v-slot:header>
      <strong>Access products</strong>
    </template>
    <div v-if="loadAccessProducts" class="flex items-center justify-center q-py-lg" style="padding: 30px 0">
      <q-spinner-gears size="40px" color="primary"></q-spinner-gears>
    </div>
    <div class="actions-table-holder q-mt-md" v-else-if="Object.keys(accessProducts).length">
      <access-products-table :accessProducts="accessProducts"></access-products-table>
    </div>
    <div v-else class="flex full-width column items-center q-py-lg" style="padding: 30px 0">
      <q-icon name="hide_source" class="q-mb-sm" size="40px" color="primary"></q-icon>
      <span class="block" style="font-size: 16px">No data</span>
    </div>
  </q-expansion-item>

  <q-dialog v-model="actionsDialog" class="single-service-page" persistent>
    <q-card style="min-width: 768px; border-radius: 0px">
      <q-toolbar class="shadow-2 q-py-sm q-px-md">
        <q-toolbar-title class="text-weight-bold">
          <strong>Actions for {{ infraService.displayName || infraService.serviceName }}</strong>
        </q-toolbar-title>
        <q-btn icon="close" round flat class="btn--no-hover" @click="actionsDialog = false"></q-btn>
      </q-toolbar>
      <q-card-section class="q-pt-none">
        <actions-table showAll :actions="actions" />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import ActionsTable from '@/pages/digitalService/components/ActionsTable';
import AccessProductsTable from '@/pages/digitalService/components/AccessProductsTable.vue';
import * as uiConfig from '@/shared/ui-config.json';
import ActionCommentsTable from '@/pages/digitalService/components/ActionCommentsTable';

export default {
  name: 'NetworkWidget',
  components: {
    ActionCommentsTable,
    AccessProductsTable,
    ActionsTable,
  },
  props: {
    manifest: { type: Object, default: () => ({ environments: [] }) },
    actions: { type: Array },
    infraService: { type: Object },
    accessProducts: { type: Object },
    loadManifest: { type: Boolean, default: () => false },
    loadActions: { type: Boolean, default: () => false },
    loadAccessProducts: { type: Boolean, default: () => false },
    commentActions: { type: Array },
    loadCommentActions: { type: Boolean, default: () => false },
  },
  watch: {
    manifest(value) {
      if (Object.values(value).length) {
        this.envTab = this.manifest.environments[0].id;
      }
    },
  },
  computed: {
    filteredDnsZones() {
      return Object.values(this.manifest.externalDNSZones).filter((zone) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const prop in this.dnsFilters) {
          if (this.dnsFilters[prop]) {
            if (prop === 'type') {
              if (this.dnsFilters.type !== zone.type) {
                return false;
              }
            } else {
              if (!new RegExp(this.dnsFilters[prop], 'i').test(zone[prop])) {
                return false;
              }
            }
          }
        }
        return true;
      });
    },
  },
  data() {
    return {
      statusOptions: [
        { label: 'Pending', value: 'pending' },
        { label: 'In progress', value: 'inprogress' },
        { label: 'Processing', value: 'processing' },
        { label: 'Completed', value: 'completed' },
        { label: 'Failed', value: 'failed' },
        { label: 'Abandoned', value: 'abandoned' },
      ],
      dnsFilters: {
        name: undefined,
        type: undefined,
        value: undefined,
        domain: undefined,
      },
      actionsDialog: false,
      tab: 'info',
      infoTab: 'vnets',
      envTab: undefined,
      zoneCols: [
        {
          label: 'Status',
          name: 'objStatus',
          field: 'objStatus',
          align: 'left',
        },
        {
          label: 'Name',
          name: 'name',
          field: 'name',
          align: 'left',
        },
        {
          label: 'Type',
          name: 'type',
          field: 'type',
          align: 'left',
        },
        {
          label: 'Value',
          name: 'value',
          field: 'value',
          align: 'left',
        },
        {
          label: 'Domain',
          name: 'domain',
          field: 'domain',
          align: 'left',
        },
        {
          label: 'Last action',
          name: 'lastActionName',
          field: 'lastActionName',
          align: 'center',
        },
        {
          label: 'Change request',
          name: 'lastChangeId',
          field: 'lastChangeId',
          align: 'center',
        },
      ],
      cols: [
        {
          label: 'Status',
          name: 'objStatus',
          field: 'objStatus',
          align: 'left',
        },
        {
          label: 'Name',
          name: 'name',
          field: 'name',
          align: 'left',
        },
        {
          label: 'Region',
          name: 'region',
          field: 'region',
          align: 'left',
        },
        {
          label: 'Resource Group',
          name: 'resourceGroup',
          field: 'resourceGroup',
          align: 'left',
        },
        {
          label: 'Size',
          name: 'size',
          field: 'size',
          align: 'left',
        },
        {
          label: 'Last action',
          name: 'lastActionName',
          field: 'lastActionName',
          align: 'center',
        },
        {
          label: 'Change request',
          name: 'lastChangeId',
          field: 'lastChangeId',
          align: 'center',
        },
      ],
      uiConfig: {},
    };
  },
  mounted() {
    this.uiConfig = uiConfig[process.env.VUE_APP_ENV];
  },
};
</script>

<style scoped lang="scss">
.env-holder {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  padding: 15px;
}
.table-info {
  border-bottom: none;
}
.active-tab {
  background-color: #edeff5;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
</style>
