import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "row q-col-gutter-sm items-center",
  style: {"width":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dispatch_renderer = _resolveComponent("dispatch-renderer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layout.uischema.elements, (element, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `${_ctx.layout.path}-${index}`,
        class: _normalizeClass(["col q-mb-sm", element.class])
      }, [
        _createVNode(_component_dispatch_renderer, {
          schema: _ctx.layout.schema,
          uischema: element,
          path: _ctx.layout.path,
          enabled: _ctx.layout.enabled,
          renderers: _ctx.layout.renderers,
          cells: _ctx.layout.cells,
          isNew: _ctx.isNew,
          isNewEl: _ctx.isNewEl,
          freezeManifest: _ctx.freezeManifest,
          currentEnv: _ctx.currentEnv,
          networkCanBeChanged: _ctx.networkCanBeChanged,
          networkPermissions: _ctx.networkPermissions,
          cloudentePermissions: _ctx.cloudentePermissions,
          dataElement: _ctx.dataElement,
          manifest: _ctx.manifest,
          isNetwork: _ctx.isNetwork,
          dateCanBeEdit: _ctx.dateCanBeEdit,
          approvalGroupState: _ctx.approvalGroupState,
          dense: _ctx.dense,
          showFilters: _ctx.showFilters
        }, null, 8, ["schema", "uischema", "path", "enabled", "renderers", "cells", "isNew", "isNewEl", "freezeManifest", "currentEnv", "networkCanBeChanged", "networkPermissions", "cloudentePermissions", "dataElement", "manifest", "isNetwork", "dateCanBeEdit", "approvalGroupState", "dense", "showFilters"])
      ], 2))
    }), 128))
  ]))
}