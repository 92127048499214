import Loading from 'quasar/src/plugins/Loading.js';;

const loadingPlugin = {
  install(app: any) {
    app.config.globalProperties.$showLoading = function globalNotify() {
      Loading.show();
    };
    app.config.globalProperties.$hideLoading = function globalNotify() {
      Loading.hide();
    };
  },
};

export default loadingPlugin;
