import { entry as enumArrayRendererEntry } from './enumArrayRenderer';
import { entry as arrayControlRendererEntry } from './arrayControlRenderer';
import { entry as objectRendererEntry } from './objectRenderer';
import { entry as anyOfRendererEntry } from './anyOfControlRenderer';

export const complexRenderers = [
  enumArrayRendererEntry,
  arrayControlRendererEntry,
  objectRendererEntry,
  anyOfRendererEntry,
];
