<template>
  <span class="label-block q-mt-md" v-if="Object.values(infraService.links).length">Links:</span>
  <div class="links" v-if="Object.values(infraService.links).length">
    <ul
      v-for="(group, key) in Object.keys(infraService.links)"
      :key="key"
      :style="`width:${100 / Object.values(infraService.links).length}%`"
    >
      <span>{{ group }}</span>
      <li v-for="link in infraService.links[group]" :key="link.id">
        <a :href="link.url" target="_blank">{{ link.label }}</a>
      </li>
    </ul>
  </div>
  <div v-if="loadManifest" class="flex items-center justify-center q-py-lg">
    <q-spinner-gears size="40px" color="primary"></q-spinner-gears>
  </div>
  <div v-else class="q-pt-lg">
    <q-table
      :rows="manifest.zones"
      :columns="cols"
      flat
      class="table-info access-products-table table-actions cloudente-table"
    >
      <template v-slot:no-data>
        <div class="flex full-width column items-center q-py-lg">
          <q-icon name="mdi-cloud-search" class="q-mb-sm" size="40px" color="primary"></q-icon>
          <span class="block" style="font-size: 14px">No items.</span>
        </div>
      </template>
      <template v-slot:body-cell-tokenUrl="props">
        <q-td :props="props">
          <a v-if="props.row.tokenUrl" :href="props.row.tokenUrl" target="_blank"> TokenUrl </a>
          <q-icon v-else name="remove"></q-icon>
        </q-td>
      </template>
      <template v-slot:body-cell-DNS="props">
        <q-td :props="props">
          <q-icon :name="props.row.DNS ? 'check' : 'remove'"></q-icon>
        </q-td>
      </template>
      <template v-slot:body-cell-objStatus="props">
        <q-td style="width: 30px" :props="props">
          <div class="status">
            <span :class="props.row.objStatus ? props.row.objStatus.toLowerCase() : ''">
              <q-tooltip>{{ props.row.objStatus || '' }}</q-tooltip>
            </span>
          </div>
        </q-td>
      </template>
      <template v-slot:body-cell-lastActionName="props">
        <q-td style="width: 30px" :props="props">
          <div v-if="props.row.lastAction.name" class="status">
            {{ props.row.lastAction.name }}
          </div>
          <q-icon v-else name="remove"></q-icon>
        </q-td>
      </template>
      <template v-slot:body-cell-lastChangeId="props">
        <q-td :props="props">
          <q-icon v-if="props.row.lastChangeRequest.error" name="error" class="q-mr-xs" color="red" size="20px">
            <q-tooltip>{{ props.row.lastChangeRequest.error }}</q-tooltip>
          </q-icon>
          <span v-if="props.row.lastChangeRequest.number">
            <a :href="props.row.lastChangeRequest.link" target="_blank">
              {{ props.row.lastChangeRequest.number }}
            </a>
          </span>
          <q-icon name="pending_actions" size="20px" color="primary" v-else-if="props.row.lastChangeWaiting">
            <q-tooltip v-if="props.row.lastChangeRequest.number"
              >Zone is in the process of being updated. Change request will be changed</q-tooltip
            >
            <q-tooltip v-else-if="props.row.markDeleted">Zone is in the process of being deleted.</q-tooltip>
            <q-tooltip v-else>
              {{
                props.row.objStatus.toLowerCase() === 'completed'
                  ? 'Zone was created successfully but for some reason there is no Change Request information.'
                  : 'Zone is in the process of being created.'
              }}
            </q-tooltip>
          </q-icon>
          <q-icon v-else name="remove"></q-icon>
        </q-td>
      </template>
    </q-table>
  </div>
  <q-expansion-item class="comment-actions">
    <template v-slot:header>
      <strong>Action comments</strong>
    </template>
    <div v-if="loadCommentActions" class="flex items-center justify-center q-py-lg" style="padding: 30px 0">
      <q-spinner-gears size="40px" color="primary"></q-spinner-gears>
    </div>
    <div class="actions-table-holder q-mt-md" v-else-if="commentActions.length">
      <action-comments-table :comments="commentActions" />
    </div>
    <div v-else class="flex full-width column items-center q-py-lg" style="padding: 30px 0">
      <q-icon name="hide_source" class="q-mb-sm" size="40px" color="primary"></q-icon>
      <span class="block" style="font-size: 16px">No data</span>
    </div>
  </q-expansion-item>
  <q-expansion-item class="actions-item">
    <template v-slot:header>
      <strong>List of actions</strong>
    </template>
    <div v-if="loadActions" class="flex items-center justify-center q-py-lg" style="padding: 30px 0">
      <q-spinner-gears size="40px" color="primary"></q-spinner-gears>
    </div>
    <div class="actions-table-holder q-mt-md" v-else-if="actions.length">
      <actions-table @showActions="actionsDialog = true" :actions="actions" />
    </div>
    <div v-else class="flex full-width column items-center q-py-lg" style="padding: 30px 0">
      <q-icon name="hide_source" class="q-mb-sm" size="40px" color="primary"></q-icon>
      <span class="block" style="font-size: 16px">No data</span>
    </div>
  </q-expansion-item>
  <q-expansion-item class="access-products-item">
    <template v-slot:header>
      <strong>Access products</strong>
    </template>
    <div v-if="loadAccessProducts" class="flex items-center justify-center q-py-lg" style="padding: 30px 0">
      <q-spinner-gears size="40px" color="primary"></q-spinner-gears>
    </div>
    <div class="actions-table-holder q-mt-md" v-else-if="Object.keys(accessProducts).length">
      <access-products-table :accessProducts="accessProducts"></access-products-table>
    </div>
    <div v-else class="flex full-width column items-center q-py-lg" style="padding: 30px 0">
      <q-icon name="hide_source" class="q-mb-sm" size="40px" color="primary"></q-icon>
      <span class="block" style="font-size: 16px">No data</span>
    </div>
  </q-expansion-item>

  <q-dialog v-model="actionsDialog" class="single-service-page" persistent>
    <q-card style="min-width: 768px; border-radius: 0px">
      <q-toolbar class="shadow-2 q-py-sm q-px-md">
        <q-toolbar-title class="text-weight-bold">
          <strong>Actions for {{ infraService.displayName || infraService.serviceName }}</strong>
        </q-toolbar-title>
        <q-btn icon="close" round flat class="btn--no-hover" @click="actionsDialog = false"></q-btn>
      </q-toolbar>
      <q-card-section class="q-pt-none">
        <actions-table showAll :actions="actions" />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import ActionsTable from '@/pages/digitalService/components/ActionsTable';
import AccessProductsTable from '@/pages/digitalService/components/AccessProductsTable.vue';
import ActionCommentsTable from '@/pages/digitalService/components/ActionCommentsTable';

export default {
  name: 'CloudenteWidget',
  components: {
    ActionCommentsTable,
    AccessProductsTable,
    ActionsTable,
  },
  props: {
    manifest: { type: Object, default: () => ({ zones: [] }) },
    actions: { type: Array },
    infraService: { type: Object },
    accessProducts: { type: Object },
    loadManifest: { type: Boolean, default: () => false },
    loadActions: { type: Boolean, default: () => false },
    loadAccessProducts: { type: Boolean, default: () => false },
    commentActions: { type: Array },
    loadCommentActions: { type: Boolean, default: () => false },
  },
  data() {
    return {
      actionsDialog: false,
      tab: 'info',
      cols: [
        {
          label: 'Status',
          name: 'objStatus',
          field: 'objStatus',
          align: 'left',
        },
        {
          label: 'Zone Name',
          name: 'name',
          field: 'name',
          align: 'left',
        },
        {
          label: 'License',
          name: 'license',
          field: 'license',
          align: 'left',
        },
        {
          label: 'Token URL',
          name: 'tokenUrl',
          field: 'tokenUrl',
          align: 'left',
        },
        {
          label: 'DNS',
          name: 'DNS',
          field: 'DNS',
          align: 'center',
        },
        {
          label: 'Last action',
          name: 'lastActionName',
          align: 'center',
        },
        {
          label: 'Change request',
          name: 'lastChangeId',
          field: 'lastChangeId',
          align: 'center',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.cloudente-table {
  thead tr {
    height: 24px !important;
  }
}
</style>
