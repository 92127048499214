import { PublicClientApplication } from '@azure/msal-browser';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';

const apiPrefix = `${process.env.VUE_APP_API_HOST || ''}/graphql`;
const apiPrefixSchemaServer = `${process.env.VUE_APP_API_HOST_SCHEMA_SERVER || ''}/graphql`;

const clientId = process.env.VUE_APP_AAD_CLIENT_ID;
const tenantId = process.env.VUE_APP_AAD_TENANT_ID;

const request = {
  scopes: [`${clientId}/Profile.Read`],
};

const authConfig = {
  auth: {
    clientId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: apiPrefix,
});

const httpLinkSchemaServer = createHttpLink({
  // You should use an absolute URL here
  uri: apiPrefixSchemaServer,
});

// eslint-disable-next-line
let resolveHandleRedirect: any = () => {};
// eslint-disable-next-line
let rejectHandleRedirect: any = () => {};

const msalInstance = new PublicClientApplication(authConfig);

msalInstance
  .handleRedirectPromise()
  .then((response: any) => resolveHandleRedirect(response && response.account))
  .catch((error: any) => rejectHandleRedirect(error));

const waitForMsalHandleRedirect = new Promise((resolve, reject) => {
  resolveHandleRedirect = resolve;
  rejectHandleRedirect = reject;
});

async function getTokenOrRedirect() {
  const accountAfterRedirect = await waitForMsalHandleRedirect;

  // get account back from redirect or first account from MSAL
  const account = accountAfterRedirect || msalInstance.getAllAccounts().shift();

  if (!account) {
    // redirect to microsoft - code never continues after this call
    await msalInstance.acquireTokenRedirect(request);
  }

  // get token from cache or get token from microsoft with refresh token
  // or get new pair of tokens from microsoft if session is alive but tokens expired
  const tokenData = await msalInstance
    .acquireTokenSilent({
      ...request,
      account,
    })
    .catch(async () => {
      // or make user to log in
      await msalInstance.acquireTokenRedirect(request);
    });

  return tokenData.accessToken;
}

async function resolutionErrorCatcher(error: any) {
  if (error.message.includes('endpoints_resolution_error')) {
    // Never-ending promise. In that case we're waiting while msal redirects us to MS login page
    // eslint-disable-next-line
    await new Promise(() => {});
  }

  throw error;
}

// Cache implementation
const cache = new InMemoryCache({
  addTypename: false,
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await getTokenOrRedirect().catch(resolutionErrorCatcher);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

// Create the apollo client
const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  },
});

const apolloClientSchemaServer = new ApolloClient({
  link: authLink.concat(httpLinkSchemaServer),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  },
});

export default {
  apolloClient,
  apolloClientSchemaServer,
  msalInstance,
};
