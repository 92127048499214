<template>
  <q-dialog :model-value="showReassignDialog" class="single-service-page" persistent>
    <q-card style="min-width: 1280px; border-radius: 0px">
      <q-toolbar class="shadow-2 q-py-sm q-px-md">
        <q-toolbar-title class="text-weight-bold">
          <strong>Reassign {{ reassignObject.entity === 'external-dns' ? 'external DNS records' : 'zones' }}</strong>
        </q-toolbar-title>
        <q-btn icon="close" style="min-width: 20px" round flat class="btn--no-hover" @click="$emit('close')"></q-btn>
      </q-toolbar>
      <q-card-section class="q-mx-md q-px-none q-pb-none" style="border-top: 2px solid var(--q-primary)">
        <div class="row no-wrap q-col-gutter-sm">
          <div class="col">
            <div class="row no-wrap">
              <q-select
                style="width: 100%"
                :model-value="reassignObject.currentDigitalService"
                disable
                outlined
                dense
              ></q-select>
              <q-btn
                v-if="reassignObject.externalDnsZones.length"
                class="filter-icon"
                :class="{ active: showFilters }"
                icon="filter_alt"
                flat
                @click="showFilter"
              >
                <q-tooltip>{{ showFilters ? 'Hide filters' : 'Show filters' }}</q-tooltip>
              </q-btn>
            </div>
          </div>
          <div class="col new-ds-select">
            <service-select
              onlyActive
              fullWidth
              :label="reassignObject?.newDigitalService ? reassignObject.newDigitalService : 'Choose digital service'"
              @reset="$emit('resetService')"
              @addService="$emit('setService', $event)"
            />
          </div>
        </div>
        <div class="row no-wrap q-col-gutter-sm">
          <div class="col">
            <div
              v-if="reassignObject.externalDnsZones.length === 0"
              class="flex column justify-center items-center full-height"
            >
              <q-icon name="mdi-cloud-search" class="q-mb-sm" size="60px" color="primary"></q-icon>
              <span class="block" style="font-size: 14px">No items for reassigning.</span>
            </div>
            <div v-if="reassignObject.entity === 'external-dns'">
              <div class="vnets-holder" v-if="showFilters">
                <div class="row no-wrap q-col-gutter-sm" style="width: 100%">
                  <div class="col">
                    <q-input
                      v-model="dnsFilters.domain"
                      label="Domain"
                      outlined
                      dense
                      clearable
                      class="q-mb-sm"
                    ></q-input>
                  </div>
                  <div class="col">
                    <q-select
                      v-model="dnsFilters.type"
                      :options="['A', 'AAAA', 'CNAME', 'CNAME-WITH-REDIRECT', 'TXT', 'NS', 'MX', 'SRV']"
                      options-dense
                      label="Type"
                      outlined
                      dense
                      clearable
                      class="q-mb-sm"
                    ></q-select>
                  </div>
                  <div class="col">
                    <q-input v-model="dnsFilters.name" label="Name" outlined dense clearable class="q-mb-sm"></q-input>
                  </div>
                  <div class="col">
                    <q-input
                      v-model="dnsFilters.value"
                      label="Value"
                      outlined
                      dense
                      clearable
                      class="q-mb-sm"
                    ></q-input>
                  </div>
                </div>
                <q-btn
                  flat
                  round
                  size="12px"
                  icon="refresh"
                  style="width: 40px; height: 40px"
                  class="btn--no-hover"
                  @click="resetFilters"
                >
                  <q-tooltip>Reset</q-tooltip>
                </q-btn>
              </div>
              <div
                class="dns-zone flex no-wrap q-pb-sm"
                style="padding-top: 10px; border-bottom: 1px solid #c2c2c2"
                v-for="zone in showingZones"
                :key="zone.id"
              >
                <div class="row no-wrap q-col-gutter-sm">
                  <div class="col">
                    <q-input v-model="zone.domain" dense outlined label="Domain" disable></q-input>
                  </div>
                  <div class="col">
                    <q-input v-model="zone.type" dense outlined label="Type" disable></q-input>
                  </div>
                  <div class="col">
                    <q-input v-model="zone.name" dense outlined label="Name" disable></q-input>
                  </div>
                  <div class="col">
                    <q-input v-model="zone.value" dense outlined label="Value" disable></q-input>
                  </div>
                </div>
                <q-btn
                  :disable="zone.status !== 'completed' || !reassignObject.newDigitalService"
                  flat
                  style="margin-top: 4px; min-width: 40px; width: 40px; padding: 0"
                  class="q-mt-sm btn--no-hover"
                  :ripple="false"
                  icon="keyboard_double_arrow_right"
                  @click="$emit('reassignZone', zone)"
                >
                </q-btn>
              </div>
            </div>
            <div v-if="reassignObject.entity === 'zones'">
              <div class="vnets-holder" v-if="showFilters">
                <div class="row no-wrap q-col-gutter-sm" style="width: 100%">
                  <div class="col">
                    <q-input v-model="dnsFilters.name" label="Name" outlined dense clearable class="q-mb-sm"></q-input>
                  </div>
                  <div class="col">
                    <q-select
                      v-model="dnsFilters.license"
                      :options="[
                        { label: 'Free', value: 'free' },
                        { label: 'Enterprise', value: 'enterprise' },
                        { label: 'China', value: 'china' },
                      ]"
                      emit-value
                      map-options
                      options-dense
                      label="License"
                      outlined
                      dense
                      clearable
                      class="q-mb-sm"
                    ></q-select>
                  </div>
                  <div class="col">
                    <q-select
                      v-model="dnsFilters.DNS"
                      :options="[
                        { label: 'DNS enabled', value: 'enabled' },
                        { label: 'DNS disabled', value: 'disabled' },
                      ]"
                      emit-value
                      map-options
                      options-dense
                      label="DNS"
                      outlined
                      dense
                      clearable
                      class="q-mb-sm"
                    ></q-select>
                  </div>
                </div>
                <q-btn
                  flat
                  round
                  size="12px"
                  icon="refresh"
                  style="width: 40px; height: 40px"
                  class="btn--no-hover"
                  @click="resetFilters"
                >
                  <q-tooltip>Reset</q-tooltip>
                </q-btn>
              </div>
              <div
                class="dns-zone flex no-wrap q-pb-sm"
                style="padding-top: 10px; border-bottom: 1px solid #c2c2c2"
                v-for="zone in showingZones"
                :key="zone.id"
              >
                <div class="row no-wrap q-col-gutter-sm" style="width: 100%">
                  <div class="col">
                    <q-input v-model="zone.name" dense outlined label="Name" disable></q-input>
                  </div>
                  <div class="col">
                    <q-input v-model="zone.license" dense outlined label="License" disable></q-input>
                  </div>
                  <div class="col-2">
                    <q-checkbox v-model="zone.DNS" style="margin-top: 10px" dense disable label="DNS" />
                  </div>
                </div>
                <q-btn
                  :disable="zone.status !== 'completed' || !reassignObject.newDigitalService"
                  flat
                  style="margin-top: 0; min-width: 40px; width: 40px; padding: 0"
                  class="q-mt-sm btn--no-hover"
                  :ripple="false"
                  icon="keyboard_double_arrow_right"
                  @click="$emit('reassignZone', zone)"
                >
                </q-btn>
              </div>
            </div>
            <div
              v-if="reassignObject?.externalDnsZones.length"
              class="flex items-center justify-end q-pt-sm q-mr-sm"
              style="font-size: 12px"
            >
              {{ page === 1 ? '1' : (page - 1) * showZonesCount }} -
              {{ page * showZonesCount > filteredDnsZones.length ? filteredDnsZones.length : page * showZonesCount }} of
              {{ filteredDnsZones.length }}
              <q-btn
                class="q-px-xs q-ml-md"
                icon="first_page"
                size="10px"
                flat
                :ripple="false"
                :disable="page === 1"
                @click="page = 1"
              ></q-btn>
              <q-btn
                class="q-px-xs"
                icon="navigate_before"
                size="10px"
                flat
                :ripple="false"
                :disable="page === 1"
                @click="page -= 1"
              ></q-btn>
              <q-btn
                class="q-px-xs"
                icon="navigate_next"
                size="10px"
                flat
                :ripple="false"
                :disable="page * showZonesCount >= filteredDnsZones.length"
                @click="page += 1"
              ></q-btn>
              <q-btn
                class="q-px-xs"
                icon="last_page"
                size="10px"
                flat
                :ripple="false"
                :disable="page * showZonesCount >= filteredDnsZones.length"
                @click="page = Math.ceil(filteredDnsZones.length / showZonesCount)"
              ></q-btn>
            </div>
          </div>
          <div class="col">
            <q-scroll-area
              :thumb-style="{
                borderRadius: '5px',
                width: '3px',
                background: '#9f9f9f',
                height: '3px',
                zIndex: 999999,
                opacity: 1,
              }"
              style="height: 295px"
            >
              <div v-if="reassignObject.entity === 'external-dns'">
                <div
                  class="dns-zone flex no-wrap q-pb-sm"
                  style="padding-top: 10px; border-bottom: 1px solid #c2c2c2"
                  v-for="zone in reassignObject.reassignedZones"
                  :key="zone.id"
                >
                  <q-btn
                    :disable="zone.status !== 'completed' || !reassignObject.newDigitalService"
                    flat
                    style="margin-top: 4px; min-width: 40px; width: 40px; padding: 0"
                    class="q-mt-sm btn--no-hover"
                    :ripple="false"
                    icon="keyboard_double_arrow_left"
                    @click="$emit('discardReassign', zone)"
                  >
                  </q-btn>
                  <div class="row no-wrap q-col-gutter-sm">
                    <div class="col">
                      <q-input v-model="zone.domain" dense outlined label="Domain" disable></q-input>
                    </div>
                    <div class="col">
                      <q-input v-model="zone.type" dense outlined label="Type" disable></q-input>
                    </div>
                    <div class="col">
                      <q-input v-model="zone.name" dense outlined label="Name" disable></q-input>
                    </div>
                    <div class="col">
                      <q-input v-model="zone.value" dense outlined label="Value" disable></q-input>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="reassignObject.entity === 'zones'">
                <div
                  class="dns-zone flex no-wrap q-pb-sm"
                  style="padding-top: 10px; border-bottom: 1px solid #c2c2c2"
                  v-for="zone in reassignObject.reassignedZones"
                  :key="zone.id"
                >
                  <q-btn
                    :disable="zone.status !== 'completed' || !reassignObject.newDigitalService"
                    flat
                    style="margin-top: 4px; min-width: 40px; width: 40px; padding: 0"
                    class="q-mt-sm btn--no-hover"
                    :ripple="false"
                    icon="keyboard_double_arrow_left"
                    @click="$emit('discardReassign', zone)"
                  >
                  </q-btn>
                  <div class="row no-wrap q-col-gutter-sm">
                    <div class="col">
                      <q-input v-model="zone.name" dense outlined label="Name" disable></q-input>
                    </div>
                    <div class="col">
                      <q-input v-model="zone.license" dense outlined label="License" disable></q-input>
                    </div>
                    <div class="col-2">
                      <q-checkbox v-model="zone.DNS" style="margin-top: 10px" dense disable label="DNS" />
                    </div>
                  </div>
                </div>
              </div>
            </q-scroll-area>
          </div>
        </div>
      </q-card-section>
      <q-card-actions class="q-px-md q-py-lg">
        <q-btn
          style="border-radius: 10px; text-transform: capitalize"
          :disable="!reassignObject.newDigitalService || !reassignObject.reassignedZones.length || loading"
          color="primary"
          text-color="text-color"
          :loading="loading"
          @click="send"
        >
          Submit
        </q-btn>
        <q-btn
          style="border-radius: 10px; text-transform: capitalize"
          class="btn--no-hover"
          flat
          @click="$emit('close')"
          >Close</q-btn
        >
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import ServiceSelect from '@/shared/components/ServiceSelect';

export default {
  name: 'ReassignExternalDnsDialog',
  components: {
    ServiceSelect,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    showReassignDialog: {
      type: Boolean,
      default: false,
    },
    reassignObject: {
      type: Object,
      default: () => ({
        currentDigitalService: undefined,
        newDigitalService: undefined,
        externalDnsZones: [],
        reassignedZones: [],
        entity: undefined,
        platform: undefined,
      }),
    },
  },
  data() {
    return {
      page: 1,
      showZonesCount: 5,
      showFilters: false,
      dnsFilters: {
        name: undefined,
        type: undefined,
        value: undefined,
        domain: undefined,
        license: undefined,
        DNS: undefined,
      },
    };
  },
  methods: {
    resetFilters() {
      this.dnsFilters.name = undefined;
      this.dnsFilters.domain = undefined;
      this.dnsFilters.value = undefined;
      this.dnsFilters.type = undefined;
      this.dnsFilters.license = undefined;
      this.dnsFilters.DNS = undefined;
    },
    showFilter() {
      this.showFilters = !this.showFilters;
    },
    send() {
      const data = {
        currentDigitalService: this.reassignObject.currentDigitalService,
        newDigitalService: this.reassignObject.newDigitalService,
        entity: this.reassignObject.entity,
        platform: this.reassignObject.platform,
        ids: this.reassignObject.reassignedZones.map((zone) => zone.id),
      };
      this.$emit('submit', data);
    },
  },
  computed: {
    filteredDnsZones() {
      if (this.reassignObject.externalDnsZones.length) {
        return Object.values(this.reassignObject.externalDnsZones).filter((zone) => {
          // eslint-disable-next-line no-restricted-syntax
          for (const prop in this.dnsFilters) {
            if (this.dnsFilters[prop]) {
              if (prop === 'type') {
                if (this.dnsFilters.type !== zone.type) {
                  return false;
                }
              } else if (prop === 'DNS') {
                if (this.dnsFilters.DNS === 'disabled') {
                  if (zone.DNS) {
                    return false;
                  }
                }
                if (this.dnsFilters.DNS === 'enabled') {
                  if (!zone.DNS) {
                    return false;
                  }
                }
              } else {
                if (!new RegExp(this.dnsFilters[prop], 'i').test(zone[prop])) {
                  return false;
                }
              }
            }
          }
          return true;
        });
      } else {
        return [];
      }
    },
    showingZones() {
      return JSON.parse(JSON.stringify(this.filteredDnsZones)).splice(
        (this.page - 1) * this.showZonesCount,
        this.showZonesCount,
      );
    },
  },
};
</script>

<style scoped lang="scss">
.vnets-holder {
  display: flex;
  border-bottom: 1px solid #c6c9cc;
  padding: 10px 0 3px 0;
}
.filter-icon {
  padding: 0 7px;
}
</style>
