import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dispatch_renderer = _resolveComponent("dispatch-renderer")!
  const _component_additional_properties = _resolveComponent("additional-properties")!

  return (_ctx.control.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_dispatch_renderer, {
          visible: _ctx.control.visible,
          enabled: _ctx.control.enabled,
          schema: _ctx.control.schema,
          uischema: _ctx.detailUiSchema,
          path: _ctx.control.path,
          renderers: _ctx.control.renderers,
          cells: _ctx.control.cells,
          disableUpdateOpass: _ctx.disableUpdateOpass
        }, null, 8, ["visible", "enabled", "schema", "uischema", "path", "renderers", "cells", "disableUpdateOpass"]),
        (_ctx.hasAdditionalProperties && _ctx.showAdditionalProperties)
          ? (_openBlock(), _createBlock(_component_additional_properties, {
              key: 0,
              input: _ctx.input
            }, null, 8, ["input"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}