import apollo from '@/library/http/apollo';
import gql from 'graphql-tag';
import { buildCatalogServiceQuery } from '@/shared/query-builder';
import { SERVICE_DESIGN_ACTIONS } from '@/store/modules/service-design';

export const SERVICES_GETTERS = {
  ALL: 'services',
  CATEGORIES: 'categories',
  WIZARD_CATEGORIES: 'wizardCategories',
};

export const SERVICES_ACTIONS = {
  GET_SERVICES: 'getServices',
};

const SERVICES_MUTATIONS = {
  SET_SERVICES: 'setServices',
  SET_WIZARD_CATEGORIES: 'setWizardCategories',
};

export default {
  namespaced: true,
  state: {
    catalogServices: [],
    wizardCategories: [],
  },
  getters: {
    [SERVICES_GETTERS.ALL]: (state: any) => {
      return state.catalogServices;
    },
    [SERVICES_GETTERS.CATEGORIES]: (state: any) =>
      state.catalogServices.map((category: any) => ({ name: category.name, apmId: category.categoryId })),
    [SERVICES_GETTERS.WIZARD_CATEGORIES]: (state: any) => state.wizardCategories,
  },
  mutations: {
    [SERVICES_MUTATIONS.SET_SERVICES]: (state: any, categories: any) => {
      state.catalogServices = categories;
    },
    [SERVICES_MUTATIONS.SET_WIZARD_CATEGORIES]: (state: any, categories: any) => {
      state.wizardCategories = categories;
    },
  },
  actions: {
    [SERVICES_ACTIONS.GET_SERVICES]: async (context: any, filters: any) => {
      await apollo.apolloClient
        .query({
          query: gql`
            query {
              getSupportingWizardCategories
            }
          `,
        })
        .then((response) => {
          const categories = response.data.getSupportingWizardCategories;
          context.commit(SERVICES_MUTATIONS.SET_WIZARD_CATEGORIES, categories);
        });
      const filterQuery = filters ? buildCatalogServiceQuery(filters) : buildCatalogServiceQuery({});

      await apollo.apolloClient
        .query({
          query: gql(filterQuery),
        })
        .then((response) => {
          const categories = response.data.actualCatalogServices.serviceCategories;
          context.commit(SERVICES_MUTATIONS.SET_SERVICES, categories);
          context.commit(`serviceDesign/${SERVICE_DESIGN_ACTIONS.SET_SERVICES}`, categories, { root: true });
        });
    },
  },
};
