<template>
  <q-page v-if="adr" class="main-wrapper-page single-page" style="background-color: var(--q-accent)">
    <div class="row q-col-gutter-lg no-wrap adr-sidebar">
      <div class="col col-3">
        <p><b>Category:</b> {{ adr.category }}</p>
        <p><b>Scope:</b> For {{ adr.scope.units }}, {{ adr.scope.regions }}</p>
        <p><b>Status:</b> {{ adr.status }}</p>
        <p><b>Valid date:</b> {{ adr.validDate }}</p>
        <p><b>Version:</b> {{ adr.version }}</p>
        <p v-if="adr.owners?.length"><b>Owners:</b> {{ adr.owners.map((owner) => owner.name).join(', ') }}</p>
        <p v-if="adr.exceptions?.length">
          <b>Exceptions:</b>
          <a v-for="(exception, key) in adr.exceptions" style="display: block" :key="key" :href="exception.url">{{
            exception.id
          }}</a>
        </p>
      </div>
      <div class="col col-9">
        <q-scroll-area
          :thumb-style="{
            borderRadius: '5px',
            width: '3px',
            background: '#9f9f9f',
            height: '3px',
            zIndex: 999999,
            opacity: 1,
          }"
          :style="`height: calc(100vh - ${currentBanner ? '190px' : '140px'})`"
        >
          <div class="single-page-markdown q-pr-md" v-html="content"></div>
        </q-scroll-area>
      </div>
    </div>
  </q-page>
</template>

<script>
import apollo from '@/library/http/apollo';
import gql from 'graphql-tag';
import { marked } from 'marked';
import { mapGetters } from 'vuex';
import { BANNERS_GETTERS } from '@/store/modules/banners';

export default {
  name: 'AdrPage',
  data() {
    return {
      adr: undefined,
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    await this.getAdrData(id);
  },
  computed: {
    ...mapGetters('banners', {
      currentBanner: BANNERS_GETTERS.BANNERS_SHOW,
    }),
    content() {
      return marked(this.adr.content);
    },
  },
  methods: {
    async getAdrData(id) {
      this.$showLoading();
      try {
        await apollo.apolloClient
          .query({
            query: gql`
            query {
              getADRs(filter: {filterBy: {adrId: "${id}"}, includeFields: {services: true, owners: true}}) {
                adrs {
                  category
                  id
                  created_at
                  name
                  scope
                  status
                  validDate
                  version
                  content
                  exceptions
                  owners {
                    name
                    email
                  }
                }
              }
            }
          `,
          })
          .then(async (response) => {
            this.adr = response.data.getADRs.adrs[0];
          });
      } catch (error) {
        this.$notify('negative', 'Error', error.message);
      } finally {
        this.$hideLoading();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.adr-sidebar {
  p {
    margin-bottom: 5px;
  }
}
</style>
