<template>
  <q-page class="main-wrapper-page single-page" style="background-color: var(--q-accent)">
    <div class="digital-services-holder q-px-md q-py-md bg-white" style="margin-bottom: 50px">
      <div class="table-header flex items-center justify-between">
        <h5>My Digital Services</h5>
        <q-btn
          class="filter-icon btn--no-hover"
          :class="{ active: showMyAppsFilters }"
          icon="filter_alt"
          flat
          @click="showMyAppsFilter"
        >
          <q-tooltip>{{ showMyAppsFilters ? 'Hide filters' : 'Show filters' }}</q-tooltip>
        </q-btn>
      </div>
      <div v-if="showMyAppsFilters" class="flex no-wrap">
        <div class="row no-wrap q-col-gutter-xs full-width">
          <div class="col">
            <q-input
              @keyup.enter="$refs.myApplications.requestServerInteraction()"
              @clear="$refs.myApplications.requestServerInteraction()"
              v-model="myAppsFilters.name"
              clearable
              dense
              outlined
              label="Name"
            ></q-input>
          </div>
          <div class="col">
            <q-input
              @keyup.enter="$refs.myApplications.requestServerInteraction()"
              @clear="$refs.myApplications.requestServerInteraction()"
              v-model="myAppsFilters.apmId"
              clearable
              dense
              outlined
              label="APMID"
            ></q-input>
          </div>
          <div class="col">
            <q-select
              @update:model-value="$refs.myApplications.requestServerInteraction()"
              @clear="$refs.myApplications.requestServerInteraction()"
              v-model="myAppsFilters.active"
              clearable
              dense
              outlined
              label="State"
              options-dense
              map-options
              emit-value
              :options="[
                { label: 'Active', value: true },
                { label: 'Inactive', value: false },
              ]"
            ></q-select>
          </div>
          <div class="col">
            <q-select
              @update:model-value="$refs.myApplications.requestServerInteraction()"
              @clear="$refs.myApplications.requestServerInteraction()"
              v-model="myAppsFilters.businessDomain"
              clearable
              dense
              outlined
              label="Business Domain"
              options-dense
              map-options
              emit-value
              :options="$options.BUSINESS_DOMAINS"
            ></q-select>
          </div>
          <div class="col">
            <q-select
              @update:model-value="$refs.myApplications.requestServerInteraction()"
              @clear="$refs.myApplications.requestServerInteraction()"
              v-model="myAppsFilters.itscmCriticality"
              clearable
              dense
              outlined
              options-dense
              map-options
              emit-value
              label="ITSCM Criticality"
              :options="['0 - Highly Critical', '1 - Critical', '2 - High', '3 - Medium', '4 - Low']"
            ></q-select>
          </div>
        </div>
        <q-btn
          class="filter-icon btn--no-hover"
          icon="search"
          flat
          @click="$refs.myApplications.requestServerInteraction()"
        ></q-btn>
      </div>
      <q-table
        :rows="myApps"
        @request="getOwnedApps"
        v-model:pagination="myAppsPagination"
        :columns="columns"
        row-key="name"
        flat
        class="digital-service-table"
        ref="myApplications"
        :loading="myAppsLoading"
      >
        <template v-slot:no-data>
          <div class="flex full-width column items-center q-py-lg">
            <q-icon name="hide_source" class="q-mb-sm" size="60px" color="primary"></q-icon>
            <span class="block" style="font-size: 14px">No Digital Services.</span>
          </div>
        </template>
        <template v-slot:header-cell-doraScore="props">
          <q-th :props="props" class="text-left sortable">
            dora score
            <q-icon push @click.stop name="info" size="xs">
              <q-popup-proxy>
                <q-banner class="dora-legend" style="width: 400px">
                  <div class="dora-legend-items">
                    <div>
                      <b>Deployment Frequency</b>
                      <ul>
                        <li>{{ '< Quarterly = 1 = LOW' }}</li>
                        <li>{{ '< Monthly = 2 = MEDIUM' }}</li>
                        <li>{{ '< Weekly = 3 = HIGH' }}</li>
                        <li>{{ '< Daily = 4 = ELITE' }}</li>
                      </ul>
                    </div>
                    <div>
                      <b>Lead Time for Changes</b>
                      <ul>
                        <li>{{ '< 1 Quarter = 1 = LOW' }}</li>
                        <li>{{ '< 1 Month = 2 = MEDIUM' }}</li>
                        <li>{{ '< 1 Week = 3 = HIGH' }}</li>
                        <li>{{ '< 1 Day = 4 = ELITE' }}</li>
                      </ul>
                    </div>
                    <div>
                      <b>Time to Restore Service</b>
                      <ul>
                        <li>{{ '> 1 Week = 1 = LOW' }}</li>
                        <li>{{ '< 1 Week = 2 = MEDIUM' }}</li>
                        <li>{{ '< 1 Day = 3 = HIGH' }}</li>
                        <li>{{ '< 1 Hour = 4 = ELITE' }}</li>
                      </ul>
                    </div>
                    <div>
                      <b>Change Failure Rate</b>
                      <ul>
                        <li>{{ '> 25% = 1 = LOW' }}</li>
                        <li>{{ '< 25% = 2 = MEDIUM' }}</li>
                        <li>{{ '< 15% = 3 = HIGH' }}</li>
                        <li>{{ '< 5% = 4 = ELITE' }}</li>
                      </ul>
                    </div>
                  </div>
                  <p class="q-mb-none">
                    The acronym, DORA stands for The DevOps Research and Assessment Team. Within a seven-year program ,
                    this Google research group analyzed DevOps practices and capabilities and has been able to identity
                    four key metrics to measure software development and delivery deployments.
                  </p>
                </q-banner>
              </q-popup-proxy>
            </q-icon>
          </q-th>
        </template>
        <template v-slot:header-cell-kpi="props">
          <q-th :props="props" class="text-left sortable">
            Cost (USD) w/o VAT
            <q-icon push @click.stop name="info" size="xs">
              <q-popup-proxy>
                <q-banner class="dora-legend" style="width: 400px">
                  <span>
                    The amount (USD) is the accumulation of the application costs in the past calendar month. The trend
                    indicator (arrow) shows the increase or decrease of the cost in comparison to the month preceding
                    this accumulated number.
                  </span>
                </q-banner>
              </q-popup-proxy>
            </q-icon>
          </q-th>
        </template>
        <template v-slot:body="props">
          <q-tr :props="props" @click="goToApplication(props.row.apmId)">
            <q-td key="digitalServiceActive" class="items-center" :props="props">
              <span class="application-state" :class="props.row.digitalServiceActive ? 'active' : 'inactive'">
                <q-tooltip>{{ props.row.digitalServiceActive ? 'active' : 'inactive' }}</q-tooltip>
              </span>
            </q-td>
            <q-td key="name" :props="props">
              {{ props.row.name }}
            </q-td>
            <q-td key="apmId" :props="props">
              {{ props.row.apmId }}
            </q-td>
            <q-td key="itOwner" :props="props">
              {{ props.row.itOwner }}
            </q-td>
            <q-td v-if="showQualitySensorIssues" class="quality-service success" key="qualitySensorOpen" :props="props">
              <a @click.stop :href="`${QUALITY_SENSORS_BASE_URL}/#/issues/open`" target="_blank">{{
                props.row.qualitySensorIssues?.numberOfOpen
              }}</a>
            </q-td>
            <q-td
              v-if="showQualitySensorIssues"
              class="quality-service"
              :class="props.row.qualitySensorIssues?.numberOfOverdue > 0 ? 'danger' : 'success'"
              key="qualitySensorOverdue"
              :props="props"
            >
              <a @click.stop :href="`${QUALITY_SENSORS_BASE_URL}/#/issues/open`" target="_blank">{{
                props.row.qualitySensorIssues?.numberOfOverdue
              }}</a>
            </q-td>
            <q-td key="majorIncidents" :props="props">
              <span @click.stop="showMajorIncidents(props.row.majorIncidents)" style="color: red">{{
                props.row.majorIncidents?.length || ''
              }}</span>
            </q-td>
            <q-td key="services" :props="props">
              <infra-service
                v-for="service in props.row.services.slice(0, 2)"
                :key="service.id"
                :data-service="service"
              >
                <template #status>
                  <span class="status" :class="service.status.toLowerCase()"
                    ><q-tooltip>{{ service.status.toLowerCase() }}</q-tooltip></span
                  >
                </template>
              </infra-service>
              <q-btn
                v-if="props.row.services.slice(2).length"
                @click.stop
                flat
                size="md"
                color="indigo"
                class="q-px-none q-py-none q-mr-sm btn--no-hover"
                :ripple="false"
              >
                +{{ props.row.services.length - 2 }}
                <q-menu self="top right" max-width="480px" class="infra-services-holder">
                  <q-banner>
                    <infra-service
                      v-for="service in props.row.services.slice(2)"
                      :key="service.id"
                      :data-service="service"
                    >
                      <template #status>
                        <span class="status" :class="service.status.toLowerCase()"
                          ><q-tooltip>{{ service.status.toLowerCase() }}</q-tooltip></span
                        >
                      </template>
                    </infra-service>
                  </q-banner>
                </q-menu>
              </q-btn>
            </q-td>
            <q-td key="kpi" :props="props">
              <span v-if="props.row.kpi && props.row.kpi.length">{{
                showKpi(props.row.kpi).total.cost.toFixed(2)
              }}</span>
              <span v-else>n/a</span>
              <q-icon
                v-if="showKpi(props.row.kpi).direction"
                :name="showKpi(props.row.kpi).direction"
                :color="showKpi(props.row.kpi).color"
                class="q-ml-xs"
              ></q-icon>
            </q-td>
            <q-td key="doraScore" :props="props">
              <span
                @click.stop="goToDoraPage(props.row.apmId)"
                style="font-weight: bold; cursor: pointer"
                :style="{
                  color:
                    props.row.doraScore === 'HIGH'
                      ? '#4caf50'
                      : props.row.doraScore === 'MEDIUM'
                      ? '#ffec3a'
                      : props.row.doraScore === 'LOW'
                      ? '#f44436'
                      : '#35454a',
                }"
                >{{ props.row.doraScore }}</span
              >
            </q-td>
            <q-td key="actions" :props="props" @click.stop>
              <q-btn
                class="q-px-none q-py-none btn--no-hover"
                :ripple="false"
                icon="mdi-dots-vertical"
                flat
                size="md"
                rounded
              >
                <q-menu anchor="bottom middle">
                  <q-list dense>
                    <q-item clickable v-close-popup @click="createBanner(props.row)">
                      <q-item-section avatar style="min-width: 25px">
                        <q-icon name="mdi-message-alert-outline" size="xs" />
                      </q-item-section>
                      <q-item-section>Set banner</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
    <div class="digital-services-holder q-px-md q-py-md bg-white">
      <div class="table-header flex items-center justify-between">
        <h5>Followed Digital Services</h5>
        <q-btn
          class="filter-icon btn--no-hover"
          :class="{ active: showFollowedAppsFilters }"
          icon="filter_alt"
          flat
          @click="showFollowedAppsFilter"
        >
          <q-tooltip>{{ showFollowedAppsFilters ? 'Hide filters' : 'Show filters' }}</q-tooltip>
        </q-btn>
      </div>
      <div v-if="showFollowedAppsFilters" class="flex no-wrap">
        <div class="row no-wrap q-col-gutter-xs full-width">
          <div class="col">
            <q-input
              @keyup.enter="$refs.followedApplications.requestServerInteraction()"
              @clear="$refs.followedApplications.requestServerInteraction()"
              v-model="followedAppsFilters.name"
              clearable
              dense
              outlined
              label="Name"
            ></q-input>
          </div>
          <div class="col">
            <q-input
              @keyup.enter="$refs.followedApplications.requestServerInteraction()"
              @clear="$refs.followedApplications.requestServerInteraction()"
              v-model="followedAppsFilters.apmId"
              clearable
              dense
              outlined
              label="APMID"
            ></q-input>
          </div>
          <div class="col">
            <q-select
              @update:model-value="$refs.followedApplications.requestServerInteraction()"
              @clear="$refs.followedApplications.requestServerInteraction()"
              v-model="followedAppsFilters.active"
              clearable
              dense
              outlined
              label="State"
              options-dense
              map-options
              emit-value
              :options="[
                { label: 'Active', value: true },
                { label: 'Inactive', value: false },
              ]"
            ></q-select>
          </div>
          <div class="col">
            <q-select
              @update:model-value="$refs.followedApplications.requestServerInteraction()"
              @clear="$refs.followedApplications.requestServerInteraction()"
              v-model="followedAppsFilters.businessDomain"
              clearable
              dense
              outlined
              label="Business Domain"
              options-dense
              map-options
              emit-value
              :options="$options.BUSINESS_DOMAINS"
            ></q-select>
          </div>
          <div class="col">
            <q-select
              @update:model-value="$refs.followedApplications.requestServerInteraction()"
              @clear="$refs.followedApplications.requestServerInteraction()"
              v-model="followedAppsFilters.itscmCriticality"
              clearable
              dense
              outlined
              options-dense
              map-options
              emit-value
              label="ITSCM Criticality"
              :options="['0 - Highly Critical', '1 - Critical', '2 - High', '3 - Medium', '4 - Low']"
            ></q-select>
          </div>
        </div>
        <q-btn
          class="filter-icon btn--no-hover"
          icon="search"
          flat
          @click="$refs.followedApplications.requestServerInteraction()"
        ></q-btn>
      </div>
      <q-table
        :rows="followedApps"
        @request="getFollowedApps"
        v-model:pagination="followedAppsPagination"
        :columns="columns"
        row-key="name"
        flat
        class="digital-service-table"
        ref="followedApplications"
        :loading="followedAppsLoading"
      >
        <template v-slot:no-data>
          <div class="flex full-width column items-center q-py-lg">
            <q-icon name="hide_source" class="q-mb-sm" size="60px" color="primary"></q-icon>
            <span class="block" style="font-size: 14px">No Digital Services.</span>
          </div>
        </template>
        <template v-slot:header-cell-doraScore="props">
          <q-th :props="props" class="text-left sortable">
            dora score
            <q-icon push @click.stop name="info" size="xs">
              <q-popup-proxy>
                <q-banner class="dora-legend" style="width: 400px">
                  <div class="dora-legend-items">
                    <div>
                      <b>Deployment Frequency</b>
                      <ul>
                        <li>{{ '< Quarterly = 1 = LOW' }}</li>
                        <li>{{ '< Monthly = 2 = MEDIUM' }}</li>
                        <li>{{ '< Weekly = 3 = HIGH' }}</li>
                        <li>{{ '< Daily = 4 = ELITE' }}</li>
                      </ul>
                    </div>
                    <div>
                      <b>Lead Time for Changes</b>
                      <ul>
                        <li>{{ '< 1 Quarter = 1 = LOW' }}</li>
                        <li>{{ '< 1 Month = 2 = MEDIUM' }}</li>
                        <li>{{ '< 1 Week = 3 = HIGH' }}</li>
                        <li>{{ '< 1 Day = 4 = ELITE' }}</li>
                      </ul>
                    </div>
                    <div>
                      <b>Time to Restore Service</b>
                      <ul>
                        <li>{{ '> 1 Week = 1 = LOW' }}</li>
                        <li>{{ '< 1 Week = 2 = MEDIUM' }}</li>
                        <li>{{ '< 1 Day = 3 = HIGH' }}</li>
                        <li>{{ '< 1 Hour = 4 = ELITE' }}</li>
                      </ul>
                    </div>
                    <div>
                      <b>Change Failure Rate</b>
                      <ul>
                        <li>{{ '> 25% = 1 = LOW' }}</li>
                        <li>{{ '< 25% = 2 = MEDIUM' }}</li>
                        <li>{{ '< 15% = 3 = HIGH' }}</li>
                        <li>{{ '< 5% = 4 = ELITE' }}</li>
                      </ul>
                    </div>
                  </div>
                  <p class="q-mb-none">
                    The acronym, DORA stands for The DevOps Research and Assessment Team. Within a seven-year program ,
                    this Google research group analyzed DevOps practices and capabilities and has been able to identity
                    four key metrics to measure software development and delivery deployments.
                  </p>
                </q-banner>
              </q-popup-proxy>
            </q-icon>
          </q-th>
        </template>
        <template v-slot:header-cell-kpi="props">
          <q-th :props="props" class="text-left sortable">
            Cost (USD) w/o VAT
            <q-icon push @click.stop name="info" size="xs">
              <q-popup-proxy>
                <q-banner class="dora-legend" style="width: 400px">
                  <span>
                    The amount (USD) is the accumulation of the application costs in the past calendar month. The trend
                    indicator (arrow) shows the increase or decrease of the cost in comparison to the month preceding
                    this accumulated number.
                  </span>
                </q-banner>
              </q-popup-proxy>
            </q-icon>
          </q-th>
        </template>
        <template v-slot:body="props">
          <q-tr :props="props" @click="goToApplication(props.row.apmId)">
            <q-td key="digitalServiceActive" class="items-center" :props="props">
              <span class="application-state" :class="props.row.digitalServiceActive ? 'active' : 'inactive'">
                <q-tooltip>{{ props.row.digitalServiceActive ? 'active' : 'inactive' }}</q-tooltip>
              </span>
            </q-td>
            <q-td key="name" :props="props">
              {{ props.row.name }}
            </q-td>
            <q-td key="apmId" :props="props">
              {{ props.row.apmId }}
            </q-td>
            <q-td key="itOwner" :props="props">
              {{ props.row.itOwner }}
            </q-td>
            <q-td v-if="showQualitySensorIssues" class="quality-service success" key="qualitySensorOpen" :props="props">
              <a @click.stop :href="`${QUALITY_SENSORS_BASE_URL}/#/issues/open`" target="_blank">{{
                props.row.qualitySensorIssues?.numberOfOpen
              }}</a>
            </q-td>
            <q-td
              v-if="showQualitySensorIssues"
              class="quality-service"
              :class="props.row.qualitySensorIssues?.numberOfOverdue > 0 ? 'danger' : 'success'"
              key="qualitySensorOverdue"
              :props="props"
            >
              <a @click.stop :href="`${QUALITY_SENSORS_BASE_URL}/#/issues/open`" target="_blank">{{
                props.row.qualitySensorIssues?.numberOfOverdue
              }}</a>
            </q-td>
            <q-td key="majorIncidents" :props="props">
              <span @click.stop="showMajorIncidents(props.row.majorIncidents)" style="color: red">{{
                props.row.majorIncidents?.length || ''
              }}</span>
            </q-td>
            <q-td key="services" :props="props">
              <infra-service
                v-for="service in props.row.services.slice(0, 2)"
                :key="service.id"
                :data-service="service"
              >
                <template #status>
                  <span class="status" :class="service.status.toLowerCase()"
                    ><q-tooltip>{{ service.status.toLowerCase() }}</q-tooltip></span
                  >
                </template>
              </infra-service>
              <q-btn
                v-if="props.row.services.slice(2).length"
                @click.stop
                flat
                size="md"
                color="indigo"
                class="q-px-none q-py-none q-mr-sm btn--no-hover"
                :ripple="false"
              >
                +{{ props.row.services.length - 2 }}
                <q-menu self="top right" max-width="480px" class="infra-services-holder">
                  <q-banner>
                    <infra-service
                      v-for="service in props.row.services.slice(2)"
                      :key="service.id"
                      :data-service="service"
                    >
                      <template #status>
                        <span class="status" :class="service.status.toLowerCase()"
                          ><q-tooltip>{{ service.status.toLowerCase() }}</q-tooltip></span
                        >
                      </template>
                    </infra-service>
                  </q-banner>
                </q-menu>
              </q-btn>
            </q-td>
            <q-td key="kpi" :props="props">
              <span v-if="props.row.kpi && props.row.kpi.length">{{
                showKpi(props.row.kpi).total.cost.toFixed(2)
              }}</span>
              <span v-else>n/a</span>
              <q-icon
                v-if="showKpi(props.row.kpi).direction"
                :name="showKpi(props.row.kpi).direction"
                :color="showKpi(props.row.kpi).color"
                class="q-ml-xs"
              ></q-icon>
            </q-td>
            <q-td key="doraScore" :props="props">
              <span
                @click.stop="goToDoraPage(props.row.apmId)"
                style="font-weight: bold; cursor: pointer"
                :style="{
                  color:
                    props.row.doraScore === 'HIGH'
                      ? '#4caf50'
                      : props.row.doraScore === 'MEDIUM'
                      ? '#ffec3a'
                      : props.row.doraScore === 'LOW'
                      ? '#f44436'
                      : '#35454a',
                }"
                >{{ props.row.doraScore }}</span
              >
            </q-td>
            <q-td key="actions" :props="props" @click.stop>
              <q-btn
                class="q-px-none q-py-none btn--no-hover"
                :ripple="false"
                icon="mdi-dots-vertical"
                flat
                size="md"
                rounded
              >
                <q-menu anchor="bottom middle">
                  <q-list dense>
                    <q-item clickable v-close-popup @click="deleteApplication(props.row)">
                      <q-item-section avatar style="min-width: 25px">
                        <q-icon name="delete_outline" size="xs" />
                      </q-item-section>
                      <q-item-section>Remove from this view</q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup @click="createBanner(props.row)">
                      <q-item-section avatar style="min-width: 25px">
                        <q-icon name="mdi-message-alert-outline" size="xs" />
                      </q-item-section>
                      <q-item-section>Set banner</q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
  </q-page>
  <q-dialog v-model="showIncidentsDialog" class="single-service-page" persistent>
    <q-card style="min-width: 1024px; border-radius: 0px">
      <q-toolbar class="shadow-2 q-py-sm q-px-md">
        <q-toolbar-title class="text-weight-bold">
          <strong>Major Incidents</strong>
        </q-toolbar-title>
        <q-btn icon="close" round flat class="btn--no-hover" @click="showIncidentsDialog = false"></q-btn>
      </q-toolbar>
      <q-card-section class="q-pt-none">
        <MajorIncidentsTable :incidents="majorIncidents" />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import emitter from 'tiny-emitter/instance';
import apollo from '@/library/http/apollo';
import dayjs from 'dayjs';
import gql from 'graphql-tag';
import InfraService from './components/InfraService';
import { mapActions } from 'vuex';
import { DIGITAL_SERVICES_ACTIONS } from '@/store/modules/digital-services';
import { BUSINESS_DOMAINS } from '@/constatns';
import * as uiConfig from '@/shared/ui-config.json';
import MajorIncidentsTable from './components/MajorIncidentsTable';

export default {
  name: 'DigitalServices',
  components: { InfraService, MajorIncidentsTable },
  BUSINESS_DOMAINS,
  data() {
    return {
      myAppsFilters: {
        name: undefined,
        apmId: undefined,
        active: true,
        businessDomain: undefined,
        itscmCriticality: undefined,
      },
      followedAppsFilters: {
        name: undefined,
        apmId: undefined,
        active: true,
        businessDomain: undefined,
        itscmCriticality: undefined,
      },
      showMyAppsFilters: false,
      showFollowedAppsFilters: false,
      myApps: [],
      followedApps: [],
      myAppsPagination: {
        sortBy: 'desc',
        descending: false,
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 5,
      },
      followedAppsPagination: {
        sortBy: 'desc',
        descending: false,
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 5,
      },
      myAppsLoading: false,
      followedAppsLoading: false,
      majorIncidents: [],
      showIncidentsDialog: false,
    };
  },
  computed: {
    QUALITY_SENSORS_BASE_URL() {
      return process.env.QUALITY_SENSORS_BASE_URL;
    },
    showQualitySensorIssues() {
      return uiConfig[process.env.VUE_APP_ENV].showQualitySensorIssues;
    },
    columns() {
      const cols = [
        {
          name: 'digitalServiceActive',
          label: 'State',
          field: 'digitalServiceActive',
          align: 'center',
        },
        {
          name: 'name',
          label: 'name',
          field: 'name',
          align: 'left',
          classes: 'service-name',
          sortable: true,
        },
        {
          name: 'apmId',
          label: 'apmId',
          field: 'apmId',
          align: 'left',
          sortable: true,
        },
        {
          name: 'itOwner',
          label: 'it owner',
          align: 'left',
          sortable: true,
        },
        {
          name: 'majorIncidents',
          label: 'Major Incident',
          align: 'center',
          sortable: true,
        },
        {
          name: 'services',
          label: 'include services',
          align: 'left',
        },
        {
          name: 'kpi',
          field: 'kpi',
          label: 'Cost (USD) w/o VAT',
          align: 'left',
          sortable: true,
          sort: (a, b) => this.sortKpi(a, b),
        },
        {
          name: 'doraScore',
          field: 'doraScore',
          label: 'DORA Score',
          align: 'left',
          sortable: true,
          sort: (a, b) => this.sortDora(a, b),
        },
        {
          name: 'actions',
          label: 'actions',
          align: 'right',
        },
      ];
      if (this.showQualitySensorIssues) {
        cols.splice(4, 0, {
          name: 'qualitySensorOpen',
          label: 'Open issues',
          align: 'center',
          sortable: true,
          field: 'qualitySensorOpen',
        });
        cols.splice(5, 0, {
          name: 'qualitySensorOverdue',
          label: 'overdue issues',
          align: 'center',
          sortable: true,
        });
      }
      return cols;
    },
  },
  methods: {
    ...mapActions('digitalServices', {
      getApplications: DIGITAL_SERVICES_ACTIONS.GET_ALL_APPLICATIONS,
    }),
    showMajorIncidents(items) {
      this.majorIncidents = items;
      this.showIncidentsDialog = true;
    },
    async fetchApplications(filters, fetchCount, startRow, owned) {
      let res;
      await apollo.apolloClient
        .query({
          query: gql`
          query getOwnedByMeApplications(
            $name: String,
            $apmId: String,
            $digitalServiceActive: Boolean,
            $owned: Boolean,
            $businessDomain: String,
            $itscmCriticality: String,
          ) {
            getOwnedByMeApplications(owned: $owned, filter: {name: $name, businessDomain: $businessDomain, itscmCriticality: $itscmCriticality,  apmId: $apmId, digitalServiceActive: $digitalServiceActive}, limit: ${fetchCount}, skip: ${startRow}) {
              count
              applications {
                majorIncidents
                id
                apmId
                digitalServiceName
                digitalServiceActive
                itOwnerName
                updatedAt
                createdAt
                doraScore
                totalKPICost
                registrations {
                  status
                  infraService {
                    apmid
                    displayName
                    serviceName
                    cloudType
                    type
                  }
                }
                qualitySensorIssues {
                  severity
                  numberOfOpen
                  numberOfOverdue
                }
              }
            }
          }
        `,
          variables: {
            name: filters?.name,
            apmId: filters?.apmId,
            digitalServiceActive: filters?.active,
            owned: owned,
            businessDomain: filters?.businessDomain,
            itscmCriticality: filters?.itscmCriticality,
          },
        })
        .then((response) => {
          res = response;
        });
      return res;
    },
    buildApplications(apps) {
      const applications = [];
      apps.forEach((app) => {
        const appObject = {
          id: app.id,
          apmId: app.apmId,
          name: app?.digitalServiceName,
          status: app?.digitalServiceStatus,
          itOwner: app?.itOwnerName || '',
          createdBy: app.createdBy || '',
          digitalServiceActive: app.digitalServiceActive,
          kpi: app?.totalKPICost || [],
          services: app.registrations?.length
            ? app.registrations.map((reg) => ({ status: reg.status, ...reg.infraService }))
            : [],
          doraScore: app.doraScore || 'No Data',
          qualitySensorIssues: app.qualitySensorIssues,
          majorIncidents: app.majorIncidents,
        };
        applications.push(appObject);
      });
      return applications;
    },
    async getOwnedApps(props) {
      this.myAppsLoading = true;
      const { page, rowsPerPage, sortBy, descending } = props.pagination;
      const fetchCount = rowsPerPage === 0 ? this.myAppsPagination.rowsNumber : rowsPerPage;
      const startRow = (page - 1) * rowsPerPage;
      this.myAppsPagination.page = page;
      this.myAppsPagination.rowsPerPage = rowsPerPage;
      this.myAppsPagination.sortBy = sortBy;
      this.myAppsPagination.descending = descending;
      await this.fetchApplications(this.myAppsFilters, fetchCount, startRow, true)
        .then((res) => {
          const rawApplications = res.data.getOwnedByMeApplications.applications || [];
          const applications = this.buildApplications(rawApplications);
          this.myAppsPagination.rowsNumber = res.data.getOwnedByMeApplications.count;
          this.myApps = applications;
        })
        .finally(() => {
          this.myAppsLoading = false;
        });
    },
    async getFollowedApps(props) {
      this.followedAppsLoading = true;
      const { page, rowsPerPage, sortBy, descending } = props.pagination;
      const fetchCount = rowsPerPage === 0 ? this.followedAppsPagination.rowsNumber : rowsPerPage;
      const startRow = (page - 1) * rowsPerPage;
      this.followedAppsPagination.page = page;
      this.followedAppsPagination.rowsPerPage = rowsPerPage;
      this.followedAppsPagination.sortBy = sortBy;
      this.followedAppsPagination.descending = descending;
      await this.fetchApplications(this.followedAppsFilters, fetchCount, startRow, false)
        .then((res) => {
          const rawApplications = res.data.getOwnedByMeApplications.applications || [];
          const applications = this.buildApplications(rawApplications);
          this.followedAppsPagination.rowsNumber = res.data.getOwnedByMeApplications.count;
          this.followedApps = applications;
        })
        .finally(() => {
          this.followedAppsLoading = false;
        });
    },
    async deleteApplication(app) {
      this.followedAppsLoading = true;
      await apollo.apolloClient
        .query({
          query: gql`mutation { removeDigitalServiceToUserViewList(digitalServiceId: "${app.id}")}`,
        })
        .then(async () => {
          await this.$refs.followedApplications.requestServerInteraction();
          emitter.emit('getAllApplications');
        })
        .catch((error) => {
          this.$notify('negative', 'Error', error.message);
        });
    },
    goToApplication(id) {
      this.$router.push(`/my-portfolio/${id.toLowerCase()}`);
    },
    sortKpi(a, b) {
      return a.reduce((acc, item) => acc + item.cost, 0) < b.reduce((acc, item) => acc + item.cost, 0)
        ? -1
        : a.reduce((acc, item) => acc + item.cost, 0) > b.reduce((acc, item) => acc + item.cost, 0)
        ? 1
        : 0;
    },
    sortDora(a, b) {
      const rule = {
        'No Data': 0,
        LOW: 1,
        MEDIUM: 2,
        HIGH: 3,
      };
      return rule[a] < rule[b] ? -1 : rule[a] > rule[b] ? 1 : 0;
    },
    goToDoraPage(apmId) {
      window.open(`https://wow-measure.swissre.com/apmids/${apmId}`, '_blank');
    },
    showKpi(kpi) {
      const sortKpi = kpi ? kpi.sort((a, b) => dayjs(a.period).unix() - dayjs(b.period).unix()) : [];
      // const totalKpi = sortKpi.reduce((acc, item) => acc + item.cost, 0);
      const lastItems = sortKpi.slice(-2);
      const direction =
        sortKpi.length > 1 ? (lastItems[1].cost > lastItems[0].cost ? 'arrow_upward' : 'arrow_downward') : null;
      return {
        direction,
        color: direction === 'arrow_downward' ? 'green' : 'red',
        total: sortKpi.length ? (sortKpi.length > 1 ? sortKpi[sortKpi.length - 1] : kpi[0]) : null,
      };
    },
    async addDigitalService(app) {
      this.followedAppsLoading = true;
      await apollo.apolloClient
        .query({
          query: gql`mutation { addDigitalServiceToUserViewList(digitalServiceId: "${app.id}"){
            id
            apmId
            digitalServiceName
            digitalServiceActive
            itOwnerName
            updatedAt
            createdAt
            doraScore
            totalKPICost
            registrations {
              status
              infraService {
                apmid
                displayName
                serviceName
                cloudType
                type
              }
            }
            qualitySensorIssues {
              severity
              numberOfOpen
              numberOfOverdue
            }
          }}`,
        })
        .then(async (response) => {
          const applications = this.buildApplications([response.data.addDigitalServiceToUserViewList]);
          this.followedApps.splice(0, 1, applications[0]);
          this.followedAppsPagination.rowsNumber += 1;
        })
        .catch((error) => {
          this.$notify('negative', 'Error', error.message);
        })
        .finally(() => {
          this.followedAppsLoading = false;
        });
    },
    createBanner(service) {
      const opaLinks = {
        DEV: 'https://opa-cockpit-dev.ac-np.swissre.com',
        NONPROD: 'https://opa-cockpit-np.ac-np.swissre.com',
        PROD: 'https://opa-cockpit.ac.swissre.com',
      };
      const env = process.env.VUE_APP_ENV;
      window.open(`${opaLinks[env]}/${service.apmId.toLowerCase()}/banners/new`, '_blank');
    },
    showMyAppsFilter() {
      this.showMyAppsFilters = !this.showMyAppsFilters;
    },
    showFollowedAppsFilter() {
      this.showFollowedAppsFilters = !this.showFollowedAppsFilters;
    },
  },
  async mounted() {
    await this.$refs.myApplications.requestServerInteraction();
    await this.$refs.followedApplications.requestServerInteraction();
  },
  created() {
    emitter.on('loadAddingService', async (value) => {
      await this.addDigitalService(value).then(() => {
        emitter.emit('getAllApplications');
      });
    });
  },
  unmounted() {
    emitter.off('loadAddingService');
  },
};
</script>

<style scoped lang="scss">
.digital-services-holder {
  border-radius: 15px;
}
.infra-services-holder {
  .infra-service {
    margin-bottom: 5px;
  }
}
.dora-legend {
  font-size: 12px;
  line-height: 1.3;

  ul {
    list-style-type: none;
    padding: 0;
    margin-top: 0;
  }
}
.dora-legend-items {
  display: flex;
  flex-wrap: wrap;

  div {
    width: 50%;
  }
}
.application-state {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $grey-5;

  &.active {
    background-color: $green-5;
  }
}
.service-name {
  max-width: 190px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.status {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
  background-color: $grey-6;

  &.inprogress {
    background-color: $orange;
  }

  &.pending {
    background-color: $grey-6;
  }

  &.completed,
  &.abandoned {
    background-color: $green;
  }

  &.failed {
    background-color: $red;
  }

  &.reverted {
    background-color: #d00fa0;
  }
}
.table-header {
  h5 {
    font-size: 20px;
    letter-spacing: 0.005em;
    font-weight: 400;
    margin: 12px 16px 12px 6px;
  }
}
.quality-service {
  &.success {
    color: $green;
  }

  &.danger {
    color: $red;
  }

  a {
    color: inherit;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
