import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "col" }
const _hoisted_2 = {
  key: 0,
  class: "col"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_toolbar_title = _resolveComponent("q-toolbar-title")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_toolbar = _resolveComponent("q-toolbar")!
  const _component_dispatch_renderer = _resolveComponent("dispatch-renderer")!
  const _component_q_row = _resolveComponent("q-row")!
  const _component_q_layout = _resolveComponent("q-layout")!
  const _component_q_card = _resolveComponent("q-card")!

  return (_ctx.control.visible)
    ? (_openBlock(), _createBlock(_component_q_card, {
        key: 0,
        elevation: "0"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_toolbar_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.additionalPropertiesTitle), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_q_input, {
                required: true,
                class: _normalizeClass(_ctx.styles.control.input),
                modelValue: _ctx.newPropertyName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newPropertyName) = $event)),
                placeholder: _ctx.placeholder,
                disabled: !_ctx.control.enabled
              }, null, 8, ["class", "modelValue", "placeholder", "disabled"]),
              _createVNode(_component_q_btn, {
                flat: "",
                icon: "plus",
                round: "",
                small: "",
                "aria-label": _ctx.addToLabel,
                disabled: _ctx.addPropertyDisabled,
                onClick: _ctx.addProperty
              }, null, 8, ["aria-label", "disabled", "onClick"])
            ]),
            _: 1
          }),
          _createVNode(_component_q_layout, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.additionalPropertyItems, (element, index) => {
                return (_openBlock(), _createBlock(_component_q_row, {
                  key: `${index}`
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      (element.schema && element.uischema)
                        ? (_openBlock(), _createBlock(_component_dispatch_renderer, {
                            key: 0,
                            schema: element.schema,
                            uischema: element.uischema,
                            path: element.path,
                            enabled: _ctx.control.enabled,
                            renderers: _ctx.control.renderers,
                            cells: _ctx.control.cells
                          }, null, 8, ["schema", "uischema", "path", "enabled", "renderers", "cells"]))
                        : _createCommentVNode("", true)
                    ]),
                    (_ctx.control.enabled)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                          _createVNode(_component_q_btn, {
                            icon: "remove",
                            flat: "",
                            round: "",
                            small: "",
                            "aria-label": _ctx.deleteLabel,
                            disabled: _ctx.removePropertyDisabled,
                            onClick: ($event: any) => (_ctx.removeProperty(element.propertyName))
                          }, null, 8, ["aria-label", "disabled", "onClick"])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}