<template>
  <img :src="$options[logoSrc]" :alt="logo" />
</template>

<script>
import defaultImage from '../../assets/logos/default.svg';
import AAD from '../../assets/logos/AAD.svg';
import Ansible from '../../assets/logos/Ansible.svg';
import API_Management_Service from '../../assets/logos/API_Management_Service.svg';
import API_Management from '../../assets/logos/API_Management.svg';
import App_Configuration from '../../assets/logos/App_Configuration.svg';
import App_Services from '../../assets/logos/App_Services.svg';
import Artifactory_Jfrog_Cloud_Native from '../../assets/logos/Artifactory_Jfrog_Cloud_Native.svg';
import azure from '../../assets/logos/azure.svg';
import Azure_DevOps from '../../assets/logos/Azure_DevOps.svg';
import Azure_Subscription from '../../assets/logos/Azure_Subscription.svg';
import Bamboo from '../../assets/logos/Bamboo.svg';
import Bitbucket from '../../assets/logos/Bitbucket.svg';
import Chef from '../../assets/logos/Chef.svg';
import Cloudflare from '../../assets/logos/Cloudflare.svg';
import Cognitive_Search from '../../assets/logos/Cognitive_Search.svg';
import Cognitive_Services from '../../assets/logos/Cognitive_Services.svg';
import Confluence from '../../assets/logos/Confluence.svg';
import Container_Apps from '../../assets/logos/Container_Apps.svg';
import Container_Instances from '../../assets/logos/Container_Instances.svg';
import Container_Registry from '../../assets/logos/Container_Registry.svg';
import CosmosDB_Account from '../../assets/logos/CosmosDB_Account.svg';
import CosmosDB_Database from '../../assets/logos/CosmosDB_Database.svg';
import CP_Application_Insights from '../../assets/logos/CP_Application_Insights.svg';
import CP_Azure_Monitor from '../../assets/logos/CP_Azure_Monitor.svg';
import Data_Factory from '../../assets/logos/Data_Factory.svg';
import Data_Lake_Storage_Gen2 from '../../assets/logos/Data_Lake_Storage_Gen2.svg';
import Data_Lake from '../../assets/logos/Data_Lake.svg';
import DataBricks_Workspace from '../../assets/logos/DataBricks_Workspace.svg';
import DbaaS from '../../assets/logos/DbaaS.svg';
import Docker_for_Windows_1 from '../../assets/logos/Docker_for_Windows_1.svg';
import Docker_for_Windows from '../../assets/logos/Docker_for_Windows.svg';
import Documentum from '../../assets/logos/Documentum.svg';
import Event_Grid from '../../assets/logos/Event_Grid.svg';
import Event_Hub from '../../assets/logos/Event_Hub.svg';
import Eviews from '../../assets/logos/Eviews.svg';
import Functions from '../../assets/logos/Functions.svg';
import IntelliJ from '../../assets/logos/IntelliJ.svg';
import JIRA from '../../assets/logos/JIRA.svg';
import Key_Vault from '../../assets/logos/Key_Vault.svg';
import Kubernetes_Service_AKS from '../../assets/logos/Kubernetes_Service_AKS.svg';
import Laas from '../../assets/logos/Laas.svg';
import Log_Analytics_Workspace from '../../assets/logos/Log_Analytics_Workspace.svg';
import Logic_Apps from '../../assets/logos/Logic_Apps.svg';
import maas from '../../assets/logos/maas.svg';
import Machine_Learning from '../../assets/logos/Machine_Learning.svg';
import Mathematica from '../../assets/logos/Mathematica.svg';
import MatLab from '../../assets/logos/MatLab.svg';
import MS_Defender_for_Cloud from '../../assets/logos/MS_Defender_for_Cloud.svg';
import MS_SQL_Database from '../../assets/logos/MS_SQL_Database.svg';
import MS_SQL_Managed_Database from '../../assets/logos/MS_SQL_Managed_Database.svg';
import MS_SQL_Server from '../../assets/logos/MS_SQL_Server.svg';
import Nexus_IQ from '../../assets/logos/Nexus_IQ.svg';
import Okta from '../../assets/logos/Okta.svg';
import Opsgenie from '../../assets/logos/Opsgenie.svg';
import Paas from '../../assets/logos/Paas.svg';
import Postgre_SQL_Database from '../../assets/logos/Postgre_SQL_Database.svg';
import Postgre_SQL_Server from '../../assets/logos/Postgre_SQL_Server.svg';
import Postgre_SQL_Single_Server from '../../assets/logos/Postgre_SQL_Single_Server.svg';
import Power_BI_report_server from '../../assets/logos/Power_BI_report_server.svg';
import PowerCenter_ETL_service from '../../assets/logos/PowerCenter_ETL_service.svg';
import Protractor from '../../assets/logos/Protractor.svg';
import Pulumi from '../../assets/logos/Pulumi.svg';
import Puppet from '../../assets/logos/Puppet.svg';
import PyCharm from '../../assets/logos/PyCharm.svg';
import Redis_Cache from '../../assets/logos/Redis_Cache.svg';
import Rstudio from '../../assets/logos/Rstudio.svg';
import Selenium from '../../assets/logos/Selenium.svg';
import Sentinel_1 from '../../assets/logos/Sentinel_1.svg';
import Sentinel from '../../assets/logos/Sentinel.svg';
import Service_Bus from '../../assets/logos/Service_Bus.svg';
import SonarQube from '../../assets/logos/SonarQube.svg';
import Storage_Account from '../../assets/logos/Storage_Account.svg';
import Stratum_AZ_Databricks from '../../assets/logos/Stratum_AZ_Databricks.svg';
import Stratum_Az_Synapse from '../../assets/logos/Stratum_Az_Synapse.svg';
import Stratum_Data_Factory from '../../assets/logos/Stratum_Data_Factory.svg';
import Stratum_Power_BI_Gateway from '../../assets/logos/Stratum_Power_BI_Gateway.svg';
import Stratum from '../../assets/logos/Stratum.svg';
import Synapse_Analytics from '../../assets/logos/Synapse_Analytics.svg';
import Synapse_Workspace from '../../assets/logos/Synapse_Workspace.svg';
import Tableu from '../../assets/logos/Tableu.svg';
import Terraform from '../../assets/logos/Terraform.svg';
import tool from '../../assets/logos/tool.svg';
import Visio from '../../assets/logos/Visio.svg';
import Visual_Studio_Enterprise from '../../assets/logos/Visual_Studio_Enterprise.svg';
import VisualStudio_Professional from '../../assets/logos/VisualStudio_Professional.svg';
import VM_Scale_Set from '../../assets/logos/VM_Scale_Set.svg';
import VM from '../../assets/logos/VM.svg';
import VSCode from '../../assets/logos/VSCode.svg';
import ZeeBe from '../../assets/logos/ZeeBe.svg';
import Zephyr_for_Jira from '../../assets/logos/Zephyr_for_Jira.svg';
import Stargate_Graph from '../../assets/logos/Stargate_Graph.svg';
import Stargate_Palantir from '../../assets/logos/Stargate_Palantir.svg';

export default {
  name: 'InfraServiceLogo',
  defaultImage,
  AAD,
  Ansible,
  API_Management_Service,
  API_Management,
  App_Configuration,
  App_Services,
  Artifactory_Jfrog_Cloud_Native,
  azure,
  Azure_DevOps,
  Azure_Subscription,
  Bamboo,
  Bitbucket,
  Chef,
  Cloudflare,
  Cognitive_Search,
  Cognitive_Services,
  Confluence,
  Container_Apps,
  Container_Instances,
  Container_Registry,
  CosmosDB_Account,
  CosmosDB_Database,
  CP_Application_Insights,
  CP_Azure_Monitor,
  Data_Factory,
  Data_Lake_Storage_Gen2,
  Data_Lake,
  DataBricks_Workspace,
  DbaaS,
  Docker_for_Windows_1,
  Docker_for_Windows,
  Documentum,
  Event_Grid,
  Event_Hub,
  Eviews,
  Functions,
  IntelliJ,
  JIRA,
  Key_Vault,
  Kubernetes_Service_AKS,
  Laas,
  Log_Analytics_Workspace,
  Logic_Apps,
  maas,
  Machine_Learning,
  Mathematica,
  MatLab,
  MS_Defender_for_Cloud,
  MS_SQL_Database,
  MS_SQL_Managed_Database,
  MS_SQL_Server,
  Nexus_IQ,
  Okta,
  Opsgenie,
  Paas,
  Postgre_SQL_Database,
  Postgre_SQL_Server,
  Postgre_SQL_Single_Server,
  Power_BI_report_server,
  PowerCenter_ETL_service,
  Protractor,
  Pulumi,
  Puppet,
  PyCharm,
  Redis_Cache,
  Rstudio,
  Selenium,
  Sentinel_1,
  Sentinel,
  Service_Bus,
  SonarQube,
  Storage_Account,
  Stratum_AZ_Databricks,
  Stratum_Az_Synapse,
  Stratum_Data_Factory,
  Stratum_Power_BI_Gateway,
  Stratum,
  Synapse_Analytics,
  Synapse_Workspace,
  Tableu,
  Terraform,
  tool,
  Visio,
  Visual_Studio_Enterprise,
  VisualStudio_Professional,
  VM_Scale_Set,
  VM,
  VSCode,
  ZeeBe,
  Zephyr_for_Jira,
  Stargate_Graph,
  Stargate_Palantir,
  props: {
    logo: {
      type: String,
    },
  },
  computed: {
    logoSrc() {
      return this.logo === 'default' || !this.$options[this.logo] || !this.logo ? 'defaultImage' : this.logo;
    },
  },
};
</script>

<style scoped lang="scss">
img {
  width: 45px;
}
</style>
