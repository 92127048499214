<template>
  <span class="label-block q-mt-md" v-if="Object.values(infraService.links).length">Links:</span>
  <div class="links" v-if="Object.values(infraService.links).length">
    <ul
      v-for="(group, key) in Object.keys(infraService.links)"
      :key="key"
      :style="`width:${100 / Object.values(infraService.links).length}%`"
    >
      <span>{{ group }}</span>
      <li v-for="link in infraService.links[group]" :key="link.id">
        <a :href="link.url" target="_blank">{{ link.label }}</a>
      </li>
    </ul>
  </div>
  <div v-if="loadManifest" class="flex items-center justify-center q-py-lg">
    <q-spinner-gears size="40px" color="primary"></q-spinner-gears>
  </div>
  <div v-else-if="Object.values(manifest).length" class="q-pt-lg ccoe-widget">
    <div v-for="key in Object.keys(manifest)" :key="key">
      <q-expansion-item>
        <template v-slot:header>
          <strong>{{ key }}</strong>
        </template>
        <q-table :rows="manifest[key]" :columns="cols" flat class="table-info" row-key="resourceName">
          <template v-slot:body="props">
            <q-tr :props="props">
              <q-td v-for="col in props.cols" :key="col.name" :props="props">
                {{ col.value }}
              </q-td>
              <q-td auto-width>
                <q-btn size="sm" flat round dense @click="props.expand = !props.expand" icon="tag">
                  <q-tooltip>{{ props.expand ? 'Close tags info' : 'Show tags info' }}</q-tooltip>
                </q-btn>
              </q-td>
            </q-tr>
            <q-tr v-show="props.expand" :props="props">
              <q-td colspan="100%" style="background: rgba(0, 0, 0, 0.03)">
                <div class="row">
                  <div class="col col-6">
                    <table class="expand-table">
                      <tbody>
                        <tr>
                          <th class="text-left">Environment:</th>
                          <td class="text-left">{{ props.row.tags.environment }}</td>
                        </tr>
                        <tr>
                          <th class="text-left">Bundle Version:</th>
                          <td class="text-left">{{ props.row.tags.bundleVersion }}</td>
                        </tr>
                        <tr>
                          <th class="text-left">Created At:</th>
                          <td class="text-left">
                            {{ $options.dayjs(props.row.tags.resourceCreatedAt).format('DD.MM.YYYY HH:mm') }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col col-6">
                    <table class="expand-table">
                      <tbody>
                        <tr>
                          <th class="text-left">Creator:</th>
                          <td class="text-left">{{ props.row.tags.creator }}</td>
                        </tr>
                        <tr>
                          <th class="text-left">Product Version:</th>
                          <td class="text-left">{{ props.row.tags.productVersion }}</td>
                        </tr>
                        <tr>
                          <th class="text-left">Artifact Name:</th>
                          <td class="text-left">{{ props.row.tags.artifactName }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-expansion-item>
    </div>
  </div>
  <div v-else class="flex full-width column items-center q-py-lg" style="padding: 45px 0">
    <q-icon name="hide_source" class="q-mb-sm" size="60px" color="primary"></q-icon>
    <span class="block" style="font-size: 16px">No data</span>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'CertifiedProductWidget',
  dayjs,
  props: {
    manifest: { type: Object },
    infraService: { type: Object },
    accessProducts: { type: Object },
    loadManifest: { type: Boolean, default: () => false },
  },
  data() {
    return {
      cols: [
        {
          label: 'Resource Name',
          name: 'resourceName',
          field: 'resourceName',
          align: 'left',
        },
        {
          label: 'Resource Group',
          name: 'resourceGroup',
          field: 'resourceGroup',
          align: 'left',
        },
        {
          label: 'Subscription Name',
          name: 'subscriptionName',
          field: 'subscriptionName',
          align: 'left',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.ccoe-widget {
  strong {
    display: block;
    font-size: 14px;
  }
  .q-table__bottom {
    border-top: none;
  }
  .table-info {
    border-bottom: none;

    .expand-table {
      tbody {
        th {
          padding: 0 10px;
        }
        td {
          border-bottom-width: 0 !important;
          height: 20px !important;
        }
      }
    }

    tbody td {
      height: 40px !important;
    }
  }

  .q-expansion-item {
    .q-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 30px;
      border-radius: 8px;
      margin-bottom: 5px;
      padding: 8px !important;
      background-color: #f7faff;
    }
  }
}
</style>
