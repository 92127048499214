
import { ControlElement } from '@jsonforms/core';
import { defineComponent } from 'vue';
import { rendererProps, useJsonFormsControl, RendererProps } from '@jsonforms/vue';

const controlRenderer = defineComponent({
  name: 'string-control-renderer',
  components: {},
  props: {
    ...rendererProps<ControlElement>(),
  },
  data() {
    return {
      inputData: undefined,
    };
  },
  mounted() {
    this.onChange(this.control.data);
  },
  methods: {
    onChange(value: any) {
      this.handleChange(this.control.path, value);
    },
  },
  setup(props: RendererProps<ControlElement>) {
    return useJsonFormsControl(props);
  },
  computed: {
    fieldRules() {
      const rules = [];
      if (this.control.required) {
        rules.push((val: string) => !!val || 'Field is required');
      }
      return rules;
    },
  },
});

export default controlRenderer;
