<template>
  <component v-if="componentLayout" :is="componentLayout" :class="{ showBanner: currentBanner && showBanners }">
    <q-page-container>
      <router-view></router-view>
    </q-page-container>
  </component>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { useQuasar } from 'quasar';
import { mapActions, mapGetters } from 'vuex';
import appInsights from '@/library/applicationInsights';
import { BANNERS_GETTERS } from '@/store/modules/banners';
import apollo from '@/library/http/apollo';
import gql from 'graphql-tag';
import { NOTIFICATIONS_ACTIONS } from '@/store/modules/notifications';
import axios from 'axios';
import { APP_ACTIONS, APP_GETTERS } from '@/store/modules/app';
import { SERVICE_STATUS_ACTIONS } from '@/store/modules/service-status';

export default {
  name: 'AppComponent',
  watch: {
    layout: {
      handler() {
        this.componentLayout = defineAsyncComponent(() => import(`@/layouts/${this.layout}.vue`));
      },
      immediate: true,
    },
  },
  computed: {
    layout() {
      return this.$route.meta.layout || 'DefaultLayout';
    },
    ...mapGetters('banners', {
      currentBanner: BANNERS_GETTERS.CURRENT,
      showBanners: BANNERS_GETTERS.BANNERS_SHOW,
    }),
    ...mapGetters('app', {
      userInfo: APP_GETTERS.USER_INFO,
    }),
  },
  data() {
    return {
      componentLayout: undefined,
      eventSource: null,
    };
  },
  methods: {
    ...mapActions('app', {
      getUserInfo: APP_ACTIONS.GET_USER_INFO,
    }),
    ...mapActions('serviceStatus', {
      getServiceStatus: SERVICE_STATUS_ACTIONS.GET_SERVICE_STATUS,
    }),
    subscribeForUserNotifications() {
      const { VUE_APP_ARS_API_URL } = process.env;
      const subscribeForNotificationsUrl = `${VUE_APP_ARS_API_URL}/notifications/subscribe`;

      setTimeout(async () => {
        this.eventSource = new EventSource(`${subscribeForNotificationsUrl}?userEmail=${this.userInfo.email}`);

        this.eventSource.onmessage = (event) => {
          const userNotification = JSON.parse(event.data);
          const { type, title: caption, description: message } = userNotification;

          this.$notify(type, caption, message);
        };

        this.eventSource.onerror = (error) => {
          console.error('EventSource failed:', error);
          this.eventSource.close();
        };
      }, 1000);
    },
  },
  setup() {
    const $q = useQuasar();
    $q.iconMapFn = (iconName) => {
      if (iconName.startsWith('engp:') === true) {
        const name = iconName.substring(5);
        return {
          cls: 'icomoon ' + name,
        };
      }
    };
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  },
  async mounted() {
    await this.getUserInfo();
    await apollo.apolloClient
      .query({
        query: gql`
          query {
            getUserNotifications {
              id
              userNotificationId
              title
              description
              is_active
              created_at
              type: notification_type
            }
          }
        `,
      })
      .then((response) => {
        const { getUserNotifications: userNotifications } = response.data;

        for (const userNotification of userNotifications) {
          const { userNotificationId, type, title: caption, description: message, created_at: time } = userNotification;

          this.$store.dispatch(`notifications/${NOTIFICATIONS_ACTIONS.ADD_NOTIFICATION}`, {
            userNotificationId,
            type,
            caption,
            message,
            time,
          });
        }
      });
    await this.getServiceStatus();
    this.subscribeForUserNotifications();
  },

  beforeUnmount() {
    this.eventSource.close();
  },
};
</script>
