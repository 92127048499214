<template>
  <q-dialog :model-value="show" persistent>
    <q-card style="min-width: 780px; border-radius: 0px">
      <q-toolbar class="shadow-2 q-py-sm q-px-md">
        <q-toolbar-title class="text-weight-bold">
          <strong>{{ title }}</strong>
        </q-toolbar-title>
        <q-btn icon="close" style="min-width: 20px" round flat class="btn--no-hover" @click="$emit('close')"></q-btn>
      </q-toolbar>
      <q-card-section class="q-mx-md q-px-none q-pb-none" style="border-top: 2px solid var(--q-primary)">
        <q-form ref="infraServiceForm">
          <slot></slot>
        </q-form>
      </q-card-section>
      <q-card-actions class="q-px-md q-mt-lg q-pt-none q-pb-lg">
        <q-btn
          style="border-radius: 10px; text-transform: capitalize"
          color="primary"
          text-color="text-color"
          @click="submit"
          >Submit</q-btn
        >
        <q-btn
          style="border-radius: 10px; text-transform: capitalize"
          class="btn--no-hover"
          flat
          @click="$emit('close')"
          >Cancel</q-btn
        >
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'DialogForm',
  props: {
    show: {
      type: Boolean,
    },
    title: {
      type: String,
    },
  },
  methods: {
    submit() {
      this.$refs.infraServiceForm.validate().then((valid) => {
        if (valid) {
          this.$emit('submit');
        }
      });
    },
  },
};
</script>
