import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_tab = _resolveComponent("q-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!
  const _component_dispatch_renderer = _resolveComponent("dispatch-renderer")!
  const _component_v_tab_item = _resolveComponent("v-tab-item")!
  const _component_q_tabs_items = _resolveComponent("q-tabs-items")!

  return (_ctx.control.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_q_tabs, {
          modelValue: _ctx.selectedIndex,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedIndex) = $event))
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.anyOfRenderInfos, (anyOfRenderInfo, anyOfIndex) => {
              return (_openBlock(), _createBlock(_component_q_tab, {
                key: `${_ctx.control.path}-${anyOfIndex}`
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(anyOfRenderInfo.label), 1)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_q_tabs_items, {
          modelValue: _ctx.selectedIndex,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedIndex) = $event))
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.anyOfRenderInfos, (anyOfRenderInfo, anyOfIndex) => {
              return (_openBlock(), _createBlock(_component_v_tab_item, {
                key: `${_ctx.control.path}-${anyOfIndex}`
              }, {
                default: _withCtx(() => [
                  (_ctx.selectedIndex === anyOfIndex)
                    ? (_openBlock(), _createBlock(_component_dispatch_renderer, {
                        key: 0,
                        schema: anyOfRenderInfo.schema,
                        uischema: anyOfRenderInfo.uischema,
                        path: _ctx.control.path,
                        renderers: _ctx.control.renderers,
                        cells: _ctx.control.cells,
                        enabled: _ctx.control.enabled
                      }, null, 8, ["schema", "uischema", "path", "renderers", "cells", "enabled"]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]))
    : _createCommentVNode("", true)
}