import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_select = _resolveComponent("q-select")!
  const _component_control_wrapper = _resolveComponent("control-wrapper")!

  return (_openBlock(), _createBlock(_component_control_wrapper, _mergeProps(_ctx.controlWrapper, {
    styles: _ctx.styles,
    isFocused: _ctx.isFocused,
    appliedOptions: _ctx.appliedOptions
  }), {
    default: _withCtx(() => [
      _createVNode(_component_q_select, {
        outlined: "",
        label: _ctx.computedLabel,
        value: _ctx.control.data,
        rules: _ctx.fieldRules,
        items: _ctx.control.options,
        onChange: _ctx.onChange,
        disable: _ctx.disable,
        class: "input-form q-pb-none"
      }, null, 8, ["label", "value", "rules", "items", "onChange", "disable"])
    ]),
    _: 1
  }, 16, ["styles", "isFocused", "appliedOptions"]))
}