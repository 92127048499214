import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dispatch_renderer = _resolveComponent("dispatch-renderer")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layout.uischema.elements, (element, index) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "column",
      key: `${_ctx.layout.path}-${index}`
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_dispatch_renderer, {
          schema: _ctx.layout.schema,
          uischema: element,
          path: _ctx.layout.path,
          enabled: _ctx.layout.enabled,
          renderers: _ctx.layout.renderers,
          cells: _ctx.layout.cells,
          manifest: _ctx.manifest,
          freezeManifest: _ctx.freezeManifest,
          currentEnv: _ctx.currentEnv
        }, null, 8, ["schema", "uischema", "path", "enabled", "renderers", "cells", "manifest", "freezeManifest", "currentEnv"])
      ])
    ]))
  }), 128))
}