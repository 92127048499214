import { createStore } from 'vuex';
import modules from './modules';

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules,
});
