<template>
  <span class="label-block q-mt-md" v-if="Object.values(infraService.links).length">Links:</span>
  <div class="links" v-if="Object.values(infraService.links).length">
    <ul
      v-for="(group, key) in Object.keys(infraService.links)"
      :key="key"
      :style="`width:${100 / Object.values(infraService.links).length}%`"
    >
      <span>{{ group }}</span>
      <li v-for="link in infraService.links[group]" :key="link.id">
        <a :href="link.url" target="_blank">{{ link.label }}</a>
      </li>
    </ul>
  </div>
  <div>
    <div v-if="loadManifest" class="flex items-center justify-center q-py-lg">
      <q-spinner-gears size="40px" color="primary"></q-spinner-gears>
    </div>
    <div v-if="!loadManifest && manifest.environments && manifest.environments.length" class="q-pt-xs q-mb-md">
      <span class="label-block q-mt-md">Environments:</span>
      <q-tabs
        v-model="envTab"
        inline-label
        content-class="justify-start"
        class="q-mt-xs widget-tabs"
        active-class="active-tab"
        indicator-color="transparent"
      >
        <q-tab v-for="item in manifest.environments" :name="item.id" :key="item.id">
          <q-icon name="check_circle" class="q-mr-xs" color="light-blue-7" size="18px"></q-icon>
          <span style="text-transform: capitalize; font-size: 12px">{{ item.id }}</span>
        </q-tab>
      </q-tabs>
      <q-tab-panels v-model="envTab" animated>
        <q-tab-panel v-for="item in manifest.environments" :key="item.id" :name="item.id" class="q-px-none q-py-none">
          <div class="row opa-settings-heading">
            <div class="col col-4">
              <span class="block" style="font-weight: bold; font-size: 12px">Email To:</span>
            </div>
            <div class="col col-4">
              <span class="block" style="font-weight: bold; font-size: 12px">Email CC:</span>
            </div>
            <div class="col col-4">
              <span class="block" style="font-weight: bold; font-size: 12px">Email BCC:</span>
            </div>
          </div>
          <div class="row">
            <div class="col col-4">
              <ul class="emails-list">
                <li v-for="(email, key) in item.emails?.defaultEmailTo" :key="key">
                  {{ email }}
                </li>
              </ul>
            </div>
            <div class="col col-4">
              <ul class="emails-list">
                <li v-for="(email, key) in item.emails?.defaultEmailCc" :key="key">
                  {{ email }}
                </li>
              </ul>
            </div>
            <div class="col col-4">
              <ul class="emails-list">
                <li v-for="(email, key) in item.emails?.defaultEmailBcc" :key="key">
                  {{ email }}
                </li>
              </ul>
            </div>
          </div>
        </q-tab-panel>
      </q-tab-panels>
    </div>
    <div v-else class="flex items-center justify-center q-py-md q-mb-lg">
      <strong>No data</strong>
    </div>
    <q-expansion-item class="actions-item">
      <template v-slot:header>
        <strong>List of actions</strong>
      </template>
      <div v-if="loadActions" class="flex items-center justify-center q-py-lg" style="padding: 30px 0">
        <q-spinner-gears size="40px" color="primary"></q-spinner-gears>
      </div>
      <div class="actions-table-holder q-mt-md" v-else-if="actions.length">
        <actions-table @showActions="actionsDialog = true" :actions="actions" />
      </div>
      <div v-else class="flex full-width column items-center q-py-lg" style="padding: 30px 0">
        <q-icon name="hide_source" class="q-mb-sm" size="40px" color="primary"></q-icon>
        <span class="block" style="font-size: 16px">No data</span>
      </div>
    </q-expansion-item>
    <q-expansion-item class="access-products-item">
      <template v-slot:header>
        <strong>Access products</strong>
      </template>
      <div v-if="loadAccessProducts" class="flex items-center justify-center q-py-lg" style="padding: 30px 0">
        <q-spinner-gears size="40px" color="primary"></q-spinner-gears>
      </div>
      <div class="actions-table-holder q-mt-md" v-else-if="Object.keys(accessProducts).length">
        <access-products-table :accessProducts="accessProducts"></access-products-table>
      </div>
      <div v-else class="flex full-width column items-center q-py-lg" style="padding: 30px 0">
        <q-icon name="hide_source" class="q-mb-sm" size="40px" color="primary"></q-icon>
        <span class="block" style="font-size: 16px">No data</span>
      </div>
    </q-expansion-item>
  </div>
</template>
<script>
import ActionsTable from '@/pages/digitalService/components/ActionsTable';
import AccessProductsTable from '@/pages/digitalService/components/AccessProductsTable';

export default {
  name: 'OpassWidget',
  components: {
    ActionsTable,
    AccessProductsTable,
  },
  props: {
    manifest: { type: Object, default: () => ({ environments: [] }) },
    actions: { type: Array },
    infraService: { type: Object },
    accessProducts: { type: Object },
    loadManifest: { type: Boolean, default: () => false },
    loadActions: { type: Boolean, default: () => false },
    loadAccessProducts: { type: Boolean, default: () => false },
    commentActions: { type: Array },
    loadCommentActions: { type: Boolean, default: () => false },
  },
  data() {
    return {
      envTab: undefined,
    };
  },
  watch: {
    manifest(value) {
      if (Object.values(value).length) {
        this.envTab = this.manifest.environments[0].id;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.active-tab,
.opa-settings-heading {
  background-color: #edeff5;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.opa-settings-heading {
  padding: 7px 10px 5px 10px;
  margin-top: -2px;
}
.emails-list {
  margin: 0;
  padding: 5px 10px;
  list-style-type: none;
  font-size: 12px;
}
</style>
