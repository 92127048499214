<template>
  <q-btn
    color="grey-3"
    :disable="!menuItems.length"
    unelevated
    text-color="text-color"
    size="md"
    icon="add"
    class="q-px-none q-py-none q-mr-sm btn--no-hover add-btn"
  >
    <q-menu max-width="240px" class="infra-services-holder" style="z-index: 99999999">
      <q-banner v-if="menuItems.length">
        <infra-service-chip v-for="item in menuItems" :key="item.id" :service="category" :infra-service="item" />
      </q-banner>
    </q-menu>
  </q-btn>
</template>

<script>
import { mapGetters } from 'vuex';
import { SERVICES_GETTERS } from '@/store/modules/services';
import InfraServiceChip from '@/shared/components/InfraServiceChip';

export default {
  name: 'AddServiceMenu',
  components: { InfraServiceChip },
  props: {
    category: {
      type: Object,
    },
    design: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('services', {
      catalogServices: SERVICES_GETTERS.ALL,
    }),
    menuItems() {
      const category = this.catalogServices.find((category) => category.categoryId === this.category.categoryId);
      const infraServices = category.infraServices.filter((item) => item.orderable && item.isCatalogPart) || [];
      if (category.serviceCategories?.length) {
        category.serviceCategories.forEach((subCategory) => {
          subCategory.infraServices.forEach((item) => {
            item.orderable && item.isCatalogPart ? infraServices.push(item) : false;
          });
        });
      }
      return infraServices.filter((item) => !this.isInDesign(item));
    },
  },
  methods: {
    isInDesign(item) {
      const category = this.design.find((category) => category.categoryId === this.category.categoryId);
      return category.infraServices.some((infra) => infra.apmid === item.apmid);
    },
  },
};
</script>

<style scoped lang="scss">
.add-btn {
  min-height: 25px;
  border-radius: 16px;
  width: 100%;
  margin-bottom: 20px;
}
</style>
