<template>
  <div class="template-popup">
    <q-form ref="form">
      <div class="template-header flex no-wrap items-center">
        <q-icon name="receipt_long" color="primary" size="40px" class="q-mr-sm"></q-icon>
        <div class="template-form flex no-wrap items-center q-gutter-xs" style="width: 100%">
          <q-input
            v-model="name"
            label="Template name"
            style="min-width: 300px"
            outlined
            :rules="[(val) => !!val || 'Field is required']"
          />
          <q-input v-model="description" label="Template description" style="width: 100%" outlined />
          <div class="btns-holder q-gutter-x-xs q-ml-auto" style="min-width: 210px">
            <q-btn @click="submit" rounded color="primary" text-color="text-color" class="tmp-btn">{{
              editTemplate ? 'Update' : 'Save'
            }}</q-btn>
            <q-btn @click="close" rounded color="grey-5" text-color="text-color" class="tmp-btn">Cancel</q-btn>
          </div>
        </div>
      </div>
    </q-form>
    <div class="services-holder q-mt-lg">
      <div class="row wrap">
        <div class="col col-2 q-px-xs" v-for="service in design" :key="service.categoryId">
          <span class="label">{{ service.name }}</span>
          <infra-service
            v-for="infraService in service.infraServices"
            @remove="removeService(service, infraService)"
            removable
            :data-service="infraService"
            :key="infraService.uuid"
          ></infra-service>
          <add-service-menu :design="design" :category="service" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import InfraService from '@/pages/templates/components/InfraService';
import AddServiceMenu from '@/pages/templates/components/AddServiceMenu';
import { SERVICE_DESIGN_ACTIONS } from '@/store/modules/service-design';

export default {
  name: 'TemplatePopup',
  components: {
    AddServiceMenu,
    InfraService,
  },
  emits: ['close', 'createTemplate'],
  props: {
    design: {
      type: Object,
    },
    editTemplate: {
      type: Boolean,
      default: () => false,
    },
    templateName: {
      type: String,
    },
    templateDescription: {
      type: String,
    },
  },
  data() {
    return {
      name: '',
      description: '',
    };
  },
  mounted() {
    if (this.editTemplate) {
      this.name = this.templateName;
      this.description = this.templateDescription;
    }
  },
  methods: {
    ...mapActions('serviceDesign', {
      removeFromDesign: SERVICE_DESIGN_ACTIONS.REMOVE_INFRASERVICE,
      resetServiceDesign: SERVICE_DESIGN_ACTIONS.RESET_SERVICE_DESIGN,
    }),
    close() {
      if (this.editTemplate) {
        this.resetServiceDesign();
      }
      this.$emit('close');
    },
    removeService(service, infraService) {
      this.removeFromDesign({ category: { categoryId: service.categoryId }, infraService });
    },
    submit() {
      this.$refs.form.validate().then(async (valid) => {
        if (valid) {
          this.$emit('createTemplate', { name: this.name, description: this.description });
          this.$emit('close');
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.q-field--with-bottom {
  padding-bottom: 0 !important;
}
.tmp-btn {
  height: 50px;
  width: 100px;
  border-radius: 10px;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: bold;
}
.label {
  display: block;
  min-height: 30px;
  text-align: center;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #a5a5a5;
  font-weight: bold;
}
.template-popup {
  .icon-service {
    display: flex;
    min-width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    border-radius: 50%;
    background-color: var(--q-primary);
  }
}
</style>
