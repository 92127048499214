
  import {
    ControlElement,
    composePaths,
    createDefaultValue,
    UISchemaElement,
    findUISchema,
    Resolve,
    getFirstPrimitiveProp,
    getControlPath,
  } from '@jsonforms/core';
  import { defineComponent, ref } from 'vue';
  import { DispatchRenderer, rendererProps, useJsonFormsArrayControl, RendererProps } from '@jsonforms/vue';
  import { useNested } from '../util';
  
  const controlRenderer = defineComponent({
    name: 'custom-opass-layout-renderer',
    components: {
      DispatchRenderer,
    },
    props: {
      ...rendererProps<ControlElement>(),
      manifest: { type: Object, default: () => ({}) },
      freezeManifest: { type: Object, default: () => ({}) },
    },
    setup(props: RendererProps<ControlElement>) {
      const expanded = ref(0);
      const control = useJsonFormsArrayControl(props);
      const suggestToDelete = ref<null | number>(null);
      useNested('array');
      return {
        ...control,
        suggestToDelete,
        expanded,
      };
    },
    computed: {
      addDisabled(): boolean {
        // No limits per environment for Alibaba;
        return false;
      },
      foundUISchema(): UISchemaElement {
        return findUISchema(
          this.control.uischemas,
          this.control.schema,
          this.control.uischema.scope,
          this.control.path,
          undefined,
          this.control.uischema,
          this.control.rootSchema,
        );
      },
    },
    methods: {
      composePaths,
      createDefaultValue,
      addButtonClick() {
        this.addItem(this.control.path, createDefaultValue(this.control.schema))();
        this.expanded += 1;
      },
      childErrors(index: number) {
        return this.control.childErrors.filter((e) => {
          const errorDataPath = getControlPath(e);
          return errorDataPath.startsWith(this.composePaths(this.control.path, `${index}`));
        });
      },
      removeItemsClick(event: Event, toDelete: number[]): void {
        event.stopPropagation();
        this.removeItems?.(this.control.path, toDelete)();
      },
      childLabelForIndex(index: number | null) {
        if (index === null) {
          return '';
        }
        const childLabelProp =
          this.control.uischema.options?.childLabelProp ?? getFirstPrimitiveProp(this.control.schema);
        if (!childLabelProp) {
          return `${index}`;
        }
        const labelValue = Resolve.data(this.control.data, composePaths(`${index}`, childLabelProp));
        if (labelValue === undefined || labelValue === null || Number.isNaN(labelValue)) {
          return 'Choose environment';
        }
        return `${labelValue}`;
      },
      canBeDelete() {
        return false;
      },
    },
  });
  
  export default controlRenderer;
  